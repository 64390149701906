import { TableCell } from '@mui/material';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import { FC, memo } from 'react';
import { DriverEntity } from 'src/entities/Driver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';

import { EditPropsBase } from './EditDialog.presenter';
import HeaderCellPresenter from './HeaderCell.presenter';

type Props = {
  daysOfWeek: Date[];
  driverDate: DriverEntity[];
  truckData: TruckEntity[];
  selectedGroup: PlanningsGroupEntity | undefined;
  updateEditPropsBase: (props: EditPropsBase) => void;
  isLoading: boolean;
  defaultStartAt: Date;
  defaultEndAt: Date;
  defaultWorkingAvailableDurationHours: number | undefined;
  selectedShiftCell: SelectedShiftCell[];
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
}

const HeaderCellsPresenter: FC<Props> = memo((
  {
    daysOfWeek,
    driverDate,
    truckData,
    selectedGroup,
    updateEditPropsBase,
    isLoading,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    selectedShiftCell,
    updateSelectedShiftCell,
  }
) => (
  <>
    {daysOfWeek.map((it) => (
      <TableCell
        align="center"
        key={format(it, 'MM/dd', { locale: ja })}
      >
        <HeaderCellPresenter
          on={it}
          driverData={driverDate}
          truckData={truckData}
          selectedGroup={selectedGroup}
          updateEditPropsBase={updateEditPropsBase}
          isLoading={isLoading}
          defaultStartAt={defaultStartAt}
          defaultEndAt={defaultEndAt}
          defaultWorkingAvailableDurationHours={defaultWorkingAvailableDurationHours}
          selectedShiftCell={selectedShiftCell.filter((cell) => (cell.date === it))}
          updateSelectedShiftCell={updateSelectedShiftCell}
        />
      </TableCell>
    ))}
  </>
));

export default HeaderCellsPresenter;
