import { SkipNext, SkipPrevious } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Theme,
  Tooltip,
  useTheme
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import DirectionSettingDialog from 'src/components/DirectionSettingDialog';
import { appBarHeight } from 'src/constants/layout';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { OrderSearchConditionEntity } from 'src/entities/OrderSearchCondition.entity';
import { RespectType, WeekDayEn } from 'src/entities/planningEntity';
import { PlanningOrderStatisticsEntity } from 'src/entities/PlanningOrderStatistics.entity';
import { AsyncPlanningResponseEntity } from 'src/entities/planningResponseEntity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningRunningEntity } from 'src/entities/PlanningsRunning.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { RelaxedRuleVo } from 'src/vo/RelaxedRule.vo';

import AllocateHistoryPresenter from '../V2Plans/presenters/AllocateHistoryPresenter';
import OrderFormPresenter from '../V2Plans/presenters/OrderFormPresenter';
import OrdersPresenter from '../V2Plans/presenters/OrdersPresenter';
import PlanningPresenter from '../V2Plans/presenters/PlanningPresenter';
import PlanningsTruckGroupSelectPresenter from '../V2Plans/presenters/PlanningsTruckGroupSelect.presenter';
import SelectPrintPresenter from '../V2Plans/presenters/SelectPrintPresenter';

import Calendar from './presenters/Calender';

type Props = {
  startDate: Date;
  endDate: Date;
  orderData: OrderEntity[];
  truckData: PlanningsTruckEntity[];
  deliveryData: PlanningsDeliveryEntity[];
  prevDeliveryData: PlanningsDeliveryEntity[];
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => Promise<void>;
  isLoading: boolean;
  groupEntities: PlanningsGroupEntity[];
  selectedGroupEntity: PlanningsGroupEntity | undefined;
  updateSelectedGroupEntity: (entity: PlanningsGroupEntity | undefined) => void;
  sendMail: () => void;
  mutateDeleteSpecificOrder: (orderId: number) => void;
  printOperationDirectionUrl: () => string;
  printAllTruckDirectionsOnClick: () => void;
  printUnloadTruckDirectionsOnClick: () => void;
  printPickingListOnClick: () => void;
  downloadPlanCsvOnClick: () => void;
  selectPrintDialogIsOpen: boolean;
  selectPrintButtonOnClick: () => void;
  selectPrintDialogClose: () => void;
  selectedOrderIds: number[];
  addSelectedOrderId: (id: number) => void;
  removeSelectedOrderId: (id: number) => void;
  unit: string;
  mutateDeleteOrder: () => void;
  mutateCloneOrder: (order: OrderEntity) => void;
  planningOrderStatisticsEntity: PlanningOrderStatisticsEntity | undefined;
  customInputFields: string[];
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  orderEntityMap: Map<number, OrderEntity>;
  directionSettingDialogIsOpen: boolean;
  updateDirectionSettingDialogIsOpen: (bool: boolean) => void;
  orderSearchKw: string;
  setOrderSearchKw: (kw: string) => void;
  currentlyOrderSearching: boolean;
  setCurrentlyOrderSearching: (searching: boolean) => void;
  mutateRestoreSplittedOrder: (orderId: number) => void;
  mutateRestoreSplittedOrders: () => void;
  planningRequest: (shiftIds: number[], orderIds: number[]) => void;
  updateBalancedLoading: (balancedLoading: boolean) => void;
  updateIsRespectRecent: (bool: boolean) => void;
  updateExcludeToll: (bool: boolean) => void;
  updateRespectType: (respectType: RespectType) => void;
  updateRespectDaysOfWeek: (days: WeekDayEn[]) => void;
  updateRespectOn: (on: string) => void;
  updateIsTimeTableBackward: (bool: boolean) => void;
  updateIncludeFutureDeliveries: (bool: boolean) => void;
  updateAutoSplitOrders: (bool: boolean) => void;
  updateDontExchangePreroute: (bool: boolean) => void;
  updateConcurrentAllOrNothing: (bool: boolean) => void;
  relaxedRules: RelaxedRuleVo[];
  selectedRelaxedRules: RelaxedRuleVo[];
  setSelectedRelaxedRules: (value: RelaxedRuleVo[]) => void;
  setPriorityLargeTrucks: (priority: number) => void;
  priorityLargeTrucks: number;
  updateExpandWorkingStartMinutes: (minutes: number) => void;
  updateExpandWorkingEndMinutes: (minutes: number) => void;
  updateExpandLoadStartMinutes: (minutes: number) => void;
  updateExpandLoadEndMinutes: (minutes: number) => void;
  updateExpandUnloadStartMinutes: (minutes: number) => void;
  updateExpandUnloadEndMinutes: (minutes: number) => void;
  updateExpandMaxVolumeRate: (rate: number) => void;
  updateExpandMaxLoadCapacityRate: (rate: number) => void;
  updateMLSourceType: (mlSourceTypes: number) => void;
  updateMLPlanning: (bool: boolean) => void;
  updateOptimizeForLoad: (bool: boolean) => void;
  updateForceNullBase: (bool: boolean) => void;
  updateTrunkTransportation: (bool: boolean) => void;
  setOrderSearchConditions: (conditions: OrderSearchConditionEntity[]) => void;
  notAllocReasons: PlanningsNotAllocReason[];
  planningRunningEntities: PlanningRunningEntity[];
  cancelRunningPlan: (id: number) => void;
  asyncPlanningResponse: AsyncPlanningResponseEntity;
  refreshRunningEntities: () => void;
  allocateHistories: AllocateHistoryEntity[];
  currentHistoryVersion: string | undefined;
  setCurrentHistoryVersion: (version: string) => void;
  resetCurrentHistoryVersion: () => void;
  rollbackRequest: () => void;
}

const Presenter: FC<Props> = memo(({
  startDate,
  endDate,
  orderData,
  truckData,
  deliveryData,
  prevDeliveryData,
  mutateDeleteOrdersOperations,
  isLoading,
  groupEntities,
  selectedGroupEntity,
  updateSelectedGroupEntity,
  sendMail,
  mutateDeleteSpecificOrder,
  printOperationDirectionUrl,
  printAllTruckDirectionsOnClick,
  printUnloadTruckDirectionsOnClick,
  printPickingListOnClick,
  downloadPlanCsvOnClick,
  selectPrintDialogIsOpen,
  selectPrintButtonOnClick,
  selectPrintDialogClose,
  selectedOrderIds,
  addSelectedOrderId,
  removeSelectedOrderId,
  unit,
  mutateDeleteOrder,
  mutateCloneOrder,
  planningOrderStatisticsEntity,
  customInputFields,
  driverEntities,
  deliveryEntities,
  orderEntityMap,
  directionSettingDialogIsOpen,
  updateDirectionSettingDialogIsOpen,
  orderSearchKw,
  setOrderSearchKw,
  currentlyOrderSearching,
  setCurrentlyOrderSearching,
  mutateRestoreSplittedOrder,
  mutateRestoreSplittedOrders,
  planningRequest,
  updateBalancedLoading,
  updateIsRespectRecent,
  updateExcludeToll,
  updateRespectType,
  updateRespectDaysOfWeek,
  updateRespectOn,
  updateIsTimeTableBackward,
  updateIncludeFutureDeliveries,
  updateAutoSplitOrders,
  updateDontExchangePreroute,
  updateConcurrentAllOrNothing,
  relaxedRules,
  selectedRelaxedRules,
  setSelectedRelaxedRules,
  setPriorityLargeTrucks,
  priorityLargeTrucks,
  updateExpandWorkingStartMinutes,
  updateExpandWorkingEndMinutes,
  updateExpandLoadStartMinutes,
  updateExpandLoadEndMinutes,
  updateExpandUnloadStartMinutes,
  updateExpandUnloadEndMinutes,
  updateExpandMaxVolumeRate,
  updateExpandMaxLoadCapacityRate,
  updateMLSourceType,
  updateMLPlanning,
  updateOptimizeForLoad,
  updateForceNullBase,
  updateTrunkTransportation,
  setOrderSearchConditions,
  notAllocReasons,
  planningRunningEntities,
  cancelRunningPlan,
  asyncPlanningResponse,
  refreshRunningEntities,
  allocateHistories,
  currentHistoryVersion,
  setCurrentHistoryVersion,
  resetCurrentHistoryVersion,
  rollbackRequest,
}) => {
  const theme: Theme = useTheme();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const licenseContext = useContext(LicenseContext);
  const [selectedDeliveryIds, setSelectedDeliveryIds] = useState<number[]>([]);
  const [runningSnackbarIsOpen, setRunningSnackbarIsOpen] = useState(false);
  const [runningSnackbarMessage, setRunningSnackbarMessage] = useState('');
  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout[]>([]);

  const [planningDialogIsOpen, setPlanningDialogIsOpen] = useState<boolean>(false);

  const handleRunningSnackbarClose = useCallback(() => {
    setRunningSnackbarIsOpen(false);
  }, []);

  const stopRunning = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('実行中の自動配車を停止します。よろしいですか？')) {
      const entity = planningRunningEntities[0];
      if (entity && entity.id) {
        cancelRunningPlan(entity.id);
      }
      handleRunningSnackbarClose();
    }
  }, [cancelRunningPlan, handleRunningSnackbarClose, planningRunningEntities]);

  const runningSnackbarAction = useMemo(() => (
    <>
      <Button size="small" color="secondary" onClick={stopRunning}>
        実行を停止する
      </Button>
      <Button size="small" color="secondary" onClick={handleRunningSnackbarClose}>
        閉じる
      </Button>
    </>
  ), [handleRunningSnackbarClose, stopRunning]);

  const setupRunningSnackbarMessage = useCallback(() => {
    if (!planningRunningEntities) return;

    const startAt = new Date(planningRunningEntities[0].createdAt);
    const diffInMinutes = Math.floor((new Date().getTime() - startAt.getTime()) / (1000 * 60));
    const estimate = asyncPlanningResponse?.estimate ?? '';
    let estimateText = '';
    if (estimate) {
      estimateText = `(${datetimeDecorator.toHourMinutes(new Date(estimate))}頃終了予定)`;
    }
    setRunningSnackbarMessage(`${diffInMinutes < 0 ? 0 : diffInMinutes} 分前から自動配車を実行しています。${estimateText}`);

    const tid = setTimeout(() => {
      setupRunningSnackbarMessage();
      refreshRunningEntities();
    }, 1000 * 60);
    setTimeoutIds((prev) => [...prev, tid]);
  }, [planningRunningEntities, refreshRunningEntities, asyncPlanningResponse]);

  useEffect(() => {
    if (!planningRunningEntities) return;

    if (planningRunningEntities.length > 0) {
      setRunningSnackbarIsOpen(true);
      setupRunningSnackbarMessage();
    } else {
      setRunningSnackbarIsOpen(false);
      setRunningSnackbarMessage('');
    }
  }, [planningRunningEntities, setupRunningSnackbarMessage]);

  const planningDialogOnClose = useCallback(() => {
    setPlanningDialogIsOpen(false);
  }, []);

  const unallocatedDrawerWidth = useMemo(() => 300, []);

  const datePickerStartOnChange = useCallback((newValue: Date) => {
    window.location.href = `/trunk-transportation/${format(newValue, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}`;
  }, []);

  const updateStartOn = useCallback((i: number) => {
    const newStartOn = addDays(startDate, 7 * i);
    window.location.href = `/trunk-transportation/${format(newStartOn, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}`;
  }, [startDate]);

  const [openUnallocatedOrders, setOpenUnallocatedOrders] = useState<boolean>(true);

  const toggleDrawer = useCallback(() => {
    setOpenUnallocatedOrders(!openUnallocatedOrders);
  }, [openUnallocatedOrders]);

  const [groupedDeliveryEntities, setGroupedDeliveryEntities] = useState<Record<string, PlanningsDeliveryEntity[]>>({});

  useEffect(() => {
    const MAX_SORT_ORDER = 999999;
    const grouped = deliveryEntities.sort((a, b) => {
      const aTruck = truckData.find((it) => it.id === a.truckId);
      const bTruck = truckData.find((it) => it.id === b.truckId);
      let ret = (aTruck.sortOrder ?? MAX_SORT_ORDER) - (bTruck.sortOrder ?? MAX_SORT_ORDER);
      if (ret !== 0) return ret;

      const aDriver = driverEntities.find((it) => it.id === a.driverId);
      const bDriver = driverEntities.find((it) => it.id === b.driverId);
      ret = (aDriver.sortOrder ?? MAX_SORT_ORDER) - (bDriver.sortOrder ?? MAX_SORT_ORDER);
      if (ret !== 0) return ret;

      ret = aTruck.id - bTruck.id;
      if (ret !== 0) return ret;

      ret = aDriver.id - bDriver.id;
      if (ret !== 0) return ret;

      ret = a.startAt.localeCompare(b.startAt);
      if (ret !== 0) return ret;
      ret = a.endAt.localeCompare(b.endAt);
      return ret;
    }).filter((it) => {
      if (selectedGroupEntity?.truckIds.length > 0) {
        return selectedGroupEntity.truckIds.includes(it.truckId);
      }
      return true;
    }).reduce((acc, cur) => {
      const key = `truck:${cur.truckId}-driver:${cur.driverId}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(cur);
      return acc;
    }, {} as Record<string, PlanningsDeliveryEntity[]>);
    setGroupedDeliveryEntities(grouped);
  }, [deliveryEntities, driverEntities, selectedGroupEntity, truckData]);

  const calendarMemo = useMemo(() => (
    <Calendar
      startDate={startDate}
      endDate={endDate}
      isLoading={isLoading}
      appBarHeight={appBarHeight}
      groupedDeliveryEntities={groupedDeliveryEntities}
      truckData={truckData}
      driverEntities={driverEntities}
      deliveryData={deliveryData}
      prevDeliveryData={prevDeliveryData}
      mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
      selectedDeliveryIds={selectedDeliveryIds}
      setSelectedDeliveryIds={setSelectedDeliveryIds}
    />
  ), [deliveryData, driverEntities, endDate, groupedDeliveryEntities, isLoading, mutateDeleteOrdersOperations, prevDeliveryData, selectedDeliveryIds, startDate, truckData]);

  const resetAllocateHistories = useCallback(() => {
    // void
  }, []);

  const ordersMemo = useMemo(() => {
    const orderIds = orderData.map((it) => it.id);
    const allocatedOrderIds = deliveryData ? deliveryData.flatMap((it) => it.operations).map((it) => it.orderId) : [];
    const unallocatedOrderIds = orderIds.filter((it) => !allocatedOrderIds.includes(it));

    return (
      <OrdersPresenter
        ids={unallocatedOrderIds}
        selectedIds={selectedOrderIds}
        addSelectedId={addSelectedOrderId}
        removeSelectedId={removeSelectedOrderId}
        unit={unit}
        isLoading={isLoading}
        startOn={datetimeDecorator.toYyyyMmDd(startDate)}
        endOn={datetimeDecorator.toYyyyMmDd(endDate)}
        mutateDeleteOrder={mutateDeleteOrder}
        mutateDeleteSpecificOrder={mutateDeleteSpecificOrder}
        mutateCloneOrder={mutateCloneOrder}
        planningOrderStatisticsEntity={planningOrderStatisticsEntity}
        resetAllocateHistories={resetAllocateHistories}
        customInputFields={customInputFields}
        notAllocReasons={notAllocReasons}
        truckEntities={truckData}
        driverEntities={driverEntities}
        deliveryEntities={deliveryEntities}
        orderEntityMap={orderEntityMap}
        unallocatedDrawerWidth={unallocatedDrawerWidth}
        orderSearchKw={orderSearchKw}
        setOrderSearchKw={setOrderSearchKw}
        currentlyOrderSearching={currentlyOrderSearching}
        setCurrentlyOrderSearching={setCurrentlyOrderSearching}
        setOrderSearchConditions={setOrderSearchConditions}
        mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
        mutateRestoreSplittedOrders={mutateRestoreSplittedOrders}
      />
    );
  }, [orderData, deliveryData, selectedOrderIds, addSelectedOrderId, removeSelectedOrderId, unit, isLoading, startDate, endDate, mutateDeleteOrder, mutateDeleteSpecificOrder, mutateCloneOrder, planningOrderStatisticsEntity, resetAllocateHistories, customInputFields, notAllocReasons, truckData, driverEntities, deliveryEntities, orderEntityMap, unallocatedDrawerWidth, orderSearchKw, setOrderSearchKw, currentlyOrderSearching, setCurrentlyOrderSearching, setOrderSearchConditions, mutateRestoreSplittedOrder, mutateRestoreSplittedOrders]);

  const [allocatedHistoriesDialogIsOpen, setAllocatedHistoriesDialogIsOpen] = useState<boolean>(false);
  const [allocateHistoriesForCompany, setAllocateHistoriesForCompany] = useState<AllocateHistoryEntity[]>([]);
  useEffect(() => {
    const list = allocateHistories.filter((it) => it.companyId === licenseContext.config?.selected_company_id);
    setAllocateHistoriesForCompany(list);
  }, [allocateHistories, licenseContext.config?.selected_company_id]);

  const allocateHistoryListItemButtonOnClick = useCallback((version: string) => {
    setCurrentHistoryVersion(version);
  }, [setCurrentHistoryVersion]);

  const allocateHistoryButtonOnClick = useCallback(() => {
    ReactGA.event('apply', { screen_name: SCREEN_NAMES.TRUNK_TRANSPORTATION, button_name: '元に戻す' });
    rollbackRequest();
  }, [rollbackRequest]);

  const allocateHistoryCancelButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.TRUNK_TRANSPORTATION, button_name: '元に戻す キャンセル' });
    resetCurrentHistoryVersion();
    setAllocatedHistoriesDialogIsOpen(false);
  }, [resetCurrentHistoryVersion]);

  const allocateHistoryMemo = useMemo(() => (
    <AllocateHistoryPresenter
      allocateHistoriesForCompany={allocateHistoriesForCompany}
      allocateHistoryListItemButtonOnClick={allocateHistoryListItemButtonOnClick}
      allocateHistoryButtonOnClick={allocateHistoryButtonOnClick}
      allocateHistoryCancelButtonOnClick={allocateHistoryCancelButtonOnClick}
      isLoading={isLoading}
      currentHistoryVersion={currentHistoryVersion}
      unallocatedDrawerWidth={unallocatedDrawerWidth}
    />
  ), [allocateHistoriesForCompany, allocateHistoryButtonOnClick, allocateHistoryCancelButtonOnClick, allocateHistoryListItemButtonOnClick, currentHistoryVersion, isLoading, unallocatedDrawerWidth]);

  const unallocatedOrdersMemo = useMemo(() => (
    <Stack>
      <IconButton
        sx={{
          position: 'fixed',
          right: openUnallocatedOrders ? `${unallocatedDrawerWidth - 5}px` : '-5px',
          bottom: '5%',
          zIndex: 998,
          backgroundColor: theme.palette.background.paper,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.colors.secondary.light,
        }}
        onClick={() => toggleDrawer()}
      >
        {openUnallocatedOrders
        ? <KeyboardArrowRightIcon />
        : <KeyboardArrowLeftIcon />}
      </IconButton>
      <Box
        sx={{
          width: openUnallocatedOrders ? unallocatedDrawerWidth : 0,
          bgcolor: theme.colors.alpha.white[100],
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 999,
          overflow: 'hidden',
          height: `calc(100vh - ${appBarHeight}px)`,
        }}
      >
        {
          !allocatedHistoriesDialogIsOpen
            ? ordersMemo
            : allocateHistoryMemo
        }
      </Box>
    </Stack>
  ), [openUnallocatedOrders, unallocatedDrawerWidth, theme.palette.background.paper, theme.colors.secondary.light, theme.colors.alpha.white, allocatedHistoriesDialogIsOpen, ordersMemo, allocateHistoryMemo, toggleDrawer]);

  const truckGroupMemo = useMemo(() => (
    <Stack
      pl={1}
      minWidth="200px"
    >
      <PlanningsTruckGroupSelectPresenter
        options={groupEntities}
        value={selectedGroupEntity}
        onChange={updateSelectedGroupEntity}
        fullWidth={false}
      />
    </Stack>
  ), [groupEntities, selectedGroupEntity, updateSelectedGroupEntity]);

  const dateSelectMemo = useMemo(() => (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      minWidth="200px"
    >
      <DatePicker
        value={startDate}
        disabled={isLoading}
        onChange={datePickerStartOnChange}
        label="開始日"
        renderInput={(params) => (
          <Tooltip
            title="配車計画開始日を変更する"
            arrow
          >
            <TextField
              {...params}
              size="small"
              sx={{
                width: 155,
                p: 0,
                m: 0,
              }}
            />
          </Tooltip>
        )}
      />
      <LoadingButton
        startIcon={<SkipPrevious />}
        loading={isLoading}
        onClick={() => updateStartOn(-1)}
        sx={{ p: 0, m: 0 }}
      >
        先週
      </LoadingButton>
      <LoadingButton
        startIcon={<SkipNext />}
        loading={isLoading}
        onClick={() => updateStartOn(1)}
        sx={{ p: 0, m: 0 }}
      >
        来週
      </LoadingButton>
    </Stack>
  ), [datePickerStartOnChange, isLoading, startDate, updateStartOn]);

  const selectPrintMemo = useMemo(() => (
    <SelectPrintPresenter
      printAllOperationDirectionsOnClick={() => {
        updateDirectionSettingDialogIsOpen(true);
      }}
      printAllTruckDirectionsOnClick={printAllTruckDirectionsOnClick}
      printUnloadTruckDirectionsOnClick={printUnloadTruckDirectionsOnClick}
      printPickingListOnClick={printPickingListOnClick}
      downloadPlanCsvOnClick={downloadPlanCsvOnClick}
    />
  ), [
    printAllTruckDirectionsOnClick,
    printUnloadTruckDirectionsOnClick,
    printPickingListOnClick,
    downloadPlanCsvOnClick,
    updateDirectionSettingDialogIsOpen,
  ]);

  const [newOrderDialogIsOpen, setNewOrderDialogIsOpen] = useState(false);

  const openDialog = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.TRUNK_TRANSPORTATION, button_name: '案件作成' });
    setNewOrderDialogIsOpen(true);
  }, []);

  const dialogOnClose = useCallback(() => {
    setNewOrderDialogIsOpen(false);
  }, []);

  const dialogOnCloseWithResetAllocateHistories = useCallback(() => {
    resetAllocateHistories();
    setNewOrderDialogIsOpen(false);
  }, [resetAllocateHistories]);

  const orderFormDialogMemo = useMemo(() => (
    <OrderFormPresenter
      dialogIsOpen={newOrderDialogIsOpen}
      dialogOnClose={dialogOnClose}
      orderId={0}
      startOn={datetimeDecorator.toYyyyMmDd(startDate)}
      endOn={datetimeDecorator.toYyyyMmDd(startDate)}
      onClose={dialogOnCloseWithResetAllocateHistories}
    />
  ), [newOrderDialogIsOpen, dialogOnClose, dialogOnCloseWithResetAllocateHistories, startDate]);

  const actionsMemo = useMemo(() => {
    const driverAppButtonText = 'ドライバーに通知する';
    const driverAppClickFunction = () => {
      const confirmMsg = 'ドライバーにメールを送信します。よろしいですか？';
      const confirm = window.confirm(confirmMsg);
      if (!confirm) return;

      sendMail();
    };

    const doPlanning = () => {
      ReactGA.event('click', { screen_name: SCREEN_NAMES.TRUNK_TRANSPORTATION, button_name: '自動配車' });
      const targets = [
        selectedDeliveryIds.length === 0 ? '車両' : '',
      ].filter((it) => it);
      if (targets.length > 0) {
        closeSnackbar();
        const message = `配車する${targets.join('・')}を選択してください。`;
        ReactGA.event('error', { screen_name: SCREEN_NAMES.TRUNK_TRANSPORTATION, button_name: '自動配車', label: message });
        enqueueSnackbar(message);
        return;
      }
      setPlanningDialogIsOpen(true);
    };

    const buttons = [
      {
        text: '自動配車',
        click: () => { doPlanning(); },
        variant: 'contained' as 'text' | 'contained' | 'outlined',
      },
      {
        text: 'もとに戻す',
        click: () => { setAllocatedHistoriesDialogIsOpen(true); }
      },
      {
        text: '勤務計画',
        click: () => { navigate(`/shifts/${datetimeDecorator.toYyyyMmDd(startDate)}/${datetimeDecorator.toYyyyMmDd(endDate)}/trunk-transportation`); }
      },
      {
        text: '案件インポート',
        click: () => { navigate('/import'); }
      },
      {
        text: '案件作成',
        click: () => { openDialog(); }
      },
      {
        text: '配送指示書',
        click: () => { selectPrintButtonOnClick(); }
      },
    ];
    if (licenseContext?.config?.use_driver_app) {
      buttons.push({
        text: driverAppButtonText,
        click: () => { driverAppClickFunction(); }
      });
    }

    return (
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
      >
        {buttons.map((button) => (
          <Button
            onClick={button.click}
            key={button.text}
            variant={button.variant || 'outlined'}
            sx={{ borderRadius: '5px', height: '35px', p: 1.5, }}
          >
            {button.text}
          </Button>
        ))}
      </Stack>
    );
  }, [licenseContext?.config?.use_driver_app, sendMail, selectedDeliveryIds.length, closeSnackbar, enqueueSnackbar, navigate, startDate, endDate, openDialog, selectPrintButtonOnClick]);

  const planningMemo = useMemo(() => (
    <Dialog
      open={planningDialogIsOpen}
      onClose={planningDialogOnClose}
      fullWidth
      maxWidth="md"
    >
      <PlanningPresenter
        planningRequest={planningRequest}
        updateBalancedLoading={updateBalancedLoading}
        updateIsRespectRecent={updateIsRespectRecent}
        updateExcludeToll={updateExcludeToll}
        onClose={planningDialogOnClose}
        selectedDeliveryIds={selectedDeliveryIds}
        selectedOrderIds={selectedOrderIds}
        startOn={datetimeDecorator.toYyyyMmDd(startDate)}
        updateRespectType={updateRespectType}
        updateRespectDaysOfWeek={updateRespectDaysOfWeek}
        updateRespectOn={updateRespectOn}
        updateIsTimeTableBackward={updateIsTimeTableBackward}
        updateIncludeFutureDeliveries={updateIncludeFutureDeliveries}
        updateAutoSplitOrders={updateAutoSplitOrders}
        updateDontExchangePreroute={updateDontExchangePreroute}
        updateConcurrentAllOrNothing={updateConcurrentAllOrNothing}
        relaxedRules={relaxedRules}
        selectedRelaxedRules={selectedRelaxedRules}
        setSelectedRelaxedRules={setSelectedRelaxedRules}
        setPriorityLargeTrucks={setPriorityLargeTrucks}
        priorityLargeTrucks={priorityLargeTrucks}
        updateExpandWorkingStartMinutes={updateExpandWorkingStartMinutes}
        updateExpandWorkingEndMinutes={updateExpandWorkingEndMinutes}
        updateExpandLoadStartMinutes={updateExpandLoadStartMinutes}
        updateExpandLoadEndMinutes={updateExpandLoadEndMinutes}
        updateExpandUnloadStartMinutes={updateExpandUnloadStartMinutes}
        updateExpandUnloadEndMinutes={updateExpandUnloadEndMinutes}
        updateExpandMaxVolumeRate={updateExpandMaxVolumeRate}
        updateExpandMaxLoadCapacityRate={updateExpandMaxLoadCapacityRate}
        mlSourceTypes={licenseContext?.config?.ml_source_types || []}
        updateMLSourceType={updateMLSourceType}
        updateMLPlanning={updateMLPlanning}
        updateOptimizeForLoad={updateOptimizeForLoad}
        updateForceNullBase={updateForceNullBase}
        updateTrunkTransportation={updateTrunkTransportation}
      />
    </Dialog>
  ), [planningDialogIsOpen, planningDialogOnClose, planningRequest, updateBalancedLoading, updateIsRespectRecent, updateExcludeToll, selectedDeliveryIds, selectedOrderIds, startDate, updateRespectType, updateRespectDaysOfWeek, updateRespectOn, updateIsTimeTableBackward, updateIncludeFutureDeliveries, updateAutoSplitOrders, updateDontExchangePreroute, updateConcurrentAllOrNothing, relaxedRules, selectedRelaxedRules, setSelectedRelaxedRules, setPriorityLargeTrucks, priorityLargeTrucks, updateExpandWorkingStartMinutes, updateExpandWorkingEndMinutes, updateExpandLoadStartMinutes, updateExpandLoadEndMinutes, updateExpandUnloadStartMinutes, updateExpandUnloadEndMinutes, updateExpandMaxVolumeRate, updateExpandMaxLoadCapacityRate, licenseContext?.config?.ml_source_types, updateMLSourceType, updateMLPlanning, updateOptimizeForLoad, updateForceNullBase, updateTrunkTransportation]);

  const runningSnackbar = useMemo(() => (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={runningSnackbarIsOpen}
      key="RunningSnackbar"
    >
      <SnackbarContent
        message={runningSnackbarMessage}
        action={runningSnackbarAction}
      />
    </Snackbar>
  ), [runningSnackbarAction, runningSnackbarIsOpen, runningSnackbarMessage]);

  const backDropMemo = useMemo(() => (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ), [isLoading, theme.zIndex.drawer]);

  return (
    <>
      <Helmet>
        <title>配車計画 | 幹線輸送</title>
      </Helmet>
      <Paper>
        {orderFormDialogMemo}
        {runningSnackbar}
        {planningMemo}
        {backDropMemo}
        <Stack>
          <Stack
            sx={{
              marginRight: openUnallocatedOrders ? `${unallocatedDrawerWidth}px` : 0,
              minWidth: theme.breakpoints.values.md,
            }}
          >
            <Stack
              pt={1}
              mb={1}
              direction="column"
              justifyContent="flex-start"
            >
              <Stack direction="row" alignItems="flex-start" gap={2} mb={1}>
                {truckGroupMemo}
                {dateSelectMemo}
              </Stack>
              <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" gap={2} pl={1}>
                {actionsMemo}
              </Stack>
            </Stack>
            {calendarMemo}
          </Stack>
          {unallocatedOrdersMemo}
        </Stack>
        <Dialog
          open={selectPrintDialogIsOpen}
          onClose={selectPrintDialogClose}
        >
          {selectPrintMemo}
        </Dialog>
        <DirectionSettingDialog
          open={directionSettingDialogIsOpen}
          onClose={() => {
            updateDirectionSettingDialogIsOpen(false);
          }}
          width="md"
          navigateToPath={printOperationDirectionUrl()}
        />
      </Paper>
    </>
  );
});

export default Presenter;
