import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import LicenseContext from 'src/contexts/LicenseContext';
import { queryKey } from 'src/hooks/useOrders.request';

import MyGridToolbarFilterButton from '../common/MyGridToolbarFilterButton';

const CustomToolbar: FC<{
  startOn: Date;
  endOn: Date;
  removeSelectedOrders: () => Promise<void>;
  resetSelectedOrders: () => Promise<void>;
  toggleDialogIsOpen: () => void;
  setIsLoading: (prevState: boolean) => boolean;
  isLoading: boolean;
  handleDateRange: (range: [Date, Date]) => void;
  canDeleteAll: boolean;
  deleteAll: (settledFnk: () => void) => void;
  handleDownloadExcel: () => void;
  isPlanningView: boolean;
}> = memo(
  ({
    startOn,
    endOn,
    removeSelectedOrders,
    resetSelectedOrders,
    toggleDialogIsOpen,
    setIsLoading,
    isLoading,
    handleDateRange,
    canDeleteAll,
    deleteAll,
    handleDownloadExcel,
    isPlanningView,
  }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const licenseContext = useContext(LicenseContext);

    const queryClient = useQueryClient();
    const [canUseUpload, setCanUseUpload] = useState<boolean>(false);
    const [deleteHandlerDialogIsOpen, setDeleteHandlerDialogIsOpen] = useState<boolean>(false);

    const deleteHandlerDialogOnClose = () => {
      setDeleteHandlerDialogIsOpen(false);
    };

    const deleteBtnOnClick = () => {
      if (canDeleteAll) {
        setDeleteHandlerDialogIsOpen(true);

        return;
      }

      removeSelectedOrders().finally(() => {
        setDeleteHandlerDialogIsOpen(false);
      });
    };

    useEffect(() => {
      if (!licenseContext.config) return;

      setCanUseUpload(licenseContext.config.selected_company_id !== 0);
    }, [licenseContext.config]);

    const [importFile, setImportFile] = useState<File | undefined>(undefined);
    const importButtonOnClick = (event: ChangeEvent<HTMLInputElement>) => {
      setImportFile(event.target.files[0]);
    };

    const [importForUpdateFile, setImportForUpdateFile] = useState<File | undefined>(undefined);
    const importForUpdateButtonOnClick = (event: ChangeEvent<HTMLInputElement>) => {
      setImportForUpdateFile(event.target.files[0]);
    };

    useEffect(() => {
      if (!importFile) return;

      const requestPath = '/api/v2/orders/import';

      const data = new FormData();
      data.append('file', importFile);

      setIsLoading(true);
      enqueueSnackbar('インポートを開始します');

      axios
        .post(requestPath, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(
          (response: AxiosResponse<{ severity: string; message: string }>) => {
            enqueueSnackbar(response.data.message);
          }
        )
        .catch((e) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (e.response.status === 400) {
            enqueueSnackbar(
              '取り込めない注文がありました、データをご確認ください。'
            );
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
            const blob = new Blob([e.response.data], {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
              type: e.response.data.type
            });
            saveAs(blob, 'errors.txt');
          } else {
            enqueueSnackbar(fiveZeroZeroErrorMessage);
          }
        })
        .finally(() => {
          setImportFile(undefined);
          setIsLoading(false);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries([queryKey]);
          selectImportMethodDialogOnClose();
        });
    }, [enqueueSnackbar, importFile]);

    useEffect(() => {
      if (!importForUpdateFile) return;

      const requestPath = '/api/v3/orders/import_for_update';

      const data = new FormData();
      data.append('file', importForUpdateFile);

      setIsLoading(true);
      enqueueSnackbar('インポートを開始します');

      axios.post(requestPath, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(
        (response: AxiosResponse<{ severity: string; message: string }>) => {
          enqueueSnackbar(response.data.message);
        }
      ).catch((e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (e.response.status === 400) {
          enqueueSnackbar(
            '取り込めない注文がありました、データをご確認ください。'
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
          const blob = new Blob([e.response.data], {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            type: e.response.data.type
          });
          saveAs(blob, 'errors.txt');
        } else {
          enqueueSnackbar(fiveZeroZeroErrorMessage);
        }
      })
      .finally(() => {
        setImportForUpdateFile(undefined);
        setIsLoading(false);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['orders']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries([queryKey]);
        selectImportMethodDialogOnClose();
      });
    }, [enqueueSnackbar, importForUpdateFile, queryClient, setIsLoading]);

    const [selectImportMethodDialogIsOpen, setSelectImportMethodDialogIsOpen] = useState<boolean>(false);
    const selectImportMethodButtonOnClick = () => {
      setSelectImportMethodDialogIsOpen(true);
    };
    const selectImportMethodDialogOnClose = () => {
      setSelectImportMethodDialogIsOpen(false);
    };

    const customImportButtonOnClick = () => {
      navigate('/import');
    };

    return (
      <>
        <GridToolbarContainer
          sx={{
            width: '100%'
          }}
        >
          <Dialog
            open={selectImportMethodDialogIsOpen}
            onClose={selectImportMethodDialogOnClose}
            fullWidth
            maxWidth="md"
            // maxWidth="sm"
          >
            <DialogTitle>インポート形式を選択してください</DialogTitle>
            <DialogContent>
              <Stack
                direction="row"
                gap={1}
              >
                <LoadingButton
                  fullWidth
                  variant="outlined"
                  loading={isLoading}
                  disabled={isLoading || !canUseUpload}
                >
                  標準ファイルアップロード
                  <input
                    type="file"
                    disabled={isLoading || !canUseUpload}
                    onChange={importButtonOnClick}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{
                      opacity: 0,
                      appearance: 'none',
                      position: 'absolute',
                      width: '100%',
                      cursor: 'pointer'
                    }}
                  />
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  variant="outlined"
                  loading={isLoading}
                  onClick={customImportButtonOnClick}
                >
                  カスタムフォーマット
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  variant="outlined"
                  loading={isLoading}
                  disabled={isLoading || !canUseUpload}
                >
                  上書きアップロード (エクスポートしたファイルを利用してください)
                  <input
                    type="file"
                    disabled={isLoading || !canUseUpload}
                    onChange={importForUpdateButtonOnClick}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{
                      opacity: 0,
                      appearance: 'none',
                      position: 'absolute',
                      width: '100%',
                      cursor: 'pointer'
                    }}
                  />
                </LoadingButton>
              </Stack>
            </DialogContent>
          </Dialog>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              flexGrow={1}
            >
              {!isPlanningView && (
                <Stack
                  direction="row"
                  gap={0.3}
                >
                  <DateRangePicker
                    value={[
                      startOn,
                      endOn
                    ]}
                    onChange={handleDateRange}
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField
                          {...startProps}
                          label="開始"
                          size="small"
                          sx={{
                            maxWidth: '120px'
                          }}
                        />
                        <Box sx={{ mx: 0.3 }}> から </Box>
                        <TextField
                          {...endProps}
                          label="終了"
                          size="small"
                          sx={{
                            maxWidth: '120px'
                          }}
                        />
                      </>
                    )}
                  />
                </Stack>
              )}
              <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
              <MyGridToolbarFilterButton />
              <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
              <Button
                startIcon={<CloudUploadRoundedIcon />}
                sx={{
                  backgroundColor: '#fff'
                }}
                onClick={selectImportMethodButtonOnClick}
              >
                インポート
              </Button>
              <Button
                startIcon={<SystemUpdateAltRoundedIcon />}
                onClick={handleDownloadExcel}
                sx={{ textTransform: 'none' }}
              >
                エクスポート
              </Button>
              <Button
                startIcon={<AddCircleOutlinedIcon />}
                onClick={toggleDialogIsOpen}
              >
                追加・修正
              </Button>
              <Button
                onClick={deleteBtnOnClick}
                startIcon={<DeleteForeverOutlinedIcon />}
              >
                削除
              </Button>
              <Button
                onClick={() => {
                  (async () => {
                    await resetSelectedOrders();
                  })().catch((e) => {
                    console.log(e);
                  });
                }}
                startIcon={<RotateLeftRoundedIcon />}
              >
                割当解除
              </Button>
            </Box>
          </Box>
        </GridToolbarContainer>
        <Dialog
          open={deleteHandlerDialogIsOpen}
          onClose={deleteHandlerDialogOnClose}
        >
          <DialogTitle>
            削除する対象を選択してください
          </DialogTitle>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              startIcon={<DeleteForeverRoundedIcon />}
              onClick={() => {
                deleteAll(deleteHandlerDialogOnClose);
              }}
            >
              すべて削除する
            </Button>
            <Button
              onClick={() => {
                removeSelectedOrders().finally(() => {
                  setDeleteHandlerDialogIsOpen(false);
                });
              }}
              variant="outlined"
              startIcon={<DeleteSweepRoundedIcon />}
            >
              選択したものを削除する
            </Button>
            <Button
              onClick={deleteHandlerDialogOnClose}
              startIcon={<HighlightOffRoundedIcon />}
            >
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default CustomToolbar;
