// 配列を定義
export const RelaxedRuleValues = [
  '労働時間',
  '指定時間',
  '重量',
  '体積',
  '指定車両タイプ',
  '指定車種',
  '荷台高さ',
  '荷台幅',
  '荷台長さ',
  '床仕様',
  '装置',
  'ピストン回数',
  '1回転あたりの降地',
  '案件数上限',
  '積み合わせ',
  '指定トラック',
  'NGドライバー',
  'トラックの上限ピストン回数',
  '１回転あたりの上限納品先件数',
  'エリア配車',
] as const;

// 配列から型を生成
export type RelaxedRuleVo = typeof RelaxedRuleValues[number];
