import { HelpOutline } from '@mui/icons-material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import BallotIcon from '@mui/icons-material/Ballot';
import BusinessIcon from '@mui/icons-material/Business';
import DirectionsIcon from '@mui/icons-material/Directions';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import GarageIcon from '@mui/icons-material/Garage';
import GroupIcon from '@mui/icons-material/Group';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import { Box, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { FC, memo } from 'react';

type Props = {
  navigateToKpis: () => void;
  handlePlanningMenuOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  planningMenuAnchorEl: null | HTMLElement;
  handlePlanningMenuOnClose: () => void;
  navigateToPlanningMap: () => void;
  navigateToPlanningTimeline: () => void;
  navigateToOrders: () => void;
  handleSettingMenuOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  settingMenuAnchorEl: null | HTMLElement;
  handleSettingMenuOnClose: () => void;
  navigateToGarages: () => void;
  navigateToTrucks: () => void;
  navigateToGroups: () => void;
  navigateToDrivers: () => void;
  navigateToPlaces: () => void;
  navigateToAreas: () => void;
  navigateToConfig: () => void;
  handleAccountMenuOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  accountMenuAnchorEl: null | HTMLElement;
  handleAccountMenuOnClose: () => void;
  navigateToAccounts: () => void;
  isAdmin: () => boolean;
  navigateToCompany: () => void;
  logout: () => void;
  useTrunkTransportation: boolean;
  navigateToPlanningTrunkTransportation: () => void;
}

const AppBarSmMenuComponent: FC<Props> = memo((
  {
    navigateToKpis,
    handlePlanningMenuOnClick,
    planningMenuAnchorEl,
    handlePlanningMenuOnClose,
    navigateToPlanningMap,
    navigateToPlanningTimeline,
    navigateToOrders,
    handleSettingMenuOnClick,
    settingMenuAnchorEl,
    handleSettingMenuOnClose,
    navigateToGarages,
    navigateToTrucks,
    navigateToGroups,
    navigateToDrivers,
    navigateToPlaces,
    navigateToAreas,
    navigateToConfig,
    handleAccountMenuOnClick,
    accountMenuAnchorEl,
    handleAccountMenuOnClose,
    navigateToAccounts,
    isAdmin,
    navigateToCompany,
    logout,
    useTrunkTransportation,
    navigateToPlanningTrunkTransportation,
  }
) => (
  <>
    <Box pl={3} sx={{ flexGrow: 1, display: 'flex' }}>
      <Tooltip title="実績参照">
        <IconButton
          onClick={
            () => {
              navigateToKpis();
            }
          }
        >
          <InsertChartOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="配車計画">
        <IconButton
          onClick={
            handlePlanningMenuOnClick
          }
        >
          <ShareLocationOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="appbar-planning-menu"
        anchorEl={planningMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(planningMenuAnchorEl)}
        onClose={handlePlanningMenuOnClose}
        MenuListProps={{ sx: { p: 0, m: 0, } }}
      >
        <MenuItem
          onClick={
            () => {
              navigateToPlanningMap();
            }
          }
        >
          <ShareLocationOutlinedIcon
            sx={{
              mr: 1
            }}
          />
          マップ
        </MenuItem>
        <MenuItem
          onClick={
            () => {
              navigateToPlanningTimeline();
            }
          }
        >
          <ViewTimelineIcon
            sx={{
              mr: 1
            }}
          />
          タイムライン
        </MenuItem>
        <Stack direction="row" alignItems="center">
          <MenuItem
            onClick={
                  () => {
                    navigateToPlanningTrunkTransportation();
                  }
                }
            disabled={!useTrunkTransportation}
          >
            <DirectionsIcon
              sx={{
                    mr: 1
                  }}
            />
            幹線輸送
          </MenuItem>
          {!useTrunkTransportation && (
            <Tooltip
              title="幹線輸送機能を利用したい場合はセールス (sales@logpose.co.jp) へご連絡ください。"
            >
              <HelpOutline />
            </Tooltip>
          )}
        </Stack>
      </Menu>
      <Tooltip title="案件一覧">
        <IconButton
          onClick={
            () => {
              navigateToOrders();
            }
          }
        >
          <BallotIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="設定">
        <IconButton
          onClick={
            handleSettingMenuOnClick
          }
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="appbar-setting-menu"
        anchorEl={settingMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(settingMenuAnchorEl)}
        onClose={handleSettingMenuOnClose}
        MenuListProps={{ sx: { p: 0, m: 0, } }}
      >
        <MenuItem
          onClick={
            () => {
              navigateToGarages();
            }
          }
        >
          <GarageIcon
            sx={{
              mr: 1
            }}
          />
          車庫管理
        </MenuItem>
        <MenuItem
          onClick={
            () => {
              navigateToTrucks();
            }
          }
        >
          <DirectionsCarIcon
            sx={{
              mr: 1
            }}
          />
          車両管理
        </MenuItem>
        <MenuItem
          onClick={
            () => {
              navigateToGroups();
            }
          }
        >
          <GroupIcon
            sx={{
              mr: 1
            }}
          />
          車両グループ管理
        </MenuItem>
        <MenuItem
          onClick={
            () => {
              navigateToDrivers();
            }
          }
        >
          <AirlineSeatReclineExtraIcon
            sx={{
              mr: 1
            }}
          />
          ドライバー管理
        </MenuItem>
        <MenuItem
          onClick={
            () => {
              navigateToAreas();
            }
          }
        >
          <FolderCopyIcon
            sx={{
              mr: 1
            }}
          />
          エリア管理
        </MenuItem>
        <MenuItem
          onClick={
            () => {
              navigateToPlaces();
            }
          }
        >
          <PlaceIcon
            sx={{
              mr: 1
            }}
          />
          地点管理
        </MenuItem>
        <MenuItem
          onClick={
            () => {
              navigateToConfig();
            }
          }
        >
          <PsychologyRoundedIcon
            sx={{
              mr: 1
            }}
          />
          計算エンジン設定
        </MenuItem>
      </Menu>
    </Box>
    <Box>
      <IconButton
        onClick={handleAccountMenuOnClick}
      >
        <AccountCircleRoundedIcon />
      </IconButton>
    </Box>
    <Menu
      id="appbar-account-menu"
      anchorEl={accountMenuAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(accountMenuAnchorEl)}
      onClose={handleAccountMenuOnClose}
      MenuListProps={{ sx: { p: 0, m: 0, } }}
    >
      <MenuItem
        onClick={
          () => {
            navigateToAccounts();
          }
        }
      >
        <PersonIcon
          sx={{
            mr: 1
          }}
        />
        アカウント管理
      </MenuItem>
      {
        isAdmin() && (
          <MenuItem
            onClick={
              () => {
                navigateToCompany();
              }
            }
          >
            <BusinessIcon
              sx={{
                mr: 1
              }}
            />
            会社情報
          </MenuItem>
        )
      }
      <MenuItem
        onClick={
          () => {
            logout();
          }
        }
      >
        <LogoutRoundedIcon
          sx={{
            mr: 1
          }}
        />
        ログアウト
      </MenuItem>
    </Menu>
  </>
));

export default AppBarSmMenuComponent;
