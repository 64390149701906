import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import { Button, Chip, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';

type Props = {
  allocateHistoriesForCompany: AllocateHistoryEntity[];
  allocateHistoryListItemButtonOnClick: (version: string) => void;
  allocateHistoryButtonOnClick: () => void;
  allocateHistoryCancelButtonOnClick: () => void;
  isLoading: boolean;
  currentHistoryVersion: string | undefined;
  unallocatedDrawerWidth: number;
}

const AllocateHistoryPresenter: FC<Props> = memo(({
  allocateHistoriesForCompany,
  allocateHistoryListItemButtonOnClick,
  allocateHistoryButtonOnClick,
  allocateHistoryCancelButtonOnClick,
  isLoading,
  currentHistoryVersion,
  unallocatedDrawerWidth,
}) => (
  <Stack
    width={unallocatedDrawerWidth}
  >
    <Stack
      position="fixed"
      zIndex={100}
      width="100%"
    >
      <Paper
        sx={{
          pt: 2,
          px: 2,
          pb: 1,
        }}
        square
      >
        <Typography>
          操作履歴
        </Typography>
        <Stack
          direction="row"
          gap={1}
        >
          <Button
            onClick={allocateHistoryCancelButtonOnClick}
            disabled={isLoading}
            color="error"
            size="small"
          >
            キャンセル
          </Button>
          <Button
            startIcon={<SaveAsRoundedIcon />}
            disabled={!currentHistoryVersion || isLoading}
            onClick={allocateHistoryButtonOnClick}
            size="small"
          >
            元に戻す
          </Button>
        </Stack>
      </Paper>
      <Divider />
    </Stack>
    <List
      disablePadding
      sx={{
        pt: 11
      }}
    >
      {
        allocateHistoriesForCompany.map((history) => (
          <ListItem
            key={[
              'allocateHistories',
              'ListItem',
              history.version
            ].join('-')}
            disablePadding
          >
            <ListItemButton
              onClick={() => allocateHistoryListItemButtonOnClick(history.version)}
            >
              <ListItemIcon
                sx={{
                  mr: 1
                }}
              >
                <Chip
                  color={currentHistoryVersion === history.version ? 'success' : 'secondary'}
                  label={datetimeDecorator.toHourMinuteSeconds(history.actAt)}
                />
              </ListItemIcon>
              <ListItemText
                primary={history.action}
                secondary={history.message}
              />
            </ListItemButton>
          </ListItem>
        ))
      }
    </List>
  </Stack>
  ));

export default AllocateHistoryPresenter;
