import { FC, memo, useContext } from 'react';
import { Polyline } from 'react-leaflet';
import LicenseContext from 'src/contexts/LicenseContext';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { PlanningsOperationDelivery } from 'src/models/PlanningsOperationGroup.model';
import { datetimeUtil } from 'src/utils/datetime.util';

type Props = {
  editPlaces: EditPlaceEntity[];
  delivery: PlanningsDeliveryEntity;
  selectedCycleIndexes: SelectedCycle;
  planningsOperationDelivery: PlanningsOperationDelivery;
}

const PlanningMapDeliveryPolylinesPresenter: FC<Props> = memo((
  {
    editPlaces,
    delivery,
    selectedCycleIndexes,
    planningsOperationDelivery,
  }
) => {
  const licenseContext = useContext(LicenseContext);
  if (!delivery || !planningsOperationDelivery) return null;

  const garagePosition: PositionEntity = [parseFloat(delivery.garageLatitude), parseFloat(delivery.garageLongitude)];

  const plotPositions: PositionEntity[] = [];
  const filledCycleIndexes = planningsOperationDelivery.cycles.filter((it) => !it.empty).map((it) => it.cycleIndex);

  planningsOperationDelivery.cycles.forEach((cycle) => {
    let operations = cycle.allOperations();
    if (operations.length === 0) { return; }

    if (editPlaces) {
      const editPlace = editPlaces.find((it) => it.deliveryId === delivery.id && it.cycleIndex === cycle.cycleIndex);
      if (editPlace) {
        operations = editPlace.places.flatMap((it) => it.allOperations());
      }
    }
    if (selectedCycleIndexes.cycleIndexes.includes(cycle.cycleIndex)) {
      if (cycle.cycleIndex === Math.min(...filledCycleIndexes)) {
        plotPositions.push(garagePosition);
      }
      operations.forEach((ops) => {
        plotPositions.push([parseFloat(ops.latitude), parseFloat(ops.longitude)]);
      });
      if (licenseContext.config?.use_trunk_transportation) {
        const sorted = operations.sort((a, b) => new Date(b.departureAt).getTime() - new Date(a.departureAt).getTime());
        const isLastDay = datetimeUtil.isSameDay(new Date(delivery.endAt), new Date(sorted[0].departureAt));
        if (isLastDay) {
          plotPositions.push(garagePosition);
        }
      } else if (cycle.cycleIndex === Math.max(...filledCycleIndexes)) {
        plotPositions.push(garagePosition);
      }
    }
  });

  return (
    <Polyline
      positions={plotPositions}
      pathOptions={{ color: delivery.driverColor }}
    />
  );
});

export default PlanningMapDeliveryPolylinesPresenter;
