import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { FC, memo, useEffect, useState, MouseEvent, useMemo, useCallback } from 'react';
import ReactGA from 'react-ga4';
import StyledMenu from 'src/components/StyledMenu';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { GarageEntity } from 'src/entities/Garage.entity';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PlanningsSortablePositionEntity } from 'src/entities/planningsSortablePosition.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PlanningsWorkingStatisticEntity } from 'src/entities/PlanningsWorkingStatistic.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { OperationsWithStatusModel } from 'src/models/OperationsWithStatus.model';
import { PlanningsOperationDelivery, PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import { PositionModel } from 'src/models/PositionModel';
import arrayUtil from 'src/utils/array.util';
import { datetimeUtil } from 'src/utils/datetime.util';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import { convertFromOperationsWithStatusModelToPlanningsSortablePositionEntities } from '../functions';

import PlanningsCyclesPresenter from './PlanningsCycles.presenter';
import PlanningsTransferDialogPresenter from './PlanningsTransferDialog.presenter';

type Props = {
  garage: GarageEntity;
  truck: PlanningsTruckEntity;
  driver: PlanningsDriverEntity;
  delivery: PlanningsDeliveryEntity;
  operationEntityWithStatsEntities: PlanningsOperationEntityWithStatsEntity[];
  selected: boolean;
  updateDisplayOrderId: (orderId: number) => void;
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  updateWorkingStatisticses: (entity: PlanningsWorkingStatisticEntity) => void;
  currentHistoryVersion: string | undefined;
  updateEditDriverId: (driverId: number) => void;
  pastOperationsData: undefined | PastOperationAndDriverIdEntity[];
  beforeOperationEntityWithStatsEntities: PlanningsOperationEntityWithStatsEntity[] | null;
  expandAll: boolean;
  planningsOperationDelivery: PlanningsOperationDelivery;
  beforePlanningsOperationDelivery: PlanningsOperationDelivery;
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  startOn: string;
  endOn: string;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  resetEditPlaces: () => void;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  addEmptyCycle: (deliveryId: number) => void;
  removeEmptyCycle: (deliveryId: number, cycleIndex: number) => void;
  addDeliveryElementRef: (deliveryId: number, element: HTMLElement) => void;
}

const PlanningsDeliveryPresenter: FC<Props> = memo((
  {
    garage,
    truck,
    driver,
    delivery,
    operationEntityWithStatsEntities,
    selected,
    updateDisplayOrderId,
    deliveryEntities,
    driverEntities,
    truckEntities,
    mutateDeleteOrdersOperations,
    openTransferDialog,
    isLoading,
    updateWorkingStatisticses,
    currentHistoryVersion,
    updateEditDriverId,
    pastOperationsData,
    beforeOperationEntityWithStatsEntities,
    expandAll,
    planningsOperationDelivery,
    beforePlanningsOperationDelivery,
    requestSingleAlgorithmPlanning,
    startOn,
    endOn,
    planningsOperationDeliveryByDeliveryIdEntity,
    selectedCycleIndexes,
    updateSelectedCycleIndexes,
    resetEditPlaces,
    updateEditPlaces,
    addEmptyCycle,
    removeEmptyCycle,
    addDeliveryElementRef,
  }
) => {
  const theme = useTheme();

  const [operationsWithStatusModel, setOperationsWithStatusModel] = useState<OperationsWithStatusModel | undefined>(undefined);
  const [beforeOperationsWithStatusModel, setBeforeOperationsWithStatusModel] = useState<OperationsWithStatusModel | undefined>(undefined);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedOperations, setSelectedOperations] = useState<PlanningsOperationEntityWithStatsEntity[]>([]);
  const [weightOver, setWeightOver] = useState<boolean>(false);
  const [volumeOver, setVolumeOver] = useState<boolean>(false);
  const [overtime, setOvertime] = useState<boolean>(false);
  const [outsideSpecifiedTime, setOutsideSpecifiedTime] = useState<boolean>(false);
  const [tooltipTitle, setTooltipTitle] = useState<string[]>([]);
  const [workingStartAt, setWorkingStartAt] = useState<Date | undefined>(undefined);
  const [workingEndAt, setWorkingEndAt] = useState<Date | undefined>(undefined);
  const [pastOperations, setPastOperations] = useState<PastOperationAndDriverIdEntity[]>([]);
  const [isFirstUnloadOperation, setIsFirstUnloadOperation] = useState<boolean>(false);
  const [addedOrderIds, setAddedOrderIds] = useState<number[]>([]);
  const [prevAddedOrderIds, setPrevAddedOrderIds] = useState<number[]>([]);
  const [checkedOperationAdded, setCheckedOperationAdded] = useState<boolean>(false);
  const [transferDialogIsOpen, setTransferDialogIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatusVo>('none');

  const addSelectedOperations = useCallback((operations: PlanningsOperationEntityWithStatsEntity[]) => {
    setSelectedOperations(
      [
        ...selectedOperations.filter((it) => !operations.map(({ id }) => id).includes(it.id)),
        ...operations
      ]
    );
  }, [selectedOperations]);

  const removeSelectedOperations = useCallback((operations: PlanningsOperationEntityWithStatsEntity[]) => {
    setSelectedOperations(
      [
        ...selectedOperations.filter((it) => !operations.map(({ id }) => id).includes(it.id)),
      ]
    );
  }, [selectedOperations]);

  const selectedOrderIds = arrayUtil.uniq<number>(selectedOperations.map((it) => it.orderId));

  const checkBoxOnClick = useCallback((event: React.MouseEvent) => {
    if (!delivery) return;

    event.stopPropagation();
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `ドライバーチェック ${selected ? 'OFF' : 'ON'}`, label: delivery.driverId });

    if (selected) {
      updateSelectedCycleIndexes(delivery.id, []);
    } else {
      updateSelectedCycleIndexes(delivery.id, planningsOperationDelivery.cycles.map((cycle) => cycle.cycleIndex));
    }
  }, [delivery, planningsOperationDelivery, selected, updateSelectedCycleIndexes]);

  const expandButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `ドライバー展開 ${isExpanded ? '閉じる' : '開く'}` });
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    setPrevAddedOrderIds(addedOrderIds);
  }, [addedOrderIds]);

  useEffect(() => {
    if (!pastOperationsData || !driver) return;

    setPastOperations(
      pastOperationsData.filter((it) => it.driverId === driver.id)
    );
  }, [driver, pastOperationsData]);

  useEffect(() => {
    if (!operationsWithStatusModel) {
      setWeightOver(false);
      return;
    }

    setWeightOver(
      truck.maximumLoadingCapacityWeightForCalculation < operationsWithStatusModel.maximumLoadingWeightG()
    );
  }, [operationsWithStatusModel, truck]);

  useEffect(() => {
    if (!operationsWithStatusModel) {
      setVolumeOver(false);
      return;
    }

    setVolumeOver(
      truck.loadingPlatformVolumeMm3 < operationsWithStatusModel.maximumLoadingVolumeMm3()
    );
  }, [operationsWithStatusModel, truck]);

  useEffect(() => {
    if (![workingStartAt, workingEndAt].every((maybe) => maybe)) {
      setOvertime(false);
      return;
    }

    if (!operationEntityWithStatsEntities.length) {
      setOvertime(false);
      return;
    }

    const startAt = new Date(delivery.startAt);
    const endAt = new Date(delivery.endAt);
    const workingSeconds = datetimeUtil.diffSeconds(workingStartAt, workingEndAt);
    if (delivery?.workingAvailableDurationSeconds && workingSeconds > delivery.workingAvailableDurationSeconds) {
      setOvertime(true);
      return;
    }

    setOvertime(
      (workingStartAt < startAt) || (endAt < workingEndAt)
    );
  }, [
    workingStartAt,
    workingEndAt,
    operationEntityWithStatsEntities,
    delivery
  ]);

  useEffect(() => {
    setTooltipTitle(
      [
        overtime ? '勤務時間が超過しています。' : '',
        weightOver ? '重量が超過しています。' : '',
        volumeOver ? '容量が超過しています。' : '',
        outsideSpecifiedTime ? '指定時間が超過しています。' : '',
      ].filter((maybe) => maybe)
    );
  }, [overtime, weightOver, volumeOver, outsideSpecifiedTime]);

  useEffect(() => {
    setSelectedOperations([]);
  }, [isLoading]);

  useEffect(() => {
    if (!(operationEntityWithStatsEntities || planningsOperationDelivery)) {
      setOperationsWithStatusModel(undefined);
      return;
    }

    setOperationsWithStatusModel(
      new OperationsWithStatusModel(operationEntityWithStatsEntities, planningsOperationDelivery)
    );
  }, [operationEntityWithStatsEntities, planningsOperationDelivery]);

  useEffect(() => {
    if (!(beforeOperationEntityWithStatsEntities || beforePlanningsOperationDelivery)) {
      setBeforeOperationsWithStatusModel(undefined);
      return;
    }

    setBeforeOperationsWithStatusModel(
      new OperationsWithStatusModel(beforeOperationEntityWithStatsEntities, beforePlanningsOperationDelivery)
    );
  }, [beforeOperationEntityWithStatsEntities, beforePlanningsOperationDelivery]);

  useEffect(() => {
    if (!delivery) return;
    if (!planningsOperationDelivery) return;

    if (delivery.workStartAt) {
      setWorkingStartAt(
        new Date(delivery.workStartAt)
      );
    }
    if (delivery.workEndAt) {
      setWorkingEndAt(
        new Date(delivery.workEndAt)
      );
    }
    if ([delivery.workStartAt, delivery.workEndAt, operationEntityWithStatsEntities.length].every((maybe) => maybe)) {
      const wMinutes = datetimeUtil.diffMinutes(new Date(delivery.workStartAt), new Date(delivery.workEndAt));
      const pMinutes = datetimeUtil.diffMinutes(new Date(delivery.startAt), new Date(delivery.endAt));

      updateWorkingStatisticses({
        deliveryId: delivery.id,
        operationCount: operationEntityWithStatsEntities.length,
        workMinutes: wMinutes,
        plannedMinutes: pMinutes,
      });
    } else {
      updateWorkingStatisticses({
        deliveryId: delivery.id,
        operationCount: 0,
        workMinutes: 0,
        plannedMinutes: 0,
      });
    }
  }, [delivery, planningsOperationDelivery, operationEntityWithStatsEntities, updateWorkingStatisticses]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const onClickMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const onClickClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const onClickEditDriver = useCallback((event: MouseEvent) => {
    if (!driver) return;
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'ドライバー編集' });

    event.stopPropagation();
    updateEditDriverId(driver.id);
    setAnchorEl(null);
  }, [driver, updateEditDriverId]);

  const driverMenu = useMemo(() => (
    <StyledMenu
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={onClickClose}
    >
      <MenuItem
        onClick={onClickEditDriver}
        disableRipple
      >
        ドライバー編集
      </MenuItem>
    </StyledMenu>
  ), [anchorEl, menuOpen, onClickEditDriver]);

  const positions: PlanningsSortablePositionEntity[] = useMemo(() => (
    operationsWithStatusModel
      ? convertFromOperationsWithStatusModelToPlanningsSortablePositionEntities(
        operationsWithStatusModel.groupByIdx(),
        delivery.id
      )
      : []
    ), [delivery.id, operationsWithStatusModel]);

  const beforePositions: PlanningsSortablePositionEntity[] = useMemo(() => (
    beforeOperationsWithStatusModel
      ? convertFromOperationsWithStatusModelToPlanningsSortablePositionEntities(
        beforeOperationsWithStatusModel.groupByIdx(),
        delivery.id
      )
      : []
    ), [delivery.id, beforeOperationsWithStatusModel]);

  useEffect(() => {
    const currentOrderIds = positions.flatMap((it) => it.operationsWithStats).map((it) => it.orderId);
    const beforeOrderIds = beforePositions.flatMap((it) => it.operationsWithStats).map((it) => it.orderId);

    if (beforeOperationEntityWithStatsEntities === null) {
      setAddedOrderIds([]);

      return;
    }

    const added = currentOrderIds.filter((it) => !beforeOrderIds.includes(it));

    setAddedOrderIds(added);
  }, [positions, beforePositions, beforeOperationEntityWithStatsEntities]);

  useEffect(() => {
    const isAddedOrderIdsIncludesPrev = addedOrderIds.every((it) => prevAddedOrderIds.includes(it));
    const isPrevAddedOrderIdsIncludesAdded = prevAddedOrderIds.every((it) => addedOrderIds.includes(it));

    if (isAddedOrderIdsIncludesPrev && isPrevAddedOrderIdsIncludesAdded) return;

    setCheckedOperationAdded(
      !!addedOrderIds.length
    );
  }, [addedOrderIds, prevAddedOrderIds]);

  useEffect(() => {
    if (!delivery) return;
    if (!operationsWithStatusModel) return;

    if (pastOperations.length === 0) {
      setIsFirstUnloadOperation(
        false
      );
      return;
    }

    const unloadedPositions = pastOperations.map((it) => [it.unloadingLatitude, it.unloadingLongitude].join('-'));
    const haveUnloadPositions = positions.filter((it) => it.operationsWithStats.some(({ action }) => action === '降'));
    const unloadPositions = haveUnloadPositions.map((it) => it.position).map((it) => it.join('-'));

    setIsFirstUnloadOperation(
      unloadPositions.some((it) => !unloadedPositions.includes(it))
    );
  }, [delivery, operationsWithStatusModel, pastOperations, positions]);

  useEffect(() => {
    setOutsideSpecifiedTime(false);
    positions.forEach((pos) => {
      pos.operationsWithStats.forEach((op) => {
        if (new PositionModel(op).isOutsideSpecifiedTime()) {
          setOutsideSpecifiedTime(true);
        }
      });
    });
  }, [positions]);

  const localSelectedCycleIndexes = useMemo(() => selectedCycleIndexes.find((it) => it.deliveryId === delivery.id)?.cycleIndexes || [], [delivery.id, selectedCycleIndexes]);

  useEffect(() => {
    if (!planningsOperationDelivery) return;

    if (localSelectedCycleIndexes.length === 0) {
      setSelectedStatus('none');
    } else if (localSelectedCycleIndexes.length === planningsOperationDelivery.cycles.length) {
      setSelectedStatus('every');
    } else {
      setSelectedStatus('some');
    }
  }, [localSelectedCycleIndexes.length, planningsOperationDelivery]);

  const updateLocalSelectedCycleIndexes = useCallback((idx: number) => {
    if (localSelectedCycleIndexes.includes(idx)) {
      updateSelectedCycleIndexes(delivery.id, localSelectedCycleIndexes.filter((it) => it !== idx));
      return;
    }
    updateSelectedCycleIndexes(delivery.id, [...localSelectedCycleIndexes, idx]);
  }, [delivery.id, localSelectedCycleIndexes, updateSelectedCycleIndexes]);

  const planningsCyclesPresenterMemo = useMemo(() => {
    if (!planningsOperationDelivery) return null;

    // 自分以外のデリバリーがあるかどうか
    const canTransfer = !!deliveryEntities.find((it) => it.id === delivery.id);
    return (
      <PlanningsCyclesPresenter
        deliveryId={delivery.id}
        driverId={delivery.driverId}
        garage={garage}
        truck={truck}
        planningsOperationDelivery={planningsOperationDelivery}
        pastOperations={pastOperations}
        requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
        selectedCycleIndexes={localSelectedCycleIndexes}
        updateSelectedCycleIndexes={updateLocalSelectedCycleIndexes}
        mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
        setTransferDialogIsOpen={setTransferDialogIsOpen}
        canTransfer={canTransfer}
        addSelectedOperations={addSelectedOperations}
        removeSelectedOperations={removeSelectedOperations}
        startOn={startOn}
        endOn={endOn}
        updateDisplayOrderId={updateDisplayOrderId}
        openTransferDialog={openTransferDialog}
        isLoading={isLoading}
        deliveryEntities={deliveryEntities}
        driverEntities={driverEntities}
        truckEntities={truckEntities}
        addEmptyCycle={addEmptyCycle}
        removeEmptyCycle={removeEmptyCycle}
        deliveryIsExpanded={isExpanded}
        resetEditPlaces={resetEditPlaces}
        updateEditPlaces={updateEditPlaces}
        planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
        addedOrderIds={addedOrderIds}
        parentCheckedOperationAdded={checkedOperationAdded}
      />
    );
  }, [planningsOperationDelivery, deliveryEntities, delivery.id, delivery.driverId, garage, truck, pastOperations, requestSingleAlgorithmPlanning, localSelectedCycleIndexes, updateLocalSelectedCycleIndexes, mutateDeleteOrdersOperations, addSelectedOperations, removeSelectedOperations, startOn, endOn, updateDisplayOrderId, openTransferDialog, isLoading, driverEntities, truckEntities, addEmptyCycle, removeEmptyCycle, isExpanded, resetEditPlaces, updateEditPlaces, planningsOperationDeliveryByDeliveryIdEntity, addedOrderIds, checkedOperationAdded]);

  const positionWarningMemo = useMemo(() => (
    <>
      {!!tooltipTitle.length && (
        <Tooltip title={tooltipTitle.join('')}>
          <WarningAmberRoundedIcon color="error" />
        </Tooltip>
      )}
      {
        isFirstUnloadOperation && (
          <Tooltip title="ドライバーが初めて訪問する納品先があります">
            <NotificationImportantIcon color="success" />
          </Tooltip>
        )
      }
    </>
  ), [tooltipTitle, isFirstUnloadOperation]);

  const titleLineMemo = useMemo(() => {
    const time = [
      datetimeDecorator.toDayAndHourMinutes(new Date(delivery.startAt)),
      datetimeDecorator.toDayAndHourMinutes(new Date(delivery.endAt)),
    ].join(' - ');
    const duration = delivery.workingAvailableDurationSeconds && delivery.workingAvailableDurationSeconds > 0 ? `, ${numberDecorator.convertToHoursAndMinutes(delivery.workingAvailableDurationSeconds / 60)}` : '';
    const timeText = `(${time}${duration})`;

    return (
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={0.5}
        sx={{
          '&:hover': {
            backgroundColor: 'grey.200',
          },
          '&:hover .hover-icons': {
            display: 'flex',
          },
          '&:hover .hover-icons-padding': {
            display: 'none',
          }
        }}
      >
        <Typography
          variant="h5"
          onClick={checkBoxOnClick}
          style={{
            cursor: 'pointer',
          }}
        >
          <span style={{ textDecoration: driver?.isActive ? '' : 'line-through' }}>
            {driver?.name}
            <span
              style={{
                paddingLeft: 5,
                fontSize: '95%',
                marginRight: '4px',
              }}
            >
              {timeText}
            </span>
          </span>
        </Typography>

        {operationsWithStatusModel?.orderCount() === 0 && (
          <Chip color="error" size="small" label="計画無" />
        )}
        <IconButton
          onClick={onClickMenu}
          sx={{
            padding: 0.5,
            margin: 0,
            display: 'none',
            position: 'absolute',
            right: 8,
            zIndex: 100,
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          disabled={!!currentHistoryVersion}
          className="hover-icons"
        >
          <MoreVertIcon />
        </IconButton>
      </Stack>
    );
  }, [checkBoxOnClick, delivery, driver, operationsWithStatusModel, onClickMenu, currentHistoryVersion]);

  useEffect(() => {
    setIsExpanded(expandAll);
  }, [expandAll]);

  const expandPositionsMemo = useMemo(() => (
    <IconButton
      onClick={expandButtonOnClick}
      size="small"
    >
      {isExpanded
        ? <KeyboardArrowUpRoundedIcon fontSize="small" />
        : <ExpandMoreRoundedIcon fontSize="small" />}
    </IconButton>
  ), [expandButtonOnClick, isExpanded]);

  const statisticsMemo = useMemo(() => {
    if (!planningsOperationDelivery || !truck || planningsOperationDelivery.empty) return null;

    const unloadCount = ['納品先', planningsOperationDelivery.unloadPlaceCount].join(' ');
    const itemCount = ['数量', numberDecorator.formatNumber(planningsOperationDelivery.itemCount)].join(' ');
    const weightRatio = ['重量', numberDecorator.toRoundedRate(planningsOperationDelivery.maxWeightG / truck.maximumLoadingCapacityWeightForCalculation, '')].join('');
    const volumeRatio = (planningsOperationDelivery.maxVolumeMm3 && truck.loadingPlatformVolumeMm3)
      ? ['体積', numberDecorator.toRoundedRate(planningsOperationDelivery.maxVolumeMm3 / truck.loadingPlatformVolumeMm3, '')].join('')
      : null;
    const ratio = ['積載:', weightRatio, volumeRatio].filter((it) => it).join(' ');

    let workRatio = '稼働率 0%';
    if ([delivery.workStartAt, delivery.workEndAt].every((it) => it)) {
      const wMinutes = datetimeUtil.diffMinutes(new Date(delivery.workStartAt), new Date(delivery.workEndAt));
      const pMinutes = datetimeUtil.diffMinutes(new Date(delivery.startAt), new Date(delivery.endAt));
      workRatio = ['稼働', numberDecorator.toRoundedRate(wMinutes / pMinutes, '')].join(' ');
    }
    const stats = [unloadCount, itemCount, ratio, workRatio].join('・');

    return (
      <Stack
        alignItems="center"
        direction="row"
        gap={0.5}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: '0.9em' }}
        >
          {stats}
        </Typography>
      </Stack>
    );
  }, [delivery, planningsOperationDelivery, truck]);

  const operationTimes = useMemo(() => {
    if (!planningsOperationDelivery || planningsOperationDelivery.empty) return '';

    let text = '配車前(まだ計画がありません)';
    if (!planningsOperationDelivery.empty && workingStartAt && workingEndAt) {
      const isSameDay = datetimeUtil.isSameDay(workingStartAt, workingEndAt);
      const times = isSameDay ? [
          datetimeDecorator.toHourMinutes(workingStartAt),
          datetimeDecorator.toHourMinutes(workingEndAt),
        ].join(' ~ ')
        : [
          `${datetimeDecorator.toMmDd(workingStartAt)} ${datetimeDecorator.toHourMinutes(workingStartAt)}`,
          `${datetimeDecorator.toMmDd(workingEndAt)} ${datetimeDecorator.toHourMinutes(workingEndAt)}`
        ].join(' ~ ');
      const duration = [
          '(',
          numberDecorator.convertToHoursAndMinutes(
            datetimeUtil.diffMinutes(
              workingStartAt,
              workingEndAt
            )
          ),
          ')',
        ].join('');
      text = ['計画', times, duration].join(' ');
    }

    return (
      <Stack direction="column">
        <Divider sx={{ my: 0.5 }} />
        <Box
          sx={{
            backgroundColor: 'grey.300',
            padding: '4px 8px',
            borderRadius: '16px',
            width: 'fit-content',
          }}
        >
          <Typography variant="body2" color="text.primary">
            {text}
          </Typography>
        </Box>
      </Stack>
    );
  }, [workingEndAt, workingStartAt, planningsOperationDelivery]);

  const deliveryBgColor = () => {
    if (checkedOperationAdded) return '#EAEFF6';

    return '';
  };

  if (!driver) return null;

  return (
    <Stack
      borderLeft={8}
      borderColor={driver.color || theme.colors.success.light}
      spacing={0.5}
      ref={(el) => { addDeliveryElementRef(delivery.id, el); }}
    >
      {driverMenu}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ backgroundColor: deliveryBgColor() }}
      >
        {expandPositionsMemo}
        <Checkbox
          size="small"
          sx={{
            m: 0,
            p: 0.3,
          }}
          onClick={checkBoxOnClick}
          checked={selectedStatus === 'every'}
          indeterminate={selectedStatus === 'some'}
          disabled={isLoading}
        />
        <Stack
          flexGrow={1}
        >
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            gap={0.5}
            marginRight={1}
          >
            {positionWarningMemo}
            {titleLineMemo}
          </Stack>
          {statisticsMemo}
          {isExpanded && operationTimes}
          {isExpanded && planningsCyclesPresenterMemo}
        </Stack>
        {transferDialogIsOpen && (
          <PlanningsTransferDialogPresenter
            transferDialogIsOpen={transferDialogIsOpen}
            setTransferDialogIsOpen={setTransferDialogIsOpen}
            fromDeliveryId={delivery.id}
            deliveryEntities={deliveryEntities}
            driverEntities={driverEntities}
            truckEntities={truckEntities}
            openTransferDialog={openTransferDialog}
            selectedOrderIds={selectedOrderIds}
            planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
          />
        )}
      </Stack>
    </Stack>
  );
});

export default PlanningsDeliveryPresenter;
