import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Box, Button, Dialog, DialogActions, Stack, Typography } from '@mui/material';
import { format } from 'date-fns-tz';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { Operations } from 'src/models/Operations';
import { datetimeUtil } from 'src/utils/datetime.util';

import PlanningMapDriversPresenter from '../../V2Plans/presenters/PlanningMapDrivers.presenter';
import PlanningOrderPresenter from '../../V2Plans/presenters/PlanningOrder.presenter';

type Props = {
  date: Date;
  startDate: Date;
  endDate: Date;
  operations: PlanningsOperationEntity[]
  sameOrderOperations: PlanningsOperationEntity[]
  isLoading: boolean;
  truckData: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  deliveryData: PlanningsDeliveryEntity[];
  prevDeliveryData: PlanningsDeliveryEntity[];
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => Promise<void>;
}

const OperationCarousel: FC<Props> = memo(({
  date,
  startDate,
  endDate,
  operations,
  sameOrderOperations,
  isLoading,
  truckData,
  driverEntities,
  deliveryData,
  prevDeliveryData,
  mutateDeleteOrdersOperations,
}) => {
  const licenseContext = useContext(LicenseContext);

  // const [currentIndex, setCurrentIndex] = useState(0);
  const [deliveryEntitiesOnPosition, setDeliveryEntitiesOnPosition] = useState<PlanningsDeliveryEntity[]>();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [isAllFixed, setIsAllFixed] = useState<boolean>(false);
  const [position, setPosition] = useState<PositionEntity>();
  const [eventDialogSelectedOrderIds, setEventDialogSelectedOrderIds] = useState<number[]>([]);
  const [uniqDriverEntitiesOnPosition, setUniqDriverEntitiesOnPosition] = useState<PlanningsDriverEntity[]>();
  const [planningsOperationDeliveryByDeliveryIdEntity, setPlanningsOperationDeliveryByDeliveryIdEntity] = useState<PlanningsOperationDeliveryByDeliveryIdEntity>();
  const [displayDetailOrderId, setDetailDisplayOrderId] = useState<number | undefined>(undefined);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>('');
  const [displayOrderDialogIsOpen, setDisplayOrderDialogIsOpen] = useState(false);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setPosition(null);
    setIsAllFixed(operations.every((it) => it.fixed));

    const deliveries = deliveryData.filter((it) => it.id === operations[0].shiftTruckId);
    setDeliveryEntitiesOnPosition(deliveryData);

    const driverIds = deliveries.map((it) => it.driverId);
    setUniqDriverEntitiesOnPosition(driverEntities.filter((it) => driverIds.includes(it.id)));

    if (!planningsOperationDeliveryByDeliveryIdEntity) {
      const model = new Operations(licenseContext.config, operations);
      setPlanningsOperationDeliveryByDeliveryIdEntity(model.createPlanningsOperationDeliveryByDeliveryIdEntity());
    }
  }, [deliveryData, driverEntities, licenseContext.config, operations, planningsOperationDeliveryByDeliveryIdEntity]);

  const displayTime = useMemo(() => {
    const orderIds = operations.map((it) => it.orderId);
    const operationsByOrder = sameOrderOperations.filter((ops) => orderIds.includes(ops.orderId));
    const loadOperation = operationsByOrder.find((it) => it.action === '積');
    const unloadOperation = operationsByOrder.find((it) => it.action === '降');

    const loadArrivalAt = new Date(loadOperation.arrivalAt);
    const unloadArrivalAt = new Date(unloadOperation.arrivalAt);

    const arrival = datetimeUtil.isSameDay(date, loadArrivalAt) ? format(loadArrivalAt, 'HH:mm') : format(loadArrivalAt, 'HH:mm (MM/dd)');
    const departure = datetimeUtil.isSameDay(date, unloadArrivalAt) ? format(unloadArrivalAt, 'HH:mm') : format(unloadArrivalAt, 'HH:mm (MM/dd)');
    return [arrival, departure].join(' - ');
  }, [date, operations, sameOrderOperations]);

  const displayFromTo = useMemo(() => {
    const from = operations[0].orderNormalizedAddress?.loadingAdministrativeAreaLevel1 || operations[0].loadingName;
    const to = operations[0].orderNormalizedAddress?.unloadingAdministrativeAreaLevel1 || operations[0].unloadingName;
    return `${from} → ${to}`;
  }, [operations]);

  const displayShipperName = useMemo(() => operations[0].shipperName, [operations]);

  const displayOtherCount = useMemo(() => {
    if (operations.length === 1) return '';

    return `他 ${operations.length - 1}件`;
  }, [operations]);

  const buttonWidth = useMemo(() => ({ minWidth: '14px', }), []);
  const buttonStyle = useMemo(() => (
    { ...buttonWidth, ...{ p: 0, m: 0, height: '60px', border: '1px solid #ccc', } }
  ), [buttonWidth]);

  const handleBoxClick = () => {
    const orderIds = operations.map((it) => it.orderId);
    setEventDialogSelectedOrderIds(orderIds);
    setDialogIsOpen(true);
  };

  const deleteButtonOnClick = useCallback(() => {
    if (isLoading) return;
    if (!eventDialogSelectedOrderIds.length) return;

    setConfirmDialogMessage('割当を解除します。よろしいですか？');
    setConfirmDialogIsOpen(true);
  }, [isLoading, eventDialogSelectedOrderIds]);

  const addEventDialogSelectedOrderIds = useCallback((eventDialogOrderIds: number[]) => {
    setEventDialogSelectedOrderIds((prev) => {
      const newIds = eventDialogOrderIds.filter((it) => !prev.includes(it));
      if (newIds.length === 0) { return prev; }
      return [...prev, ...newIds];
    });
  }, []);

  const dialogOnClose = useCallback(() => {
    if (isLoading) return;

    setDialogIsOpen(false);
  }, [isLoading]);

  const removeEventDialogSelectedOrderIds = useCallback((eventDialogOrderIds: number[]) => {
    setEventDialogSelectedOrderIds((prev) => prev.filter((it) => !eventDialogOrderIds.includes(it)));
  }, []);

  const updateDisplayDetailOrderId = useCallback((orderId: number) => {
    setDetailDisplayOrderId(orderId);
  }, []);

  useEffect(() => {
    setDisplayOrderDialogIsOpen(!!displayDetailOrderId);
  }, [displayDetailOrderId]);

  const resetDisplayDetailOrderId = useCallback(() => {
    setDetailDisplayOrderId(undefined);
  }, []);

  const planningOrderPresenterMemo = useMemo(() => (
    <Dialog
      fullWidth
      maxWidth="md"
      open={displayOrderDialogIsOpen}
      onClose={resetDisplayDetailOrderId}
    >
      <PlanningOrderPresenter
        orderId={displayDetailOrderId}
        customInputFields={[]}
      />
    </Dialog>
  ), [displayOrderDialogIsOpen, displayDetailOrderId, resetDisplayDetailOrderId]);

  const detailDialogMemo = useMemo(() => (
    <Dialog
      open={dialogIsOpen}
      onClose={dialogOnClose}
      fullWidth
      maxWidth="md"
    >
      <PlanningMapDriversPresenter
        position={position}
        driverEntitiesOnPosition={uniqDriverEntitiesOnPosition}
        deliveryEntitiesOnPosition={deliveryEntitiesOnPosition}
        startOn={datetimeDecorator.toYyyyMmDd(startDate)}
        endOn={datetimeDecorator.toYyyyMmDd(endDate)}
        selectedOrderIds={eventDialogSelectedOrderIds}
        addSelectedOrderIds={addEventDialogSelectedOrderIds}
        removeSelectedOrderIds={removeEventDialogSelectedOrderIds}
        updateDisplayOrderId={updateDisplayDetailOrderId}
        isLoading={isLoading}
        truckEntities={truckData}
        editPlaces={undefined}
        planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
      />
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            disabled={!eventDialogSelectedOrderIds.length || isLoading || isAllFixed}
            onClick={deleteButtonOnClick}
            startIcon={<DeleteForeverRoundedIcon />}
          >
            割当解除
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  ), [addEventDialogSelectedOrderIds, deleteButtonOnClick, deliveryEntitiesOnPosition, dialogIsOpen, dialogOnClose, endDate, isAllFixed, isLoading, position, removeEventDialogSelectedOrderIds, eventDialogSelectedOrderIds, startDate, truckData, uniqDriverEntitiesOnPosition, updateDisplayDetailOrderId, planningsOperationDeliveryByDeliveryIdEntity]);

   const confirmDialogHandleOk = useCallback(() => {
     setConfirmDialogIsOpen(false);
     setDialogIsOpen(false);
     // eslint-disable-next-line no-void
     void mutateDeleteOrdersOperations(eventDialogSelectedOrderIds);
   }, [mutateDeleteOrdersOperations, eventDialogSelectedOrderIds]);

   const confirmDialogHandleCancel = useCallback(() => {
     setConfirmDialogIsOpen(false);
   }, []);

  const confirmDialogMemo = useMemo(() => (
    <ConfirmDialog
      open={confirmDialogIsOpen}
      message={confirmDialogMessage}
      handleOk={confirmDialogHandleOk}
      handleCancel={confirmDialogHandleCancel}
    />
  ), [confirmDialogHandleCancel, confirmDialogHandleOk, confirmDialogIsOpen, confirmDialogMessage]);

  useEffect(() => {
    if (operations.length === 0 || prevDeliveryData.length === 0) return;
    const prevOrderIds = prevDeliveryData.flatMap((it) => it.operations).map((it) => it.orderId);
    const currentOrderIds = operations.map((it) => it.orderId);
    const isChanged = !currentOrderIds.every((it) => prevOrderIds.includes(it));
    setChanged(isChanged);
  }, [operations, prevDeliveryData]);

  const innerBgColor = () => {
    if (changed) {
      return '#D6E3F6';
    }
    return '';
  };

  if (operations.length === 0) return null;

  return (
    <>
      {confirmDialogMemo}
      {detailDialogMemo}
      {planningOrderPresenterMemo}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={0.5}
        width="100%"
        sx={{
          p: 0,
          m: 0,
          maxHeight: '60px',
        }}
      >
        <Box
          width="100%"
          sx={{
            cursor: 'pointer',
            p: 0.2,
            backgroundColor: innerBgColor(),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          onClick={handleBoxClick}
        >
          <Typography fontSize="12px">
            {displayFromTo}
          </Typography>
          <Typography fontSize="12px" sx={{ textOverflow: 'clip', whiteSpace: 'nowrap', }}>
            {displayShipperName}
          </Typography>
          <Typography fontSize="12px">
            {displayOtherCount}
          </Typography>
        </Box>
      </Box>
    </>
  );
});

export default OperationCarousel;
