import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { GridColumnResizeParams } from '@mui/x-data-grid-premium';

const setSavedColumnWidth = (prefix: string, params: GridColumnResizeParams) => {
  const storageKey = `${prefix}-width-${params.colDef.field}`;
  localStorage.setItem(storageKey, JSON.stringify(params.colDef.width));
};

const getSavedColumnWidth = (prefix: string, field: string) => {
  const storageKey = `${prefix}-width-${field}`;
  const width = localStorage.getItem(storageKey);

  if (width) {
    return parseInt(width, 10);
  }

  return 0;
};

const setSavedColumnVisibilities = (prefix: string, newModel: GridColumnVisibilityModel) => {
  const storageKey = `${prefix}-visibilities`;

  localStorage.setItem(storageKey, JSON.stringify(newModel));
};

const getSavedColumnVisibilities: (prefix: string) => GridColumnVisibilityModel = (prefix) => {
  const storageKey = `${prefix}-visibilities`;

  const columnVisibility = localStorage.getItem(storageKey);

  if (columnVisibility) {
    const columns = JSON.parse(columnVisibility) as GridColumnVisibilityModel;
    return columns;
  }

  return {};
};

const setColumnOrders = (prefix: string, orders: string[]) => {
  const storageKey = `${prefix}-column-orders`;
  localStorage.setItem(storageKey, JSON.stringify(orders));
};

const getColumnOrders = (prefix: string) => {
  const storageKey = `${prefix}-column-orders`;
  const orders = localStorage.getItem(storageKey);

  if (orders) {
    return JSON.parse(orders) as string[];
  }
  return [];
};

export const dataGridUtil = {
  getSavedColumnWidth,
  setSavedColumnWidth,
  getSavedColumnVisibilities,
  setSavedColumnVisibilities,
  getColumnOrders,
  setColumnOrders,
};
