import { Reducer } from 'react';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningMapUnallocatedOrderPositionEntity } from 'src/entities/import/PlanningMapUnallocatedOrderPosition.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { PlanningEarningEntity } from 'src/entities/PlanningEarning.entity';
import { PlanningEntity, RespectType, WeekDayEn } from 'src/entities/planningEntity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsMapGaragePositionEntity } from 'src/entities/PlanningsMapGaragePosition.entity';
import { PlanningsMapOperationPositionEntity } from 'src/entities/PlanningsMapOperationPosition.entity';
import { NotAllocReasonState, PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity, PlanningsOperationEntitiesWithStatsByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import arrayUtil from 'src/utils/array.util';
import { RelaxedRuleVo } from 'src/vo/RelaxedRule.vo';

type ResetAction = { type: 'reset' }

type DisplayOrderIdActions = {
  type: 'update';
  payload: number
} | ResetAction

export const displayOrderIdReducer: Reducer<number | undefined, DisplayOrderIdActions> = (state, action) => {
  switch (action.type) {
    case 'update': {
      return action.payload;
    }
    case 'reset': {
      return undefined;
    }
    default: {
      return state;
    }
  }
};

type SelectedCycleIndexesActions = {
  type: 'update';
  payload: { deliveryId: number, cycleIndexes: number[] };
} | {
  type: 'remove';
  payload: number;
} | ResetAction;

export const selectedCycleIndexesReducer : Reducer<{ deliveryId: number, cycleIndexes: number[] }[], SelectedCycleIndexesActions> = (state, action) => {
  switch (action.type) {
    case 'update': {
      const filtered = state.filter((it) => it.deliveryId !== action.payload.deliveryId);
      return [...filtered, action.payload];
    }
    case 'remove': {
      return state.filter((it) => it.deliveryId !== action.payload);
    }
    case 'reset': {
      return [];
    }
    default: {
      return state;
    }
  }
};

type ImportFileActions = {
  type: 'set';
  payload: File;
} | ResetAction
export const importFileReducer: Reducer<File | undefined, ImportFileActions> = (state, action) => {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'reset': {
      return undefined;
    }
    default: {
      return state;
    }
  }
};

type ImportForUpdateActions = {
  type: 'set';
  payload: File;
} | ResetAction
export const importForUpdateFileReducer: Reducer<File | undefined, ImportForUpdateActions> = (state, action) => {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'reset': {
      return undefined;
    }
    default: {
      return state;
    }
  }
};

type TransferRequestActions = {
  type: 'set',
  payload: TransferRequestEntity
} | ResetAction
export const transferRequestReducer: Reducer<TransferRequestEntity | undefined, TransferRequestActions> = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return undefined;
    default:
      return state;
  }
};

type PlanningAction = {
  type: 'updateStartOn';
  payload: string;
} | {
  type: 'updateEndOn';
  payload: string;
} | {
  type: 'setOrderIds';
  payload: number[];
} | {
  type: 'setShiftIds';
  payload: number[];
} | {
  type: 'updateIsRespectRecent';
  payload: boolean;
} | {
  type: 'updateExcludeToll';
  payload: boolean;
} | {
  type: 'updateRespectType';
  payload: RespectType;
} | {
  type: 'updateRespectDaysOfWeek';
  payload: WeekDayEn[];
} | {
  type: 'updateRespectOn';
  payload: string;
} | {
  type: 'updateIsTimeTableBackward';
  payload: boolean;
} | {
  type: 'updateIncludeFutureDeliveries';
  payload: boolean;
} | {
  type: 'updateActivateConstraintLoadAfterNotCleared';
  payload: boolean;
} | {
  type: 'updateLoadAfterInitialStock';
  payload: boolean;
} | {
  type: 'updateSelectedRelaxedRules';
  payload: RelaxedRuleVo[];
} | {
  type: 'updatePriorityLargeTrucks';
  payload: number;
} | {
  type: 'updateExpandWorkingStartTime';
  payload: number;
} | {
  type: 'updateExpandWorkingEndTime';
  payload: number;
} | {
  type: 'updateExpandLoadStartTime';
  payload: number;
} | {
  type: 'updateExpandLoadEndTime';
  payload: number;
} | {
  type: 'updateExpandUnloadStartTime';
  payload: number;
} | {
  type: 'updateExpandUnloadEndTime';
  payload: number;
} | {
  type: 'updateExpandMaxVolumeRate';
  payload: number;
} | {
  type: 'updateExpandMaxLoadCapacityRate';
  payload: number;
} | {
  type: 'updateMLSourceType';
  payload: number;
} | {
  type: 'updateBalancedLoading'
  payload: boolean;
} | {
  type: 'updateAutoSplitOrders'
  payload: boolean;
} | {
  type: 'updateDontExchangePreroute'
  payload: boolean;
} | {
  type: 'updateConcurrentAllOrNothing'
  payload: boolean;
} | {
  type: 'updateMLPlanning',
  payload: boolean;
} | {
  type: 'updateOptimizeForLoad',
  payload: boolean;
} | {
  type: 'updateForceNullBase',
  payload: boolean;
} | {
  type: 'updateTrunkTransportation',
  payload: boolean;
} | {
  type: 'resetRestrictedInsertPosition';
} | ResetAction;
export const planningInitialState: PlanningEntity = {
  startOn: '',
  endOn: '',
  lockType: 'free',
  isRespectRecent: false,
  excludeToll: false,
  respectType: 'day_of_week',
  respectDaysOfWeek: [],
  respectOn: '',
  isTimeTableBackward: true,
  includeFutureDeliveries: false,
  activateConstraintLoadAfterNotCleared: false,
  orderOperationIdsForSort: [],
  deleteOrderIdsFromOperations: [],
  selectedRelaxedRules: [],
  priorityLargeTrucks: 0.5,
  expandWorkingStartTime: 0,
  expandWorkingEndTime: 0,
  expandLoadStartTime: 0,
  expandLoadEndTime: 0,
  expandUnloadStartTime: 0,
  expandUnloadEndTime: 0,
  expandMaxVolumeRate: 0.0,
  expandMaxLoadCapacityRate: 0.0,
  requestAsync: false,
  mlSourceType: -1,
  balancedLoading: false,
  autoSplitOrders: false,
  dontExchangePreroute: true,
  concurrentAllOrNothing: false,
  loadAfterInitialStock: true,
  mlPlanning: true,
  optimizeForLoad: false,
  forceNullBase: false,
  setCurrentTimeToAlgorithm: false,
  trunkTransportation: false,
};
export const planningReducer: Reducer<PlanningEntity, PlanningAction> = (state, action) => {
  switch (action.type) {
    case 'updateStartOn':
      return {
        ...state,
        startOn: action.payload,
      };
    case 'updateEndOn':
      return {
        ...state,
        endOn: action.payload,
      };
    case 'updateIsRespectRecent':
      return {
        ...state,
        isRespectRecent: action.payload
      };
    case 'updateExcludeToll':
      return {
        ...state,
        excludeToll: action.payload
      };
    case 'updateRespectType':
      return {
        ...state,
        respectType: action.payload
      };
    case 'updateRespectDaysOfWeek':
      return {
        ...state,
        respectDaysOfWeek: action.payload
      };
    case 'updateRespectOn':
      return {
        ...state,
        respectOn: action.payload
      };
    case 'updateIsTimeTableBackward':
      return {
        ...state,
        isTimeTableBackward: action.payload
      };
    case 'updateIncludeFutureDeliveries':
      return {
        ...state,
        includeFutureDeliveries: action.payload
      };
    case 'updateActivateConstraintLoadAfterNotCleared':
      return {
        ...state,
        activateConstraintLoadAfterNotCleared: action.payload
      };
      case 'updateLoadAfterInitialStock':
        return {
          ...state,
          loadAfterInitialStock: action.payload
        };
    case 'updateSelectedRelaxedRules':
      return {
        ...state,
        selectedRelaxedRules: action.payload
      };
    case 'updatePriorityLargeTrucks':
      return {
        ...state,
        priorityLargeTrucks: action.payload
      };
    case 'updateExpandWorkingStartTime':
      return {
        ...state,
        expandWorkingStartTime: action.payload
      };
    case 'updateExpandWorkingEndTime':
      return {
        ...state,
        expandWorkingEndTime: action.payload
      };
    case 'updateExpandLoadStartTime':
      return {
        ...state,
        expandLoadStartTime: action.payload
      };
    case 'updateExpandLoadEndTime':
      return {
        ...state,
        expandLoadEndTime: action.payload
      };
    case 'updateExpandUnloadStartTime':
      return {
        ...state,
        expandUnloadStartTime: action.payload
      };
    case 'updateExpandUnloadEndTime':
      return {
        ...state,
        expandUnloadEndTime: action.payload
      };
    case 'updateExpandMaxVolumeRate':
      return {
        ...state,
        expandMaxVolumeRate: action.payload
      };
    case 'updateExpandMaxLoadCapacityRate':
      return {
        ...state,
        expandMaxLoadCapacityRate: action.payload
      };
    case 'updateMLSourceType':
      return {
        ...state,
        mlSourceType: action.payload
      };
    case 'updateBalancedLoading':
      return {
        ...state,
        balancedLoading: action.payload,
      };
    case 'updateAutoSplitOrders':
      return {
        ...state,
        autoSplitOrders: action.payload,
      };
    case 'updateDontExchangePreroute':
      return {
        ...state,
        dontExchangePreroute: action.payload,
      };
    case 'updateConcurrentAllOrNothing':
      return {
        ...state,
        concurrentAllOrNothing: action.payload,
      };
    case 'updateMLPlanning':
      return {
        ...state,
        mlPlanning: action.payload,
      };
    case 'updateOptimizeForLoad':
      return {
        ...state,
        optimizeForLoad: action.payload,
      };
    case 'updateForceNullBase':
      return {
        ...state,
        forceNullBase: action.payload,
      };
    case 'updateTrunkTransportation':
      return {
        ...state,
        trunkTransportation: action.payload,
      };
    case 'resetRestrictedInsertPosition':
      return {
        ...state,
        allocationRules: undefined,
      };
    case 'reset':
      return planningInitialState;
    default:
      return state;
  }
};

export type SelectedOrderIdsActions = {
  type: 'set';
  payload: number[];
} | {
  type: 'add';
  payload: number;
} | {
  type: 'remove';
  payload: number;
} | {
  type: 'bulkRemove';
  payload: number[];
} | ResetAction

export const selectedOrderIdsReducer: Reducer<number[], SelectedOrderIdsActions> = (state, action) => {
  switch (action.type) {
    case 'set':
      return arrayUtil.uniq<number>(action.payload.sort());
    case 'add':
      return arrayUtil.uniq<number>([...state, action.payload].sort());
    case 'remove':
      return arrayUtil.uniq<number>(state.filter((id) => id !== action.payload));
    case 'bulkRemove':
      return arrayUtil.uniq<number>(state.filter((id) => !action.payload.includes(id)));
    case 'reset':
      return [];
    default:
      return state;
  }
};

export type IsLoadingActions = {
  type: 'loading'
} | {
  type: 'notLoading'
}
export const isLoadingReducer: Reducer<boolean, IsLoadingActions> = (state, action) => {
  switch (action.type) {
    case 'loading':
      return true;
    case 'notLoading':
      return false;
    default:
      return state;
  }
};

export type OrderIdsActions = {
  type: 'set';
  payload: number[];
} | {
  type: 'add';
  payload: number[];
} | {
  type: 'remove';
  payload: number[];
} | ResetAction
export const orderIdsReducer: Reducer<number[], OrderIdsActions> = (state, action) => {
  switch (action.type) {
    case 'set':
      return [...action.payload];
    case 'add':
      return arrayUtil.uniq<number>([
        ...action.payload,
        ...state
      ]);
    case 'remove':
      return state.filter((s) => !action.payload.includes(s));
    case 'reset':
      return [];
    default:
      return state;
  }
};

export type DisplayOrderIdsActions = {
  type: 'set';
  payload: number[];
} | {
  type: 'add';
  payload: number;
} | {
  type: 'remove';
  payload: number;
} | ResetAction

export const displayOrderIdsReducer: Reducer<number[], DisplayOrderIdsActions> = (state, action) => {
  switch (action.type) {
    case 'set':
      return arrayUtil.uniq<number>(action.payload);
    case 'add':
      return arrayUtil.uniq<number>([...state, action.payload]);
    case 'remove':
      return arrayUtil.uniq<number>(state.filter((id) => id !== action.payload));
    case 'reset':
      return [];
    default:
      return state;
  }
};

type fitBoundsPositionsAction = {
  type: 'set';
  payload: PositionEntity[];
} | ResetAction;

export const fitBoundsPositionsReducer: Reducer<PositionEntity[],
  fitBoundsPositionsAction> = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return [];
    default:
      return state;
  }
};

type AllocateHistoriesAction = {
  type: 'add';
  payload: AllocateHistoryEntity;
} | ResetAction;

export const allocateHistoriesReducer: Reducer<AllocateHistoryEntity[], AllocateHistoriesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'add':
      return [
        action.payload,
        ...state,
      ];
    case 'reset': {
      return [];
    }
    default:
      return state;
  }
};

type CurrentHistoryVersionAction = {
  type: 'set';
  payload: string;
} | ResetAction;

export const currentHistoryVersionReducer: Reducer<string | undefined, CurrentHistoryVersionAction> = (
  state,
    action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return undefined;
    default:
      return state;
  }
};

type SelectedRelaxedRulesAction = {
  type: 'set';
  payload: RelaxedRuleVo[];
} | ResetAction;

export const selectedRelaxedRulesReducer: Reducer<
  RelaxedRuleVo[],
  SelectedRelaxedRulesAction
  > = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return [];
    default:
      return state;
  }
};

type NotAllocReasonStateAction = {
  type: 'set';
  payload: NotAllocReasonState;
};

export const notAllocReasonStateReducer: Reducer<NotAllocReasonState, NotAllocReasonStateAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type NotAlocReasonsAction = {
  type: 'set',
  payload: PlanningsNotAllocReason[];
} | ResetAction;

export const notAllocReasonsReducer: Reducer<PlanningsNotAllocReason[], NotAlocReasonsAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return [];
    default:
      return state;
  }
};

type LatestAlgorithmRequestVersionAcction = {
  type: 'set',
  payload: number;
};

export const latestAlgorithmRequestVersionReducer: Reducer<number | undefined, LatestAlgorithmRequestVersionAcction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type LastOperationStatusUpdatedAtAction = {
  type: 'set',
  payload: string;
};

export const lastOperationStatusUpdatedAtReducer: Reducer<string | undefined, LastOperationStatusUpdatedAtAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type TruckEntitiesAction = {
  type: 'set',
  payload: PlanningsTruckEntity[];
};

export const truckEntitiesReducer: Reducer<PlanningsTruckEntity[], TruckEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type DriverEntitiesAction = {
  type: 'set',
  payload: PlanningsDriverEntity[];
};

export const driverEntitiesReducer: Reducer<PlanningsDriverEntity[], DriverEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type DeliveryEntitiesAction = {
  type: 'set',
  payload: PlanningsDeliveryEntity[];
};

export const deliveryEntitiesReducer: Reducer<PlanningsDeliveryEntity[], DeliveryEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type OperationEntitiesAction = {
  type: 'set',
  payload: PlanningsOperationEntity[];
} | ResetAction;

export const operationEntitiesReducer: Reducer<PlanningsOperationEntity[], OperationEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return [];
    default:
      return state;
  }
};

type GroupEntitiesAction = {
  type: 'set',
  payload: PlanningsGroupEntity[];
};

export const groupEntitiesReducer: Reducer<PlanningsGroupEntity[], GroupEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type PlanningsMapGaragePositionEntitiesActcion = {
  type: 'set',
  payload: PlanningsMapGaragePositionEntity[];
};

export const planningsMapGaragePositionEntitiesReducer: Reducer<PlanningsMapGaragePositionEntity[], PlanningsMapGaragePositionEntitiesActcion> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type PlanningsMapOperationPositionEntities = {
  type: 'set',
  payload: PlanningsMapOperationPositionEntity[];
};

export const planningsMapOperationPositionEntitiesReducer: Reducer<PlanningsMapOperationPositionEntity[], PlanningsMapOperationPositionEntities> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type PlanningsOperationEntitiesWithStatsByDeliveryIdEntityAction = {
  type: 'set',
  payload: PlanningsOperationEntitiesWithStatsByDeliveryIdEntity;
};

export const planningsOperationEntitiesWithStatsByDeliveryIdEntityReducer: Reducer<PlanningsOperationEntitiesWithStatsByDeliveryIdEntity, PlanningsOperationEntitiesWithStatsByDeliveryIdEntityAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type FlyToPositionAction = {
  type: 'set',
  payload: PositionEntity;
} | ResetAction;

export const flyToPositionReducer: Reducer<PositionEntity | undefined, FlyToPositionAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return undefined;
    default:
      return state;
  }
};

type SelectedGroupEntityAction = {
  type: 'set',
  payload: PlanningsGroupEntity[];
} | ResetAction;

export const selectedGroupEntitiesReducer: Reducer<PlanningsGroupEntity[] | undefined, SelectedGroupEntityAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return undefined;
    default:
      return state;
  }
};

type PlanningMapUnallocatedOrderPositionEntitiesAction = {
  type: 'set',
  payload: PlanningMapUnallocatedOrderPositionEntity[];
};

export const planningMapUnallocatedOrderPositionEntitiesReducer: Reducer<PlanningMapUnallocatedOrderPositionEntity[], PlanningMapUnallocatedOrderPositionEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type PlanningEarningEntityAction = {
  type: 'set',
  payload: PlanningEarningEntity;
};

export const planningEarningEntityReducer: Reducer<PlanningEarningEntity | undefined, PlanningEarningEntityAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type CalculatingAction = {
  type: 'set',
  payload: boolean;
};

export const calculatingReducer: Reducer<boolean, CalculatingAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type OrderEntityMapAction = {
  type: 'set',
  payload: Map<number, OrderEntity>;
};

export const orderEntityMapReducer: Reducer<Map<number, OrderEntity>, OrderEntityMapAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type PlanningsOperationDeliveryByDeliveryIdEntityAction = {
  type: 'set',
  payload: PlanningsOperationDeliveryByDeliveryIdEntity;
} | {
  type: 'addEmptyCycle',
  payload: number; // deliveryId
} | {
  type: 'removeEmptyCycle',
  payload: { deliveryId: number, cycleIndex: number },
};

export const planningsOperationDeliveryByDeliveryIdEntityReducer: Reducer<PlanningsOperationDeliveryByDeliveryIdEntity, PlanningsOperationDeliveryByDeliveryIdEntityAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'set': {
      if (!state) {
        return action.payload;
      }
      Object.keys(state).forEach((key) => {
        const oldDelivery = state[key];
        const newDelivery = action.payload[key];
        if (oldDelivery && newDelivery) {
          for (let i = newDelivery.cycles.length; i < oldDelivery.cycles.length; i++) {
            newDelivery.addEmptyCycle();
          }
        }
      });
      return action.payload;
    }
    case 'addEmptyCycle': {
      const delivery = state[`${action.payload}`];
      if (delivery) {
        delivery.addEmptyCycle();
        state[`${action.payload}`] = delivery;
      }
      return state;
    }
    case 'removeEmptyCycle': {
      const delivery = state[`${action.payload.deliveryId}`];
      if (delivery) {
        delivery.removeEmptyCycle(action.payload.cycleIndex);
        state[`${action.payload.deliveryId}`] = delivery;
      }
      return state;
    }
    default:
      return state;
  }
};

type EditPlacesAction = {
  type: 'set',
  payload: EditPlaceEntity;
} | ResetAction;

export const editPlacesReducer: Reducer<EditPlaceEntity[], EditPlacesAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'set': {
      const filtered = state.filter((it) => !(it.deliveryId === action.payload.deliveryId && it.cycleIndex === action.payload.cycleIndex));
      return [...filtered, action.payload];
    }
    case 'reset':
      return [];
    default:
      return state;
  }
};
