import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { addDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import LoadingComponent from 'src/components/LoadingComponent';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { OrderSearchConditionEntity } from 'src/entities/OrderSearchCondition.entity';
import { RespectType, WeekDayEn } from 'src/entities/planningEntity';
import { DeliveryConditions, PlanningConditions, PlanningSettingRequest } from 'src/entities/PlanningHistory.entity';
import { AsyncPlanningResponseEntity, PlanningResponseEntity } from 'src/entities/planningResponseEntity';
import { BaseOperationEntity, distanceMmBurdenPerShippersEntity, PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { useQueryLatestAlgorithmRequestVersion } from 'src/hooks/useQueryLatestAlgorithmRequestVersion';
import { useInfiniteQueryOrders, useMutationOrder } from 'src/hooks/useQueryOrders';
import { useMutationPlanning } from 'src/hooks/useQueryPlanning';
import { useQueryPlanningGroups } from 'src/hooks/useQueryPlanningGroups';
import { useQueryPlanningOrderStatistics } from 'src/hooks/useQueryPlanningOrderStatistics';
import { useInfiniteQueryPlanningsDeliveries } from 'src/hooks/useQueryPlanningsDeliveries';
import { useQueryPlanningsDrivers } from 'src/hooks/useQueryPlanningsDrivers';
import { useQueryPlanningsNotAllocReasons } from 'src/hooks/useQueryPlanningsNotAllocReasons';
import { useQueryPlanningsResults } from 'src/hooks/useQueryPlanningsResults';
import { useMutationPlanningRunnings, useQueryPlanningsRunnings } from 'src/hooks/useQueryPlanningsRunnings';
import { useQueryPlanningsTrucks } from 'src/hooks/useQueryPlanningsTrucks';
import { Operations } from 'src/models/Operations';
import { PlanningsOperationDelivery } from 'src/models/PlanningsOperationGroup.model';
import { RelaxedRuleValues, RelaxedRuleVo } from 'src/vo/RelaxedRule.vo';

import { useMutationPlanningOperations } from '../../hooks/useQueryPlanningsOperations';
import { allocateHistoriesReducer, currentHistoryVersionReducer, deliveryEntitiesReducer, driverEntitiesReducer, notAllocReasonsReducer, notAllocReasonStateReducer, orderEntityMapReducer, planningInitialState, planningReducer, selectedOrderIdsReducer } from '../V2Plans/reducers';

import Presenter from './Presenter';
import { groupEntitiesReducer, orderEntitiesReducer, selectedGroupEntityReducer } from './Reducer';

type Props = {
  startDate: Date;
  unit: string;
  customInputFields: string[];
}

const Component: FC<Props> = memo(({
  startDate,
  unit,
  customInputFields,
}) => {
  const startYMD = datetimeDecorator.toYyyyMmDd(startDate);
  const endDate = useMemo(() => addDays(startDate, 6), [startDate]);
  const endYMD = datetimeDecorator.toYyyyMmDd(endDate);

  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const reset = useCallback(() => {
    dispatchPlanning({
      type: 'updateStartOn',
      payload: startYMD
    });
    dispatchPlanning({
      type: 'updateEndOn',
      payload: endYMD
    });
    dispatchSelectedOrderIds({ type: 'reset' });
    setPrevDeliveryData([]);
  }, [startYMD, endYMD]);

  // queryの前にないと、query が2回呼ばれてしまう。
  useEffect(() => {
    if (!licenseContext.config?.selected_company_id) return;
    reset();
  }, [startYMD, endYMD, licenseContext.config?.selected_company_id, reset]);

  const resetQueries = useCallback(() => {
    // eslint-disable-next-line no-void
    void Promise.all([
      // queryClient.resetQueries(['useQueryPlanningsOperations']),
      queryClient.resetQueries(['useQueryPlanningsDeliveries', { startOn: startYMD, endOn: endYMD }]),
      queryClient.resetQueries(['useQueryLatestAlgorithmRequestVersion']),
    ]);
  }, [endYMD, queryClient, startYMD]);

  const [selectedOrderIds, dispatchSelectedOrderIds] = useReducer(selectedOrderIdsReducer, []);

  const { data: truckData, isLoading: trucksIsLoading } = useQueryPlanningsTrucks(startYMD, endYMD);
  const { data: driverData, isLoading: driversIsLoading } = useQueryPlanningsDrivers(startYMD, endYMD);
  // const { data: fetchedDeliveryData, isLoading: deliveriesIsLoading } = useQueryPlanningsDeliveries(startYMD, endYMD);
  const {
    data: deliveriesInfiniteData,
    isLoading: deliveriesIsLoading,
    hasNextPage: deliveriesHasNextPage,
    fetchNextPage: fetchNextDeliveries,
  } = useInfiniteQueryPlanningsDeliveries(startYMD, endYMD, 10);
  const {
    data: orderData,
    isLoading: ordersIsLoading,
    hasNextPage: ordersHasNextPage,
    fetchNextPage: fetchNextOrders,
    isInitialLoading: ordersIsInitialLoading,
  } = useInfiniteQueryOrders(startYMD, endYMD, 200);
  const { data: planningGroupsData } = useQueryPlanningGroups();
  const { data: planningOrderStatisticsEntity } = useQueryPlanningOrderStatistics(startYMD, endYMD, selectedOrderIds);
  const { data: latestAlgorithmRequestVersionData } = useQueryLatestAlgorithmRequestVersion(startYMD, endYMD);
  const { data: planningRunningEntities } = useQueryPlanningsRunnings(startYMD, endYMD);
  const { delete: deletePlanningRunning } = useMutationPlanningRunnings();

  const { normalPlanning, rollback } = useMutationPlanning();
  const { operationBulkResetting } = useMutationOrder();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupEntities, dispatchGroupEntities] = useReducer(groupEntitiesReducer, []);
  const [orderEntities, dispatchOrderEntities] = useReducer(orderEntitiesReducer, []);
  const [selectedGroupEntity, dispatchSelectedGroupEntity] = useReducer(selectedGroupEntityReducer, undefined);
  const [driverEntities, dispatchDriverEntities] = useReducer(driverEntitiesReducer, []);
  const [deliveryEntities, dispatchDeliveryEntities] = useReducer(deliveryEntitiesReducer, []);
  const [currentHistoryVersion, dispatchCurrentHistoryVersion] = useReducer(currentHistoryVersionReducer, undefined);
  const [orderEntityMap, dispatchOrderEntityMap] = useReducer(orderEntityMapReducer, null);
  const [orderSearchKw, setOrderSearchKw] = useState<string>('');
  const [currentlyOrderSearching, setCurrentlyOrderSearching] = useState<boolean>(false);
  const [orderSearchConditions, setOrderSearchConditions] = useState<OrderSearchConditionEntity[]>([]);
  const [planning, dispatchPlanning] = useReducer(planningReducer, planningInitialState);
  const [asyncPlanningResponse, setAsyncPlanningResponse] = useState<AsyncPlanningResponseEntity>(null);
  const { data: planningResultData, error: planningResultError } = useQueryPlanningsResults(asyncPlanningResponse);
  const [fetchedDeliveryData, setFetchedDeliveryData] = useState<PlanningsDeliveryEntity[]>([]);
  const [deliveryData, setDeliveryData] = useState<PlanningsDeliveryEntity[]>([]);
  const [prevDeliveryData, setPrevDeliveryData] = useState<PlanningsDeliveryEntity[]>([]);

  useEffect(() => {
    if (!deliveriesInfiniteData || deliveriesIsLoading) {
      return;
    }
    setDeliveryData(deliveriesInfiniteData.pages.flatMap((page) => page.data));
    if (deliveriesHasNextPage) {
      // eslint-disable-next-line no-void
      void fetchNextDeliveries();
    } else {
      setFetchedDeliveryData((prev) => {
        setPrevDeliveryData(prev);
        return deliveriesInfiniteData.pages.flatMap((page) => page.data);
      });
    }
  }, [deliveriesHasNextPage, deliveriesInfiniteData, deliveriesIsLoading, fetchNextDeliveries]);

  const [notAllocState, dispatchNotAllocReasonState] = useReducer(
    notAllocReasonStateReducer,
    { sessionId: null, notAllocOrderLength: 0, disabledOrders: [] }
  );
  const { data: notAllocReasonData, isLoading: notAllocReasonDataIsLoading } = useQueryPlanningsNotAllocReasons(notAllocState.sessionId);
  const [notAllocReasons, dispatchNotAllocReasons] = useReducer(notAllocReasonsReducer, null);
  const [allocateHistories, dispatchAllocateHistories] = useReducer(allocateHistoriesReducer, []);

  const generateRandomString = (charCount = 7): string => {
    const str = Math.random().toString(36).substring(2).slice(-charCount);
    return str.length < charCount ? str + 'a'.repeat(charCount - str.length) : str;
  };

  const planningSettings = useCallback(() => {
    const json = localStorage.getItem('PlanningPresenterSettings');
    return JSON.parse(json) as unknown as PlanningConditions;
  }, []);

  const cancelRunningPlan = useCallback((id: number) => {
    deletePlanningRunning.mutate({ id, startOn: startYMD, endOn: endYMD }, {
      onSuccess: () => {
        enqueueSnackbar('実行中の自動配車を停止しました。');
      },
      onError: (_) => {
        enqueueSnackbar('自動配車の停止処理でエラーが発生しました。');
      },
      onSettled: () => {
        setAsyncPlanningResponse(null);
        queryClient.clear();
        setIsLoading(false);
      }
    });
  }, [deletePlanningRunning, endYMD, enqueueSnackbar, queryClient, startYMD]);

  const refreshRunningEntities = useCallback(() => {
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningsRunnings']
    );
  }, [queryClient]);

  const resetQuery = useCallback(() => {
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningsDeliveries']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningOrderStatistics']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['orders', startYMD, endYMD]
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryLatestAlgorithmRequestVersion']
    );
  }, [endYMD, queryClient, startYMD]);

  const showNotAllocReasons = useCallback(() => {
    if (!notAllocState.sessionId) return;
    if (notAllocReasonDataIsLoading) return;
    if ([truckData, driverData, notAllocReasonData, deliveryData].some((maybe) => !maybe)) return;

    const truckOrder = truckData.reduce((map, truck, index) => { map[truck.id] = index; return map; }, {});
    const driverOrder = driverData.reduce((map, driver, index) => { map[driver.id] = index; return map; }, {});
    const notAllocDeliveryIds = notAllocReasonData.map((it) => it.delivery_id);
    const notAllocDeliveries = deliveryData.filter((it) => notAllocDeliveryIds.includes(it.id));
    const sorted = notAllocReasonData.sort((a, b) => {
      const deliveryA = notAllocDeliveries.find((it) => it.id === a.delivery_id);
      const deliveryB = notAllocDeliveries.find((it) => it.id === b.delivery_id);
      // 宵積みの場合に翌日の deliveryId が返ってくることがあるため、deliveryId がない場合は 0 として扱う
      if (deliveryA === undefined || deliveryB === undefined) return 0;

      let ret = truckOrder[deliveryA.truckId] - truckOrder[deliveryB.truckId];
      if (ret === 0) {
        ret = driverOrder[deliveryA.driverId] - driverOrder[deliveryB.driverId];
      }
      return ret;
    });
    if (notAllocState.disabledOrders.length > 0) {
      notAllocState.disabledOrders.forEach((it: number) => {
        sorted.push({
            order_id: it,
            delivery_id: 0,
            message: '有料道路を使用しないと到達できないルートがあります',
        });
      });
    }

    dispatchNotAllocReasons({
      type: 'set',
      payload: sorted
    });
  }, [deliveryData, driverData, notAllocReasonData, notAllocReasonDataIsLoading, notAllocState, truckData]);

  useEffect(() => {
    if (notAllocReasonDataIsLoading) return;

    showNotAllocReasons();
  }, [notAllocReasonDataIsLoading, showNotAllocReasons]);

  const handlePlanningResponse = useCallback((response: PlanningResponseEntity, message: string) => {
    resetQuery();

    enqueueSnackbar(message);

    if (response.messages && response.messages.length > 0) {
      enqueueSnackbar(response.messages.join(', '));
    }
    if (response.not_allocated_order_ids && response.not_allocated_order_ids.length) {
      if (response.session_id) {
        dispatchNotAllocReasonState({
          type: 'set',
          payload: {
            sessionId: response.session_id,
            notAllocOrderLength: response.not_allocated_order_ids.length,
            disabledOrders: response.disabled_order_ids,
          }
        });
        response.allocated_order_ids.forEach((it) => dispatchSelectedOrderIds({ type: 'remove', payload: it }));
        enqueueSnackbar(`${response.not_allocated_order_ids.length.toLocaleString()}件が割り当たりませんでした。割当たらない理由を確認してください(最大100件まで)。`);
      } else {
        enqueueSnackbar(`${response.not_allocated_order_ids.length.toLocaleString()}件が割り当たりませんでした。`);
      }
    } else {
      dispatchNotAllocReasonState({
        type: 'set',
        payload: { sessionId: null, notAllocOrderLength: 0, disabledOrders: [] }
      });
    }
  }, [enqueueSnackbar, resetQuery]);

  const handleErrorResponse = useCallback((error: AxiosError<{
    message: string;
  }>) => {
    queryClient.clear();

    if (error.response?.status === 400 && error.response?.data?.message) {
      enqueueSnackbar(error.response.data.message);
    } else {
      enqueueSnackbar(fiveZeroZeroErrorMessage);
    }
  }, [enqueueSnackbar, queryClient]);

  useEffect(() => {
    if (!(planningResultData || planningResultError)) return;

    if (planningResultData) {
      handlePlanningResponse(planningResultData, '自動配車が完了しました');
    } else if (planningResultError) {
      handleErrorResponse(planningResultError);
    }
    setAsyncPlanningResponse(null);
    // resetEditPositions();
    refreshRunningEntities();
  }, [handleErrorResponse, handlePlanningResponse, planningResultData, planningResultError, refreshRunningEntities]);

  useEffect(() => {
    const loading = asyncPlanningResponse != null || trucksIsLoading || driversIsLoading || deliveriesIsLoading || ordersIsLoading;
    setIsLoading(loading);
  }, [asyncPlanningResponse, deliveriesIsLoading, driversIsLoading, notAllocReasonDataIsLoading, ordersIsLoading, trucksIsLoading]);

  const planningRequest: (requestDeliveryIds: number[], requestOrderIds: number[]) => void = useCallback((requestDeliveryIds, requestOrderIds,) => {
    dispatchNotAllocReasons({ type: 'reset' });
    dispatchNotAllocReasonState({
      type: 'set',
      payload: { sessionId: null, notAllocOrderLength: 0, disabledOrders: [] }
    });
    const shiftIds = requestDeliveryIds.map((it) => {
      const entity = deliveryEntities.find((e) => e.id === it);
      return entity ? entity.shiftId : null;
    });
    if (shiftIds.length === 0) {
      enqueueSnackbar('配車可能なシフトがありません。');
      return;
    }

    const allocateHistoryEntity: AllocateHistoryEntity = {
      version: generateRandomString(20),
      companyId: licenseContext.config?.selected_company_id,
      actAt: new Date(),
      action: '自動配車',
      message: '自動配車を実行しました。',
      deliveryEntities,
    };

    dispatchAllocateHistories({
      type: 'add',
      payload: allocateHistoryEntity,
    });

    enqueueSnackbar('自動配車を開始します');

    setIsLoading(true);

    const deliveries = deliveryData.filter((it) => requestDeliveryIds.includes(it.id));
    const model = new Operations(licenseContext.config, deliveries.flatMap((it) => it.operations));
    const tmp = model.createPlanningsOperationDeliveryByDeliveryIdEntity();
    const planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity = deliveryEntities.reduce((acc, delivery) => {
      acc[delivery.id] = tmp[delivery.id] ?? PlanningsOperationDelivery.empty(licenseContext.config?.delivery_list_grouping);
      return acc;
    }, {} as PlanningsOperationDeliveryByDeliveryIdEntity);

    const deliveryOperations = requestDeliveryIds.map((deliveryId) => {
      const delivery = planningsOperationDeliveryByDeliveryIdEntity[deliveryId];
      delivery.cycles.forEach((it) => {
        it.allOperations().forEach((ops) => {
          ops.cycle = it.cycleIndex;
        });
      });
      return {
        deliveryId,
        operations: delivery.allOperations().map((it) => ({
          id: it.id,
          cycle: it.cycle,
          orderId: it.orderId,
          action: it.action,
        }))
      };
    });

    const allDeliverySelected = requestDeliveryIds.length === deliveryData.length;
    const selectedCycleIndexes = requestDeliveryIds.map((it) => ({ deliveryId: it, cycleIndexes: [1] }));

    const selectedCycleIndexesOnlyAlloced = selectedCycleIndexes.map((it) => {
      const delivery = planningsOperationDeliveryByDeliveryIdEntity[it.deliveryId];
      if (delivery) {
        const selectedCycles = delivery.cycles.filter((c) => it.cycleIndexes.includes(c.cycleIndex));
        if (selectedCycles.every((c) => c.empty)) {
          return {
            ...it,
            cycleIndexes: [it.cycleIndexes[0]], // すべて未割り当てなら最初の回転だけにする
          };
        }
        return {
          ...it,
          cycleIndexes: selectedCycles.filter((c) => !c.empty).map((c) => c.cycleIndex), // 割り当て済みが含まれる場合は割り当て済みだけにする
        };
      }
      return it;
    });

    const deliveryConditions: DeliveryConditions = {
      truckDisplayStatus: 'すべて表示',
      selectedGroupIds: selectedGroupEntity ? [selectedGroupEntity.id] : [],
      selectedGroups: selectedGroupEntity ? [selectedGroupEntity] : [],
      allDeliverySelected,
      selectedCycleIndexes,
      selectedDeliveries: selectedCycleIndexes.map((it) => {
        const { deliveryId } = it;
        const delivery = deliveryEntities.find((deli) => deli.id === deliveryId);
        if (!delivery) return null;

        const truck = truckData.find((t) => t.id === delivery.truckId);
        const driver = driverEntities.find((d) => d.id === delivery.driverId);
        return {
          deliveryId,
          truckId: truck.id,
          licensePlateValue: truck.licensePlateValue,
          driverId: driver.id,
          driverName: driver.name,
        };
      })
    };
    const orderConditions = {
      orderSearchKw,
      orderSearchConditions: currentlyOrderSearching ? orderSearchConditions : [],
    };
    const planningConditions = planningSettings();
    const planningSetting: PlanningSettingRequest = {
      deliveryConditions,
      orderConditions,
      planningConditions,
    };

    let async = false;
    normalPlanning.mutate(
      {
        shiftIds,
        orderIds: requestOrderIds,
        planning,
        latestAlgorithmRequestVersion: latestAlgorithmRequestVersionData,
        requestAsync: true,
        selectedCycleIndexes: selectedCycleIndexesOnlyAlloced,
        deliveryOperations,
        planningSetting,
      },
      {
        onSuccess: (data: AxiosResponse<PlanningResponseEntity>) => {
          dispatchPlanning({
            type: 'resetRestrictedInsertPosition',
          });

          if (data.data.status === 'queued') {
            async = true;
            const entity: AsyncPlanningResponseEntity = data.data;
            setAsyncPlanningResponse(entity);
            selectedOrderIds.forEach((it) => dispatchSelectedOrderIds({ type: 'add', payload: it }));
            enqueueSnackbar('自動配車を実行中です。完了までしばらくお待ち下さい。');
            refreshRunningEntities();
          } else {
            handlePlanningResponse(data.data, '自動配車が完了しました');
          }
        },
        onError: (e: AxiosError<{ message: string }>) => {
          handleErrorResponse(e);
        },
        onSettled: () => {
          if (async) return;

          setIsLoading(false);
        }
      }
    );
  }, [currentlyOrderSearching, deliveryData, deliveryEntities, driverEntities, enqueueSnackbar, handleErrorResponse, handlePlanningResponse, latestAlgorithmRequestVersionData, licenseContext.config, orderSearchConditions, orderSearchKw, planning, planningSettings, refreshRunningEntities, selectedGroupEntity, selectedOrderIds, truckData]);

  const mergeYoidumi = useCallback((deliveries: PlanningsDeliveryEntity[], responseData: PlanningsDeliveryEntity[]) => {
    const ids = deliveries.map((it) => it.id);
    const extraEntities = responseData.filter((it) => !ids.includes(it.id));
    return [...deliveries, ...extraEntities];
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const mutateDeleteOrdersOperations = useCallback(async (requestOrderIds: number[]) => {
    enqueueSnackbar('配送計画を削除します');

    try {
      setIsLoading(true);
      await operationBulkResetting.mutateAsync(requestOrderIds, {
        onSuccess: (res: AxiosResponse<PlanningsDeliveryEntity[]>) => {
          // 宵積みがあるとリクエストに含まれないものも削除するので、戻り値を受け取り追加する。
          const merged = mergeYoidumi(deliveryEntities, res.data);

          const allocateHistoryEntity: AllocateHistoryEntity = {
            version: generateRandomString(20),
            companyId: licenseContext.config?.selected_company_id,
            actAt: new Date(),
            action: '割当部分解除',
            message: `${requestOrderIds.length.toLocaleString()}件の割当をすべて解除しました`,
            deliveryEntities: merged,
          };

          dispatchAllocateHistories({
            type: 'add',
            payload: allocateHistoryEntity
          });
        }
      });

      resetQueries();

      enqueueSnackbar('配送計画を削除しました');
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as unknown as AxiosError<string>;
        enqueueSnackbar(axiosError.response.data);
      }
      reset();
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [deliveryEntities, enqueueSnackbar, licenseContext.config, mergeYoidumi, reset, resetQueries]);

  const rollbackRequest = useCallback(() => {
    if (!currentHistoryVersion) return;

    const deliveryIds: number[] = deliveryEntities.map(({ id }) => id);
    const current = allocateHistories.find(({ version }) => version === currentHistoryVersion);

    if (![deliveryIds.length, current].every((maybe) => !!maybe)) return;

    const allocateHistoryEntity: AllocateHistoryEntity = {
      version: generateRandomString(20),
      companyId: licenseContext.config?.selected_company_id,
      actAt: new Date(),
      action: '取消',
      message: `${datetimeDecorator.toHourMinuteSeconds(current.actAt)}までまで元に戻しました`,
      deliveryEntities: deliveryData,
    };

    dispatchAllocateHistories({
      type: 'add',
      payload: allocateHistoryEntity
    });

    enqueueSnackbar('操作を取り消します');

    setIsLoading(true);

    const orderOperations: PlanningsOperationEntityWithStatsEntity[] = current.deliveryEntities.flatMap(({ operations: ops }) => ops as unknown as PlanningsOperationEntityWithStatsEntity);
    orderOperations.forEach((it) => delete it.timeWindow);

    const arrivalOperations: BaseOperationEntity[] = current.deliveryEntities
      .flatMap(({ arrivalOperation }) => arrivalOperation)
      .filter((maybe) => maybe);
    const departureOperations: BaseOperationEntity[] = current.deliveryEntities
      .flatMap(({ departureOperation }) => departureOperation)
      .filter((maybe) => maybe);
    const distanceMmBurdenPerShippersEntities: distanceMmBurdenPerShippersEntity[] = current.deliveryEntities
      .flatMap(({ distanceMmBurdenPerShippers }) => distanceMmBurdenPerShippers)
      .filter((maybe) => maybe);

    rollback.mutate(
      {
        deliveryIds,
        orderOperations,
        arrivalOperations,
        departureOperations,
        distanceMmBurdenPerShippersEntities,
        latestAlgorithmRequestVersion: latestAlgorithmRequestVersionData,
        startOn: startYMD,
        endOn: endYMD,
      },
      {
        onSuccess: (data: AxiosResponse<PlanningResponseEntity>) => {
          enqueueSnackbar('操作を取り消しました');
        },
        onError: (e: AxiosError<{ message: string; }>) => {
          handleErrorResponse(e);
        },
        onSettled: () => {
          setIsLoading(false);
          resetQueries();
        }
      }
    );
  }, [currentHistoryVersion, deliveryEntities, allocateHistories, licenseContext.config?.selected_company_id, deliveryData, enqueueSnackbar, rollback, latestAlgorithmRequestVersionData, startYMD, endYMD, handleErrorResponse, resetQueries]);

  const updateSelectedGroupEntity = useCallback((entity: PlanningsGroupEntity | undefined) => {
    dispatchSelectedGroupEntity({
      type: 'set',
      payload: entity
    });
  }, []);

  useEffect(() => {
    dispatchGroupEntities({
      type: 'set',
      payload: planningGroupsData
    });
  }, [planningGroupsData]);

  useEffect(() => {
    if (!orderData) return;

    dispatchOrderEntities({
      type: 'set',
      payload: orderData?.pages.flatMap((it) => it.data)
    });
    if (ordersHasNextPage) {
      // eslint-disable-next-line no-void
      void fetchNextOrders();
    }
  }, [fetchNextOrders, orderData, ordersHasNextPage]);

  useEffect(() => {
    if (!deliveryData) return;

    const allocatedOrderIds = deliveryData ? deliveryData.flatMap((it) => it.operations).map((it) => it.orderId) : [];
    allocatedOrderIds.forEach((it) => dispatchSelectedOrderIds({ type: 'remove', payload: it }));
  }, [deliveryData]);

  const { operationSendMail } = useMutationPlanningOperations();

  const sendMail = useCallback(() => {
    const startOn = datetimeDecorator.toYyyyMmDd(startDate);
    const endOn = datetimeDecorator.toYyyyMmDd(endDate);
    const shiftTruckIds = deliveryData?.map((it: PlanningsDeliveryEntity) => it.id) ?? [];

    setIsLoading(true);
    operationSendMail.mutate({ startOn, endOn, shiftTruckIds }, {
      onSuccess: () => {
        enqueueSnackbar('ドライバーにメール送信しました。');
      },
      onError: () => { enqueueSnackbar('メール送信処理でエラーになりました'); },
      onSettled: () => {
        setIsLoading(false);
        resetQueries();
      }
    });
  }, [deliveryData, endDate, enqueueSnackbar, operationSendMail, resetQueries, startDate]);

  const { addOrder, deleteOrders, restoreSplittedOrder, restoreSplittedOrders } = useMutationOrder();

  const mutateRestoreSplittedOrder: (orderId: number) => void = useCallback((orderId) => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('分割した案件を復元します。よろしいですか？')) return;

    setIsLoading(true);
    restoreSplittedOrder.mutate(
      orderId,
      {
        onSuccess: () => {
          resetQueries();
          enqueueSnackbar('分割した案件を元に戻しました。');
        },
        onError: () => {
          enqueueSnackbar('分割した案件を元に戻す処理でエラーが発生しました。');
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  }, [enqueueSnackbar, resetQueries, restoreSplittedOrder]);

  const mutateRestoreSplittedOrders: () => void = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('分割した案件をすべて復元します。よろしいですか？')) return;

    setIsLoading(true);
    restoreSplittedOrders.mutate(
      { startOn: startYMD, endOn: endYMD },
      {
        onSuccess: () => {
          resetQueries();
          enqueueSnackbar('分割した案件を元に戻しました。');
        },
        onError: () => {
          enqueueSnackbar('分割した案件を元に戻す処理でエラーが発生しました。');
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  }, [endYMD, enqueueSnackbar, resetQueries, restoreSplittedOrders, startYMD]);

  const mutateDeleteSpecificOrder: (orderId: number) => void = useCallback((orderId) => {
    setIsLoading(true);

    deleteOrders.mutate([orderId]);

    resetQueries();

    setIsLoading(false);
  }, [deleteOrders, resetQueries]);
  const [directionSettingDialogIsOpen, setDirectionSettingDialogIsOpen] = useState<boolean>(false);
  const updateDirectionSettingDialogIsOpen = useCallback((val: boolean) => {
    setDirectionSettingDialogIsOpen(val);
  }, []);

  const [selectPrintDialogIsOpen, setSelectPrintDialogIsOpen] = useState<boolean>(false);
  const selectPrintButtonOnClick = useCallback(() => {
    setSelectPrintDialogIsOpen(true);
  }, []);

  const selectPrintDialogClose = () => {
    setSelectPrintDialogIsOpen(false);
  };

  const printAllTruckDirectionsOnClick = useCallback(() => {
    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/truck-directions/?ids=${ids.join(',')}`;

    window.open(asidePath);
  }, [deliveryData]);

  const printUnloadTruckDirectionsOnClick = useCallback(() => {
    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/truck-directions/?ids=${ids.join(',')}&action=降`;

    window.open(asidePath);
  }, [deliveryData]);

  const printOperationDirectionUrl = useCallback(() => {
    if (!deliveryData) return '';

    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/operation-directions/?ids=${ids.join(',')}`;

    return asidePath;
  }, [deliveryData]);

  const printPickingListOnClick = useCallback(() => {
    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/picking-list/?ids=${ids.join(',')}&date=${startYMD}`;

    window.open(asidePath);
  }, [deliveryData, startYMD]);

  const downloadPlanCsvOnClick = useCallback(() => {
    window.open(`/api/v2/shifts/csv?start_at_range_start_on=${startYMD}&start_at_range_end_on=${endYMD}`);
  }, [startYMD, endYMD]);

  const addSelectedOrderId = useCallback(
    (id: number) => {
      dispatchSelectedOrderIds({
        type: 'add',
        payload: id
      });
    },
    [],
  );
  const removeSelectedOrderId = useCallback(
    (id: number) => {
      dispatchSelectedOrderIds({
        type: 'remove',
        payload: id
      });
    },
    [],
  );

  const mutateDeleteOrder: () => void = useCallback(() => {
    if (!selectedOrderIds) return;
    if (!selectedOrderIds.length) return;

    enqueueSnackbar(`${selectedOrderIds.length.toLocaleString()}件の案件を削除します`);
    setIsLoading(true);

    deleteOrders.mutate(
      selectedOrderIds,
      {
        onSuccess: () => {
          dispatchSelectedOrderIds({
            type: 'bulkRemove',
            payload: selectedOrderIds
          });

          enqueueSnackbar(`${selectedOrderIds.length.toLocaleString()}件の案件を削除しました`);
          resetQueries();
        },
        onError: () => {
          enqueueSnackbar('案件の削除でエラーが発生しました。');
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  }, [deleteOrders, enqueueSnackbar, resetQueries, selectedOrderIds]);

  const mutateCloneOrder: (order: OrderEntity) => void = useCallback((order) => {
    setIsLoading(true);

    order.id = null;
    // eslint-disable-next-line no-void
    void addOrder.mutateAsync(order).finally(() => {
      // src/components/V2OrderForm/index.tsx の reset を真似ている
      const resetQueryKeys = ['shifts', 'shift', 'unallocatedOrders', 'order', 'shippers', 'places', 'useQueryPlanningOrderStatistics'];
      // eslint-disable-next-line no-void
      void queryClient.resetQueries({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        predicate: (query) => resetQueryKeys.includes(`${query.queryKey[0]}`),
      });
      resetQueries();
      setIsLoading(false);
    });
  }, [addOrder, queryClient, resetQueries]);

  useEffect(() => {
    dispatchDriverEntities({
      type: 'set',
      payload: driverData || []
    });
  }, [driverData]);

  useEffect(() => {
    dispatchDeliveryEntities({
      type: 'set',
      payload: deliveryData || []
    });
  }, [deliveryData]);

  useEffect(() => {
    if (!orderData) return;

    const map = new Map<number, OrderEntity>();
    orderData.pages.flatMap((it) => it.data).forEach((it) => {
      map.set(it.id, it);
    });
    dispatchOrderEntityMap({
      type: 'set',
      payload: map
    });
  }, [orderData]);

  useEffect(() => {
    localStorage.setItem('planningOn', startYMD);
  }, [startYMD]);

  const updateBalancedLoading = useCallback((balancedLoading: boolean) => {
    dispatchPlanning({
      type: 'updateBalancedLoading',
      payload: balancedLoading
    });
  }, []);
  const updateIsRespectRecent = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateIsRespectRecent',
      payload: bool
    });
  }, []);
  const updateExcludeToll = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateExcludeToll',
      payload: bool
    });
  }, []);
  const updateRespectType = useCallback((respectType: RespectType) => {
    dispatchPlanning({
      type: 'updateRespectType',
      payload: respectType
    });
  }, []);
  const updateRespectDaysOfWeek = useCallback((days: WeekDayEn[]) => {
    dispatchPlanning({
      type: 'updateRespectDaysOfWeek',
      payload: days
    });
  }, []);
  const updateRespectOn = useCallback((payload: string) => {
    dispatchPlanning({
      type: 'updateRespectOn',
      payload
    });
  }, []);
  const updateIsTimeTableBackward = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateIsTimeTableBackward',
      payload: bool
    });
  }, []);
  const updateIncludeFutureDeliveries = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateIncludeFutureDeliveries',
      payload: bool
    });
  }, []);
  const updateAutoSplitOrders = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateAutoSplitOrders',
      payload: bool
    });
  }, []);
  const updateDontExchangePreroute = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateDontExchangePreroute',
      payload: bool,
    });
  }, []);
  const updateConcurrentAllOrNothing = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateConcurrentAllOrNothing',
      payload: bool,
    });
  }, []);
  const updateMLPlanning = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateMLPlanning',
      payload: bool,
    });
  }, []);
  const updateExpandWorkingStartMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandWorkingStartTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandWorkingEndMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandWorkingEndTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandLoadStartMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandLoadStartTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandLoadEndMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandLoadEndTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandUnloadStartMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandUnloadStartTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandUnloadEndMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandUnloadEndTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandMaxVolumeRate = useCallback((rate: number) => {
    dispatchPlanning({
      type: 'updateExpandMaxVolumeRate',
      payload: rate / 100.0
    });
  }, []);
  const updateExpandMaxLoadCapacityRate = useCallback((rate: number) => {
    dispatchPlanning({
      type: 'updateExpandMaxLoadCapacityRate',
      payload: rate / 100.0
    });
  }, []);
  const updateMLSourceType = useCallback((type: number) => {
    dispatchPlanning({
      type: 'updateMLSourceType',
      payload: type,
    });
  }, []);
  const updateOptimizeForLoad = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateOptimizeForLoad',
      payload: bool,
    });
  }, []);
  const updateForceNullBase = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateForceNullBase',
      payload: bool,
    });
  }, []);
  const updateTrunkTransportation = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateTrunkTransportation',
      payload: bool,
    });
  }, []);
  const emptyArray = useMemo(() => [], []);
  const selectedRelaxedRules = useMemo(() => (
    planning.selectedRelaxedRules || emptyArray as RelaxedRuleVo[]
  ), [emptyArray, planning.selectedRelaxedRules]);
  const setSelectedRelaxedRules = useCallback((rules: RelaxedRuleVo[]) => {
    dispatchPlanning({
      type: 'updateSelectedRelaxedRules',
      payload: rules
    });
  }, []);
  const setPriorityLargeTrucks = useCallback((priority: number) => {
    dispatchPlanning({
      type: 'updatePriorityLargeTrucks',
      payload: priority
    });
  }, []);

  const setCurrentHistoryVersion = useCallback((version: string) => {
    dispatchCurrentHistoryVersion({
      type: 'set',
      payload: version,
    });
  }, []);

  const resetCurrentHistoryVersion = useCallback(() => {
    dispatchCurrentHistoryVersion({
      type: 'reset'
    });
    dispatchDeliveryEntities({
      type: 'set',
      payload: deliveryData || []
    });
  }, [deliveryData]);

  const relaxedRules = useMemo(() => RelaxedRuleValues.map((it) => it), []);

  if ([trucksIsLoading, driversIsLoading, ordersIsLoading, ordersIsInitialLoading].some((bool) => bool)) return <LoadingComponent />;

  return (
    <Presenter
      startDate={startDate}
      endDate={endDate}
      orderData={orderEntities}
      truckData={truckData}
      deliveryData={deliveryData}
      prevDeliveryData={prevDeliveryData}
      mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
      isLoading={isLoading}
      groupEntities={groupEntities}
      selectedGroupEntity={selectedGroupEntity}
      updateSelectedGroupEntity={updateSelectedGroupEntity}
      sendMail={sendMail}
      mutateDeleteSpecificOrder={mutateDeleteSpecificOrder}
      printOperationDirectionUrl={printOperationDirectionUrl}
      printAllTruckDirectionsOnClick={printAllTruckDirectionsOnClick}
      printUnloadTruckDirectionsOnClick={printUnloadTruckDirectionsOnClick}
      printPickingListOnClick={printPickingListOnClick}
      downloadPlanCsvOnClick={downloadPlanCsvOnClick}
      selectPrintDialogIsOpen={selectPrintDialogIsOpen}
      selectPrintButtonOnClick={selectPrintButtonOnClick}
      selectPrintDialogClose={selectPrintDialogClose}
      selectedOrderIds={selectedOrderIds}
      addSelectedOrderId={addSelectedOrderId}
      removeSelectedOrderId={removeSelectedOrderId}
      unit={unit}
      mutateDeleteOrder={mutateDeleteOrder}
      mutateCloneOrder={mutateCloneOrder}
      planningOrderStatisticsEntity={planningOrderStatisticsEntity}
      customInputFields={customInputFields}
      driverEntities={driverEntities}
      deliveryEntities={deliveryEntities}
      orderEntityMap={orderEntityMap}
      directionSettingDialogIsOpen={directionSettingDialogIsOpen}
      updateDirectionSettingDialogIsOpen={updateDirectionSettingDialogIsOpen}
      orderSearchKw={orderSearchKw}
      setOrderSearchKw={setOrderSearchKw}
      currentlyOrderSearching={currentlyOrderSearching}
      setCurrentlyOrderSearching={setCurrentlyOrderSearching}
      mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
      mutateRestoreSplittedOrders={mutateRestoreSplittedOrders}
      planningRequest={planningRequest}
      updateBalancedLoading={updateBalancedLoading}
      updateIsRespectRecent={updateIsRespectRecent}
      updateExcludeToll={updateExcludeToll}
      updateRespectType={updateRespectType}
      updateRespectDaysOfWeek={updateRespectDaysOfWeek}
      updateRespectOn={updateRespectOn}
      updateIsTimeTableBackward={updateIsTimeTableBackward}
      updateIncludeFutureDeliveries={updateIncludeFutureDeliveries}
      updateAutoSplitOrders={updateAutoSplitOrders}
      updateDontExchangePreroute={updateDontExchangePreroute}
      updateConcurrentAllOrNothing={updateConcurrentAllOrNothing}
      relaxedRules={relaxedRules}
      selectedRelaxedRules={selectedRelaxedRules}
      setSelectedRelaxedRules={setSelectedRelaxedRules}
      setPriorityLargeTrucks={setPriorityLargeTrucks}
      priorityLargeTrucks={planning.priorityLargeTrucks}
      updateExpandWorkingStartMinutes={updateExpandWorkingStartMinutes}
      updateExpandWorkingEndMinutes={updateExpandWorkingEndMinutes}
      updateExpandLoadStartMinutes={updateExpandLoadStartMinutes}
      updateExpandLoadEndMinutes={updateExpandLoadEndMinutes}
      updateExpandUnloadStartMinutes={updateExpandUnloadStartMinutes}
      updateExpandUnloadEndMinutes={updateExpandUnloadEndMinutes}
      updateExpandMaxVolumeRate={updateExpandMaxVolumeRate}
      updateExpandMaxLoadCapacityRate={updateExpandMaxLoadCapacityRate}
      updateMLSourceType={updateMLSourceType}
      updateMLPlanning={updateMLPlanning}
      updateOptimizeForLoad={updateOptimizeForLoad}
      updateForceNullBase={updateForceNullBase}
      updateTrunkTransportation={updateTrunkTransportation}
      setOrderSearchConditions={setOrderSearchConditions}
      notAllocReasons={notAllocReasons}
      planningRunningEntities={planningRunningEntities}
      cancelRunningPlan={cancelRunningPlan}
      asyncPlanningResponse={asyncPlanningResponse}
      refreshRunningEntities={refreshRunningEntities}
      allocateHistories={allocateHistories}
      currentHistoryVersion={currentHistoryVersion}
      setCurrentHistoryVersion={setCurrentHistoryVersion}
      resetCurrentHistoryVersion={resetCurrentHistoryVersion}
      rollbackRequest={rollbackRequest}
    />
  );
});

export default Component;
