import {
  Checkbox,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { addDays } from 'date-fns';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { datetimeUtil } from 'src/utils/datetime.util';

import OperationCarousel from './OperationCarousel';
import TruckLabel from './TruckLabel';

type Props = {
  startDate: Date;
  endDate: Date;
  isLoading: boolean;
  appBarHeight: number;
  groupedDeliveryEntities: Record<string, PlanningsDeliveryEntity[]>;
  truckData: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  deliveryData: PlanningsDeliveryEntity[];
  prevDeliveryData: PlanningsDeliveryEntity[];
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => Promise<void>;
  selectedDeliveryIds: number[];
  setSelectedDeliveryIds: (selectedDeliveryIds: number[]) => void;
};

const Calendar: FC<Props> = memo(({
  startDate,
  endDate,
  isLoading,
  appBarHeight,
  groupedDeliveryEntities,
  truckData,
  driverEntities,
  deliveryData,
  prevDeliveryData,
  mutateDeleteOrdersOperations,
  selectedDeliveryIds,
  setSelectedDeliveryIds,
}) => {
  const tableCellTruckStyle = useMemo(() => ({ width: '16%', border: '1px solid #ccc', p: 0, m: 0, }), []);
  const tableCellDateStyle = useMemo(() => ({ width: '12%', border: '1px solid #ccc', p: 0, m: 0, }), []);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const days = useMemo(() => [...Array(7)].map((_, it) => it).map((it) => addDays(startDate, it)), [startDate]);

  const dateHeader = useCallback((date: Date) => (
    <Stack>
      <Stack>{['日', '月', '火', '水', '木', '金', '土'][date.getDay()]}</Stack>
      <Stack>{date.getDate()}</Stack>
    </Stack>
  ), []);

  const findOperations = useCallback((deliveries: PlanningsDeliveryEntity[], date: Date) => {
    const allOpeations = deliveries.flatMap((it) => it.operations);
    return allOpeations.filter((it) => (
      it.action === '積' ? datetimeUtil.isSameDay(date, new Date(it.arrivalAt)) : datetimeUtil.isSameDay(date, new Date(it.departureAt))
    )).sort((a, b) => {
      let diff = new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime();
      if (diff !== 0) return diff;
      diff = new Date(a.departureAt).getTime() - new Date(b.departureAt).getTime();
      if (diff !== 0) return diff;
      return a.shipperName.localeCompare(b.shipperName);
    });
  }, []);

  const dateCell = useCallback((deliveries: PlanningsDeliveryEntity[], date: Date) => {
    const allOpeations = deliveries.flatMap((it) => it.operations);
    const operations = findOperations(deliveries, date);
    if (operations.length === 0 || deliveryData === undefined || deliveryData.length === 0) {
      return null;
    }
    const orderIds = operations.map((it) => it.orderId);
    const sameOrderOperations = allOpeations.filter((it) => orderIds.includes(it.orderId));
    return (
      <OperationCarousel
        date={date}
        startDate={startDate}
        endDate={endDate}
        operations={operations}
        sameOrderOperations={sameOrderOperations}
        isLoading={isLoading}
        truckData={truckData}
        driverEntities={driverEntities}
        deliveryData={deliveryData}
        prevDeliveryData={prevDeliveryData}
        mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
      />
    );
  }, [deliveryData, driverEntities, endDate, findOperations, isLoading, mutateDeleteOrdersOperations, prevDeliveryData, startDate, truckData]);

  const bgColor = useCallback((deliveries: PlanningsDeliveryEntity[], date: Date) => {
    const driverIds = deliveries.map((it) => it.driverId);
    const tomorrow = addDays(date, 1);
    const filtered = deliveryData.filter((it) => driverIds.includes(it.driverId)
        && date.getTime() <= new Date(it.endAt).getTime() && new Date(it.startAt).getTime() < tomorrow.getTime());
    if (filtered.length === 0) {
      return '#eee';
    }

    if (prevDeliveryData.length === 0) return '';

    const prevOrderIds = prevDeliveryData.flatMap((it) => it.operations).map((it) => it.orderId);
    const operations = findOperations(deliveries, date);
    const currentOrderIds = operations.map((it) => it.orderId);
    const includeChanges = !currentOrderIds.every((it) => prevOrderIds.includes(it));
    if (includeChanges) {
      return '#EAEFF6';
    }

    return '';
  }, [deliveryData, findOperations, prevDeliveryData]);

  const CustomStack = styled(Stack)({
    fontSize: '12px',
  });

  const [checkboxStatus, setCheckboxStatus] = useState<'every' | 'some' | 'none'>('none');

  const checkBoxOnClick = useCallback(() => {
    if (checkboxStatus === 'every') {
      setSelectedDeliveryIds([]);
    } else {
      setSelectedDeliveryIds(deliveryData.map(({ id }) => id));
    }
  }, [checkboxStatus, deliveryData, setSelectedDeliveryIds]);

  useEffect(() => {
    const allIds = deliveryData.map(({ id }) => id);
    let newStatus: 'every' | 'some' | 'none' = 'none';
    if (selectedDeliveryIds.length === 0) {
      newStatus = 'none';
    } else if (deliveryData.map(({ id }) => id).every((it) => selectedDeliveryIds.includes(it))) {
      newStatus = 'every';
    } else {
      newStatus = 'some';
    }
    setCheckboxStatus((prev) => (prev === newStatus ? prev : newStatus));
  }, [deliveryData, selectedDeliveryIds]);

  return (
    <CustomStack
      px={1}
      pb={1}
      height={`calc(100vh - ${appBarHeight + 100}px)`}
    >
      <TableContainer
        sx={{
          maxHeight: `calc(100vh - ${appBarHeight + 60}px)`,
        }}
      >
        <Table stickyHeader sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead sx={{ width: '100%' }}>
            <TableRow sx={{ width: '100%' }}>
              <TableCell align="left" sx={tableCellTruckStyle}>
                <Checkbox
                  onClick={() => checkBoxOnClick()}
                  checked={checkboxStatus === 'every'}
                  indeterminate={checkboxStatus === 'some'}
                  size="small"
                />
                車両
              </TableCell>
              {days.map((date) => (
                <TableCell align="center" key={date.toISOString()} sx={tableCellDateStyle}>
                  {dateHeader(date)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedDeliveryEntities).map(([key, deliveries]) => (
              <TableRow key={key}>
                <TableCell sx={{ ...tableCellTruckStyle }}>
                  <TruckLabel
                    deliveries={deliveries}
                    truckData={truckData}
                    driverEntities={driverEntities}
                    selectedDeliveryIds={selectedDeliveryIds}
                    setSelectedDeliveryIds={setSelectedDeliveryIds}
                  />
                </TableCell>
                {days.map((date) => (
                  <TableCell sx={{ ...tableCellDateStyle, backgroundColor: bgColor(deliveries, date) }} key={[date.toISOString(), key].join('-')}>
                    {dateCell(deliveries, date)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomStack>
  );
});

export default Calendar;
