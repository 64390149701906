import {
  Button, Checkbox, Stack
} from '@mui/material';
import { addDays } from 'date-fns';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { DriverEntity } from 'src/entities/Driver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';

import { EditPropsBase } from './EditDialog.presenter';

type Props = {
  on: Date;
  driverData: DriverEntity[];
  truckData: TruckEntity[];
  selectedGroup: PlanningsGroupEntity | undefined;
  updateEditPropsBase: (props: EditPropsBase) => void;
  isLoading: boolean;
  defaultStartAt: Date;
  defaultEndAt: Date;
  defaultWorkingAvailableDurationHours: number | undefined;
  selectedShiftCell: SelectedShiftCell[];
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
}

const HeaderCellPresenter: FC<Props> = memo((
  {
    on,
    driverData,
    truckData,
    selectedGroup,
    updateEditPropsBase,
    isLoading,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    selectedShiftCell,
    updateSelectedShiftCell,
  }
) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<'every' | 'some' | 'none'>('none');

  const [activeDrivers, setActiveDrivers] = useState<DriverEntity[]>([]);
  useEffect(() => {
    if (!driverData) return;
    let filtered: DriverEntity[] = driverData.filter((it) => it.isActive);
    if (selectedGroup !== undefined) {
      filtered = filtered.filter((it) => selectedGroup.truckIds.includes(it.defaultTruckId));
    }
    setActiveDrivers(filtered);
  }, [driverData, selectedGroup]);

  const onClickCheckbox = useCallback(() => {
    updateSelectedShiftCell(activeDrivers.map((it) => ({ driver_id: it.id, date: on })), !checked);
    setChecked((prev) => !prev);
  }, [checked, activeDrivers, on, updateSelectedShiftCell]);

  useEffect(() => {
    const filtered = activeDrivers.filter((it) => selectedShiftCell.find((elem) => elem.driver_id === it.id && elem.date === on));
    if (filtered.length === 0) {
      setSelectedStatus((prev) => (prev !== 'none' ? 'none' : prev));
      if (checked) setChecked((prev) => !prev);
    } else if (filtered.length === activeDrivers.length) {
      setSelectedStatus((prev) => (prev !== 'every' ? 'every' : prev));
      if (!checked) setChecked((prev) => !prev);
    } else {
      setSelectedStatus((prev) => (prev !== 'some' ? 'some' : prev));
    }
  }, [checked, activeDrivers, on, selectedShiftCell]);

  const buttonOnClick = useCallback(() => {
    const shiftCell: SelectedShiftCell[] = activeDrivers.map((it) => ({ driver_id: it.id, date: on }));

    const isNextDay = defaultStartAt > defaultEndAt;
    const startAt = new Date(defaultStartAt);
    startAt.setFullYear(on.getFullYear(), on.getMonth(), on.getDate());
    let endAt = new Date(defaultEndAt);
    endAt.setFullYear(on.getFullYear(), on.getMonth(), on.getDate());
    endAt = addDays(endAt, isNextDay ? 1 : 0);

    updateEditPropsBase({
      dialogTitle: `${format(on, 'MM/dd(E)', { locale: ja })}の一括編集`,
      defaultRequestEntities: [
        {
          startDate: datetimeDecorator.toYyyyMmDd(startAt),
          startTime: datetimeDecorator.toHourMinutes(startAt),
          endDate: datetimeDecorator.toYyyyMmDd(endAt),
          endTime: datetimeDecorator.toHourMinutes(endAt),
          workingAvailableDurationHours: defaultWorkingAvailableDurationHours
        }
      ],
      truckData,
      date: on,
      defaultStartAt: startAt,
      defaultEndAt: endAt,
      defaultWorkingAvailableDurationHours,
      shiftCell,
    });
  }, [defaultEndAt, defaultStartAt, defaultWorkingAvailableDurationHours, activeDrivers, on, truckData, updateEditPropsBase]);

  const buttonMemo = useMemo(() => (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Checkbox
        checked={selectedStatus === 'every'}
        indeterminate={selectedStatus === 'some'}
        onClick={onClickCheckbox}
      />
      <Button
        onClick={buttonOnClick}
        disabled={isLoading}
      >
        {format(on, 'MM/dd(E)', { locale: ja })}
      </Button>
    </Stack>
  ), [buttonOnClick, isLoading, on, onClickCheckbox, selectedStatus]);

  return buttonMemo;
});

export default HeaderCellPresenter;
