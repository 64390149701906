import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import { CompanyEntity } from 'src/entities/admin/companyEntity';

type Props = {
  companies: CompanyEntity[];
  hideShowAllSelectMenuItem: boolean;
  hideThisComponent: boolean;
}

export const SwitchCompanies: FC<Props> = memo((
  {
    companies,
    hideShowAllSelectMenuItem,
    hideThisComponent,
  }
) => {
  const queryClient = useQueryClient();

  const licenseContext = useContext(LicenseContext);
  const [initialValue, setInitialValue] = useState(0);

  const updateSelectedCompanyId = useCallback(async (companyId: number) => {
    await licenseContext.update({ selectedCompanyId: companyId });
  }, [licenseContext]);

  useEffect(() => {
    if (!licenseContext.config) return;

    if (!hideShowAllSelectMenuItem) {
      setInitialValue(licenseContext.config.selected_company_id);
    } else {
      let companyId = licenseContext.config.selected_company_id;
      if (companyId === 0) {
        companyId = licenseContext.config.company_id;
        // eslint-disable-next-line no-void
        void updateSelectedCompanyId(companyId);
      }
      setInitialValue(companyId);
    }
  }, [licenseContext.config, hideShowAllSelectMenuItem, updateSelectedCompanyId]);

  const handleChange = async (event: SelectChangeEvent<number>) => {
    const companyId = Number(event.target.value);
    ReactGA.event('select', { screen_name: SCREEN_NAMES.HEADER, button_name: '表示する事業所', label: companyId });
    await updateSelectedCompanyId(companyId);

    queryClient.clear();
  };

  const selectSx = {
    width: '150px',
    overFlow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const allCompanyDisplayText = 'すべて表示する';

  if (hideThisComponent || companies.length === 0) return null;

  return (
    <Box
      sx={{
        width: '150px'
      }}
    >
      <Toolbar
        title={`${companies.find((it) => it.id === initialValue)?.name || allCompanyDisplayText}が選択されています`}
      >
        <FormControl
          size="small"
        >
          <InputLabel>表示する事業所</InputLabel>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Select value={initialValue} onChange={handleChange} sx={selectSx}>
            {licenseContext?.config?.use_control_center && !hideShowAllSelectMenuItem && (
              <MenuItem key="0" value="0" sx={{ m: 0 }}>{allCompanyDisplayText}</MenuItem>
            )}
            {companies?.map((company) => (
              <MenuItem key={company.name} value={company.id} sx={{ m: 0 }}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Toolbar>
    </Box>
  );
});
