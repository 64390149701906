import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import ja from 'date-fns/locale/ja';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import ThemeProvider from 'src/theme/ThemeProvider';

if (ja && ja.options) {
  ja.options.weekStartsOn = 1;
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

const queryOptions = {
  defaultOptions: {
    queries: {
      // retry: false,
      // refetchOnMount: true,
      // suspense: true,
    },
  },
};
const queryClient = new QueryClient(queryOptions);

function App() {
  const content = useRoutes(router);

  useEffect(() => {
    if (!ReactGA.isInitialized) {
      ReactGA.initialize('G-MGYSVEJN30');
    }
  }, []);

  useEffect(() => {
    const page = window.location.href;
    ReactGA.send({ hitType: 'pageview', page });
  }, [content]);

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <SnackbarProvider
            maxSnack={5}
            preventDuplicate
            dense
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
          >
            <CssBaseline />
            {content}
          </SnackbarProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
