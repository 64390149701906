import { Chip, Stack, Typography } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import arrayUtil from 'src/utils/array.util';

type Props = {
  deliveryEntity: PlanningsDeliveryEntity;
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
}

const PlanningTransferSelectDeliveryPresenter: FC<Props> = memo((
  {
    deliveryEntity,
    driverEntities,
    truckEntities,
  }
  ) => {
  const [truckEntity, setTruckEntity] = useState<PlanningsTruckEntity | undefined>(undefined);
  const [driverEntity, setDriverEntity] = useState<PlanningsDriverEntity | undefined>(undefined);
  const [driverName, setDriverName] = useState('');
  const [truckLicensePlateValue, setTruckLicensePlateValue] = useState('');

  const [loadCapacityWeightG, setLoadCapacityWeightG] = useState<number | undefined>(undefined);
  const [maxLoadWeightG, setMaxLoadWeightG] = useState<number | undefined>(undefined);
  const weightUtilizationRateInitialState = '-%';
  const [weightUtilizationRate, setWeightUtilizationRate] = useState<string>(weightUtilizationRateInitialState);

  const [loadCapacityVolumeMm3, setLoadCapacityVolumeMm3] = useState<number | undefined>(undefined);
  const [maxLoadVolumeMm3, setMaxLoadVolumeMm3] = useState<number | undefined>(undefined);
  const volumeUtilizationRateInitialState = '-%';
  const [volumeUtilizationRate, setVolumeUtilizationRate] = useState<string>(volumeUtilizationRateInitialState);

  const [planStartAt, setPlanStartAt] = useState('');
  const [planEndAt, setPlanEndAt] = useState('');

  useEffect(() => {
    if (![deliveryEntity, truckEntities].every((maybe) => maybe)) return;

    setTruckEntity(
      truckEntities.find((it) => it.id === deliveryEntity.truckId)
    );
  }, [deliveryEntity, truckEntities]);

  useEffect(() => {
    if (![deliveryEntity, driverEntities].every((maybe) => maybe)) return;

    setDriverEntity(
      driverEntities.find((it) => it.id === deliveryEntity.driverId)
    );
  }, [deliveryEntity, driverEntities]);

  useEffect(() => {
    if (!truckEntity) return;

    setTruckLicensePlateValue(
      truckEntity.licensePlateValue
    );
    setLoadCapacityWeightG(
      truckEntity.maximumLoadingCapacityWeightForCalculation
    );
    setLoadCapacityVolumeMm3(
      truckEntity.loadingPlatformVolumeMm3
    );
  }, [truckEntity]);

  useEffect(() => {
    if (!driverEntity) return;

    setDriverName(
      driverEntity.name
    );
  }, [driverEntity]);

  useEffect(() => {
    if (!deliveryEntity) return;

    setPlanStartAt(
      datetimeDecorator.toHourMinutes(
        new Date(deliveryEntity.startAt)
      )
    );
    setPlanEndAt(
      datetimeDecorator.toHourMinutes(
        new Date(deliveryEntity.endAt)
      )
    );

    if (!deliveryEntity?.operations?.length) return;

    const weightGrams: number[] = deliveryEntity.operations.map((it) => it.operationWeightG);
    const runningSumGrams: number[] = arrayUtil.calculateRunningSum(weightGrams);
    setMaxLoadWeightG(
      Math.max(...runningSumGrams)
    );
    const volumeMm3: number[] = deliveryEntity.operations.map((it) => it.operationVolumeMm3).filter((maybe) => maybe);
    const runningSumMm3: number[] = arrayUtil.calculateRunningSum(volumeMm3);
    setMaxLoadVolumeMm3(
      Math.max(...runningSumMm3)
    );
  }, [deliveryEntity]);

  useEffect(() => {
    if (![loadCapacityWeightG, maxLoadWeightG].every((maybe) => maybe)) return;

    const rate = maxLoadWeightG / loadCapacityWeightG;
    setWeightUtilizationRate(numberDecorator.toRoundedRate(rate, ''));
  }, [loadCapacityWeightG, maxLoadWeightG]);

  useEffect(() => {
    if (![loadCapacityVolumeMm3, maxLoadVolumeMm3].every((maybe) => maybe)) return;

    const rate = loadCapacityVolumeMm3 / maxLoadVolumeMm3;
    setVolumeUtilizationRate(numberDecorator.toRoundedRate(rate, ''));
  }, [loadCapacityVolumeMm3, maxLoadVolumeMm3]);

  const [hasNotOrder, setHasNorOrder] = useState(true);
  useEffect(() => {
    setHasNorOrder(deliveryEntity.operations.length === 0);
  }, [deliveryEntity]);

  return (
    <Stack direction="row" gap={0.5}>
      <Typography>
        {driverName}
      </Typography>
      <Typography>
        {truckLicensePlateValue}
      </Typography>
      <Typography
        variant="caption"
      >
        {
          [
            planStartAt,
            planEndAt
          ].join(' - ')
        }
      </Typography>
      <Typography
        variant="caption"
      >
        {
          hasNotOrder ? (<Chip color="error" size="small" label="計画無" />)
          : [
            weightUtilizationRate,
            volumeUtilizationRate
          ].filter((maybe) => maybe).join(', ')
        }
      </Typography>
    </Stack>
  );
});

export default PlanningTransferSelectDeliveryPresenter;
