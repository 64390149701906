import { AppBar, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { FC, memo, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useParams } from 'react-router';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import PlansContext from 'src/contexts/PlansContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { CompanyEntity } from 'src/entities/admin/companyEntity';

import AppBarLgMenuComponent from './AppBarLgMenu.component';
import AppBarLogoComponent from './AppBarLogo.component';
import AppBarMdMenuComponent from './AppBarMdMenu.component';
import AppBarSmMenuComponent from './AppBarSmMenu.component';

const AppBarContainerComponent: FC = memo(() => {
  const licenseContext = useContext(LicenseContext);
  const plansContext = useContext(PlansContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const navigate = useNavigate();
  const { startOn, endOn } = useParams();

  const [hideThisComponent, setHideThisComponent] = useState<boolean>(false);
  const [hideShowAllSelectMenuItem, setHideShowAllSelectMenuItem] = useState<boolean>(false);
  const [companies, setCompanies] = useState<CompanyEntity[]>([]);

  const [planningMenuAnchorEl, setPlanningMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handlePlanningMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPlanningMenuAnchorEl(event.currentTarget);
  };
  const handlePlanningMenuOnClose = () => {
    setPlanningMenuAnchorEl(null);
  };

  const [settingMenuAnchorEl, setSettingMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleSettingMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingMenuAnchorEl(event.currentTarget);
  };
  const handleSettingMenuOnClose = () => {
    setSettingMenuAnchorEl(null);
  };

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleAccountMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };
  const handleAccountMenuOnClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const buildPathWithStartOnAndEndOn = (plansOrTimelineOrOrders: string) => {
    if (startOn && endOn) {
      return `/${plansOrTimelineOrOrders}/${startOn}/${endOn}`;
    }

    const on = localStorage.getItem('planningOn') || datetimeDecorator.toYyyyMmDd(new Date());
    return `/${plansOrTimelineOrOrders}/${on}/${on}`;
  };

  const buildPathWithStartOn = (plansOrTimelineOrOrders: string) => {
    if (startOn) {
      return `/${plansOrTimelineOrOrders}/${startOn}`;
    }

    const on = localStorage.getItem('planningOn') || datetimeDecorator.toYyyyMmDd(new Date());
    return `/${plansOrTimelineOrOrders}/${on}`;
  };

  const navigateToDashboard = (isLogo: boolean) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: isLogo ? 'ロゴ' : SCREEN_NAMES.DASHBOARD });
    navigate('/dashboard');
  };

  const navigateToKpis = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '実績参照' });
    navigate('/kpis');
  };

  const navigateToPlanningMap = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '配車計画 - マップ' });
    navigate(buildPathWithStartOnAndEndOn('plans'));
    handlePlanningMenuOnClose();
  };

  const navigateToPlanningTimeline = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '配車計画 - タイムライン' });
    navigate(buildPathWithStartOnAndEndOn('timeline'));
    handlePlanningMenuOnClose();
  };

  const navigateToPlanningTrunkTransportation = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '配車計画 - 幹線輸送' });
    navigate(buildPathWithStartOn('trunk-transportation'));
    handlePlanningMenuOnClose();
  };

  const navigateToOrders = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '案件一覧' });
    navigate(buildPathWithStartOnAndEndOn('orders'));
  };

  const navigateToGarages = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '設定 - 車庫管理' });
    navigate('/garages');
    handleSettingMenuOnClose();
  };

  const navigateToTrucks = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '設定 - 車両管理' });
    navigate('/trucks');
    handleSettingMenuOnClose();
  };

  const navigateToGroups = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '設定 - 車両グループ管理' });
    navigate('/groups');
    handleSettingMenuOnClose();
  };

  const navigateToDrivers = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '設定 - ドライバー管理' });
    navigate('/drivers');
    handleSettingMenuOnClose();
  };

  const navigateToPlaces = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '設定 - 地点管理' });
    navigate('/places');
    handleSettingMenuOnClose();
  };

  const navigateToAreas = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '設定 - エリア管理' });
    navigate('/areas');
    handleSettingMenuOnClose();
  };

  const navigateToConfig = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: '設定 - 計算エンジン設定' });
    navigate('/config');
    handleSettingMenuOnClose();
  };

  const companyName = () => licenseContext?.config?.company_name;

  const userName = () => licenseContext?.config?.person_name || licenseContext?.config?.email;

  const useTrunkTransportation = licenseContext?.config?.use_trunk_transportation;

  const navigateToAccounts = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: 'アカウント - アカウント情報' });
    navigate('/accounts');
    handleAccountMenuOnClose();
  };

  const isAdmin = () => licenseContext?.config?.role && licenseContext?.config?.role?.name === 'admin';

  const navigateToCompany = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: 'アカウント - 会社情報' });
    navigate('/company');
    handleAccountMenuOnClose();
  };

  const logout = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.HEADER, button_name: 'アカウント - ログアウト' });
    licenseContext.logout();
    handleAccountMenuOnClose();
  };

  useEffect(() => {
    const hideShowAllSelectMenuItemPaths = [
      '/config',
      '/import'
    ];

    setHideShowAllSelectMenuItem(
      hideShowAllSelectMenuItemPaths.includes(window.location.pathname)
    );
  }, [window.location.pathname]);

  useEffect(() => {
    const hideThisComponentPaths = [
      '/accounts',
      '/company',
    ];

    const isHideThisComponentPath = hideThisComponentPaths.includes(window.location.pathname);
    const notHaveSelectableCompanies = companies.length < 2;

    setHideThisComponent(isHideThisComponentPath || notHaveSelectableCompanies);
  }, [companies, window.location.pathname]);

  useEffect(() => {
    setCompanies(
      licenseContext?.config?.selectable_companies ?? []
    );
  }, [licenseContext]);
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.colors.alpha.white[100],
      }}
    >
      {(isLargeScreen) && (
        <Toolbar
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'none',
              lg: 'flex',
              xl: 'flex',
            },
          }}
        >
          <AppBarLogoComponent
            companies={companies}
            navigateToDashboard={navigateToDashboard}
            hideShowAllSelectMenuItem={hideShowAllSelectMenuItem}
            hideThisComponent={hideThisComponent}
          />
          <AppBarLgMenuComponent
            navigateToDashboard={navigateToDashboard}
            navigateToKpis={navigateToKpis}
            handlePlanningMenuOnClick={handlePlanningMenuOnClick}
            planningMenuAnchorEl={planningMenuAnchorEl}
            handlePlanningMenuOnClose={handlePlanningMenuOnClose}
            navigateToPlanningMap={navigateToPlanningMap}
            navigateToPlanningTimeline={navigateToPlanningTimeline}
            navigateToOrders={navigateToOrders}
            handleSettingMenuOnClick={handleSettingMenuOnClick}
            settingMenuAnchorEl={settingMenuAnchorEl}
            handleSettingMenuOnClose={handleSettingMenuOnClose}
            navigateToGarages={navigateToGarages}
            navigateToTrucks={navigateToTrucks}
            navigateToGroups={navigateToGroups}
            navigateToDrivers={navigateToDrivers}
            navigateToPlaces={navigateToPlaces}
            navigateToAreas={navigateToAreas}
            navigateToConfig={navigateToConfig}
            handleAccountMenuOnClick={handleAccountMenuOnClick}
            companyName={companyName}
            userName={userName}
            accountMenuAnchorEl={accountMenuAnchorEl}
            handleAccountMenuOnClose={handleAccountMenuOnClose}
            navigateToAccounts={navigateToAccounts}
            isAdmin={isAdmin}
            navigateToCompany={navigateToCompany}
            logout={logout}
            useTrunkTransportation={useTrunkTransportation}
            navigateToPlanningTrunkTransportation={navigateToPlanningTrunkTransportation}
          />
        </Toolbar>
      )}
      {(isMediumScreen) && (
        <Toolbar
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
              md: 'flex',
              lg: 'none',
              xl: 'none',
            }
          }}
        >
          <AppBarLogoComponent
            companies={companies}
            navigateToDashboard={navigateToDashboard}
            hideShowAllSelectMenuItem={hideShowAllSelectMenuItem}
            hideThisComponent={hideThisComponent}
          />
          <AppBarMdMenuComponent
            navigateToKpis={navigateToKpis}
            handlePlanningMenuOnClick={handlePlanningMenuOnClick}
            planningMenuAnchorEl={planningMenuAnchorEl}
            handlePlanningMenuOnClose={handlePlanningMenuOnClose}
            navigateToPlanningMap={navigateToPlanningMap}
            navigateToPlanningTimeline={navigateToPlanningTimeline}
            navigateToOrders={navigateToOrders}
            handleSettingMenuOnClick={handleSettingMenuOnClick}
            settingMenuAnchorEl={settingMenuAnchorEl}
            handleSettingMenuOnClose={handleSettingMenuOnClose}
            navigateToGarages={navigateToGarages}
            navigateToTrucks={navigateToTrucks}
            navigateToGroups={navigateToGroups}
            navigateToDrivers={navigateToDrivers}
            navigateToPlaces={navigateToPlaces}
            navigateToAreas={navigateToAreas}
            navigateToConfig={navigateToConfig}
            handleAccountMenuOnClick={handleAccountMenuOnClick}
            accountMenuAnchorEl={accountMenuAnchorEl}
            handleAccountMenuOnClose={handleAccountMenuOnClose}
            navigateToAccounts={navigateToAccounts}
            isAdmin={isAdmin}
            navigateToCompany={navigateToCompany}
            logout={logout}
            useTrunkTransportation={useTrunkTransportation}
            navigateToPlanningTrunkTransportation={navigateToPlanningTrunkTransportation}
          />
        </Toolbar>
      )}
      {isSmallScreen && (
        <Toolbar
          sx={{
            display: {
              xs: 'flex',
              sm: 'none',
              md: 'none',
              lg: 'none',
              xl: 'none',
            }
          }}
        >
          <AppBarLogoComponent
            companies={companies}
            navigateToDashboard={navigateToDashboard}
            hideShowAllSelectMenuItem={hideShowAllSelectMenuItem}
            hideThisComponent={hideThisComponent}
          />
          <AppBarSmMenuComponent
            navigateToKpis={navigateToKpis}
            handlePlanningMenuOnClick={handlePlanningMenuOnClick}
            planningMenuAnchorEl={planningMenuAnchorEl}
            handlePlanningMenuOnClose={handlePlanningMenuOnClose}
            navigateToPlanningMap={navigateToPlanningMap}
            navigateToPlanningTimeline={navigateToPlanningTimeline}
            navigateToOrders={navigateToOrders}
            handleSettingMenuOnClick={handleSettingMenuOnClick}
            settingMenuAnchorEl={settingMenuAnchorEl}
            handleSettingMenuOnClose={handleSettingMenuOnClose}
            navigateToGarages={navigateToGarages}
            navigateToTrucks={navigateToTrucks}
            navigateToGroups={navigateToGroups}
            navigateToDrivers={navigateToDrivers}
            navigateToPlaces={navigateToPlaces}
            navigateToAreas={navigateToAreas}
            navigateToConfig={navigateToConfig}
            handleAccountMenuOnClick={handleAccountMenuOnClick}
            accountMenuAnchorEl={accountMenuAnchorEl}
            handleAccountMenuOnClose={handleAccountMenuOnClose}
            navigateToAccounts={navigateToAccounts}
            isAdmin={isAdmin}
            navigateToCompany={navigateToCompany}
            logout={logout}
            useTrunkTransportation={useTrunkTransportation}
            navigateToPlanningTrunkTransportation={navigateToPlanningTrunkTransportation}
          />
        </Toolbar>
      )}
    </AppBar>
  );
});

export default AppBarContainerComponent;
