import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { FC, memo, useCallback, useEffect, useState, MouseEvent, useMemo } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { DeliveryEntity } from 'src/entities/Delivery.entity';
import { DriverEntity } from 'src/entities/Driver.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';
import { useDeliveriesQuery } from 'src/hooks/useDeliveries.query';
import { datetimeUtil } from 'src/utils/datetime.util';
import numberUtil from 'src/utils/number.util';

import { EditPropsBase, RequestEntity } from './EditDialog.presenter';

type Props = {
  dayOfWeek: Date;
  truckData: TruckEntity[];
  driver: DriverEntity;
  isLoading: boolean;
  updateEditPropsBase: (props: EditPropsBase) => void;
  defaultStartAt: Date;
  defaultEndAt: Date;
  defaultWorkingAvailableDurationHours: number | undefined;
  checked: boolean;
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
  appendDeliveryExistingCells: (cells: SelectedShiftCell[]) => void;
}

const BodyCellPresenter: FC<Props> = memo((
  {
    dayOfWeek,
    truckData,
    driver,
    isLoading,
    updateEditPropsBase,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    checked,
    updateSelectedShiftCell,
    appendDeliveryExistingCells,
  }
) => {
  const { data, isFetching: deliveryIsFetching } = useDeliveriesQuery(datetimeDecorator.toYyyyMmDd(dayOfWeek));

  useEffect(() => {
    if (!data) return;

    appendDeliveryExistingCells(data.map((it) => ({ date: dayOfWeek, driver_id: it.driverId }) as SelectedShiftCell));
  }, [appendDeliveryExistingCells, data, dayOfWeek, deliveryIsFetching]);

  const [currentDeliveries, setCurrentDeliveries] = useState<DeliveryEntity[]>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [defaultRequestEntities, setDefaultRequestEntities] = useState<RequestEntity[]>([]);

  const onClickCheckbox = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    updateSelectedShiftCell([{ driver_id: driver.id, date: dayOfWeek }], !checked);
  }, [checked, dayOfWeek, driver.id, updateSelectedShiftCell]);

  const buttonOnClick = useCallback(() => {
    const isNextDay = defaultStartAt.getTime() - defaultEndAt.getTime() > 0;
    defaultStartAt.setFullYear(dayOfWeek.getFullYear(), dayOfWeek.getMonth(), dayOfWeek.getDate());
    defaultEndAt.setFullYear(dayOfWeek.getFullYear(), dayOfWeek.getMonth(), dayOfWeek.getDate());
    const props: EditPropsBase = {
      dialogTitle,
      defaultRequestEntities,
      driver,
      truckData,
      date: dayOfWeek,
      defaultStartAt,
      defaultEndAt: addDays(defaultEndAt, isNextDay ? 1 : 0),
      defaultWorkingAvailableDurationHours,
      shiftCell: [{ driver_id: driver.id, date: dayOfWeek }]
    };

    updateEditPropsBase(props);
  }, [dayOfWeek, defaultEndAt, defaultRequestEntities, defaultStartAt, defaultWorkingAvailableDurationHours, dialogTitle, driver, truckData, updateEditPropsBase]);

  useEffect(() => {
    if (!data) {
      setCurrentDeliveries([]);
      return;
    }

    const sortedDeliveries = [...data.filter((it) => it.driverId === driver.id)].sort((a, b) => {
      const aStart = new Date(a.startAt).getTime();
      const bStart = new Date(b.startAt).getTime();
      return aStart - bStart;
    });

    setCurrentDeliveries(sortedDeliveries);
  }, [data, driver.id]);

  useEffect(() => {
    if (!currentDeliveries) return;
    if (!driver) return;

    const todaysDeliveries = currentDeliveries.filter((it) => datetimeUtil.isSameDay(new Date(it.startAt), dayOfWeek));

    if (!todaysDeliveries.length) {
        const isNextDay = defaultStartAt.getTime() - defaultEndAt.getTime() > 0;
        defaultStartAt.setFullYear(dayOfWeek.getFullYear(), dayOfWeek.getMonth(), dayOfWeek.getDate());
        defaultEndAt.setFullYear(dayOfWeek.getFullYear(), dayOfWeek.getMonth(), dayOfWeek.getDate());
        setDefaultRequestEntities(
        [
          {
            startDate: datetimeDecorator.toYyyyMmDd(defaultStartAt),
            startTime: datetimeDecorator.toHourMinutes(defaultStartAt),
            endDate: datetimeDecorator.toYyyyMmDd(addDays(defaultEndAt, isNextDay ? 1 : 0)),
            endTime: datetimeDecorator.toHourMinutes(defaultEndAt),
            workingAvailableDurationHours: defaultWorkingAvailableDurationHours,
            truckId: driver.defaultTruckId,
          }
        ]
      );
      return;
    }

    const sortedDeliveries = [...todaysDeliveries].sort((a, b) => {
      const aStart = new Date(a.startAt).getTime();
      const bStart = new Date(b.startAt).getTime();
      return aStart - bStart;
    });

    setDefaultRequestEntities(
      sortedDeliveries.map((it) => ({
        ...it,
        startDate: datetimeDecorator.toYyyyMmDd(new Date(it.startAt)),
        startTime: datetimeDecorator.toHourMinutes(new Date(it.startAt)),
        endDate: datetimeDecorator.toYyyyMmDd(new Date(it.endAt)),
        endTime: datetimeDecorator.toHourMinutes(new Date(it.endAt)),
        workingAvailableDurationHours: numberUtil.convertFromSecondsToHours(it.workingAvailableDurationSeconds),
        truckId: it.truckId,
        isAllDay: it.isAllDay,
      }))
    );
  }, [currentDeliveries, driver, defaultStartAt, defaultEndAt, defaultWorkingAvailableDurationHours, dayOfWeek]);

  useEffect(() => {
    if (!driver) return;
    if (!dayOfWeek) return;

    setDialogTitle([
      datetimeDecorator.toMmDd(dayOfWeek),
      driver.name
    ].join(' '));
  }, [driver, dayOfWeek]);

  const newButton = useMemo(() => (driver.isActive ? '新規作成' : '-'), [driver]);

  const [isContinuousWork, setIsContinuousWork] = useState<boolean>(false);

  const textContentsMemo = useMemo(() => (
    currentDeliveries.length ? currentDeliveries.map((it) => {
      const formattedText = () => {
        const { startAt, endAt, isAllDay } = it;
        const startAtDate = new Date(startAt);
        const endAtDate = new Date(endAt);
        const isSameDay = datetimeUtil.isSameDay(startAtDate, endAtDate);
        if (isAllDay) {
          return [
            datetimeDecorator.toMmDd(startAtDate),
            datetimeDecorator.toMmDd(endAtDate),
          ].join(' - ');
        }
        if (isSameDay) {
          return [
            datetimeDecorator.toHourMinutes(startAtDate),
            datetimeDecorator.toHourMinutes(endAtDate),
          ].join(' - ');
        }
        const isStartDay = datetimeUtil.isSameDay(startAtDate, dayOfWeek);
        const isEndDay = datetimeUtil.isSameDay(endAtDate, dayOfWeek);
        return [
          [
            [
              isEndDay ? datetimeDecorator.toMmDd(startAtDate) : null,
              datetimeDecorator.toHourMinutes(startAtDate),
            ].filter((maybe) => maybe).join(' '),
            [
              isStartDay ? datetimeDecorator.toMmDd(endAtDate) : null,
              datetimeDecorator.toHourMinutes(endAtDate),
            ].filter((maybe) => maybe).join(' '),
          ].join(' - ')
        ];
      };

      setIsContinuousWork(false);
      if (currentDeliveries.length === 1) {
        const delivery = currentDeliveries[0];
        if (new Date(delivery.startAt).getTime() < dayOfWeek.getTime()) {
          if (delivery.isAllDay || ((addDays(dayOfWeek, 1).getTime() - 1) < new Date(delivery.endAt).getTime())) {
            setIsContinuousWork(true);
          }
        }
      }

      return (
        <Stack
          key={
            [
              'BodyCellPresenter',
              'Stack',
              it.id
            ].join('-')
          }
        >
          <Typography sx={{ wordBreak: 'keep-all' }}>
            {
              formattedText()
            }
            {
              it.workingAvailableDurationSeconds > 0 && (
                [
                  ' (',
                  numberDecorator.convertToHoursAndMinutes(
                    numberUtil.convertFromSecondsToMinutes(it.workingAvailableDurationSeconds)
                  ),
                  ')'
                ].join('')
              )
            }
          </Typography>
        </Stack>
      );
    }) : newButton
  ), [currentDeliveries, dayOfWeek, newButton]);

  const contentsMemo = useMemo(() => (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      {driver.isActive && (
        <Checkbox
          checked={checked}
          onClick={onClickCheckbox}
        />
      )}
      <Button
        style={{
            display: 'block'
          }}
        size="small"
        color={currentDeliveries.length ? 'primary' : 'secondary'}
        onClick={buttonOnClick}
        disabled={isLoading || !driver.isActive}
      >
        {textContentsMemo}
      </Button>
    </Stack>
  ), [buttonOnClick, checked, currentDeliveries, driver, isLoading, onClickCheckbox, textContentsMemo]);

  if (currentDeliveries.length && isContinuousWork) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="body2">
          連続勤務
        </Typography>
      </Stack>
    );
  }

  return contentsMemo;
});

export default BodyCellPresenter;
