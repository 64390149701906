import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DeliveryOperationRequest } from 'src/entities/DeliveryOperationRequest.entity';
import { PlanningEntity } from 'src/entities/planningEntity';
import { PlanningSettingRequest } from 'src/entities/PlanningHistory.entity';
import { BaseOperationEntity, distanceMmBurdenPerShippersEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { RelaxedRuleVo } from 'src/vo/RelaxedRule.vo';

const requestPath = '/api/v3/plannings';

const buildRelaxParams = (selectedRelaxedRules: RelaxedRuleVo[], activateConstraintLoadAfterNotCleared: boolean, loadAfterInitialStock: boolean) => ({
    overwork: selectedRelaxedRules.includes('労働時間'),
    outofwork: selectedRelaxedRules.includes('労働時間'),
    outofwork_order: selectedRelaxedRules.includes('労働時間'),
    outofwork_load: selectedRelaxedRules.includes('労働時間'),
    outofwork_unload: selectedRelaxedRules.includes('労働時間'),
    driver_rest: selectedRelaxedRules.includes('労働時間'),
    deliver_time: selectedRelaxedRules.includes('指定時間'),
    bigweight: selectedRelaxedRules.includes('重量'),
    weight_sequence: selectedRelaxedRules.includes('重量'),
    bigvolume: selectedRelaxedRules.includes('体積'),
    volume_sequence: selectedRelaxedRules.includes('体積'),
    truck_classes: selectedRelaxedRules.includes('指定車両タイプ'),
    truck_types: selectedRelaxedRules.includes('指定車種'),
    truck_loading_platform_heights: selectedRelaxedRules.includes('荷台高さ'),
    truck_loading_platform_widths: selectedRelaxedRules.includes('荷台幅'),
    truck_loading_platform_lengths: selectedRelaxedRules.includes('荷台長さ'),
    truck_specifications: selectedRelaxedRules.includes('床仕様'),
    truck_features: selectedRelaxedRules.includes('装置'),
    pistons: selectedRelaxedRules.includes('ピストン回数'),
    orders_per_piston: selectedRelaxedRules.includes('ピストン回数'),
    max_num_orders: selectedRelaxedRules.includes('案件数上限'),
    load_after_not_cleared: !activateConstraintLoadAfterNotCleared,
    load_after_initial_stock: loadAfterInitialStock,
    mixed_items: selectedRelaxedRules.includes('積み合わせ'),
    mixed_loading: selectedRelaxedRules.includes('積み合わせ'),
    must_trucks: selectedRelaxedRules.includes('指定トラック'),
    ng_drivers: selectedRelaxedRules.includes('NGドライバー'),
    pistons_truck: selectedRelaxedRules.includes('トラックの上限ピストン回数'),
    locations_per_piston_truck: selectedRelaxedRules.includes('１回転あたりの上限納品先件数'),
    unloading_areas: selectedRelaxedRules.includes('エリア配車'),
  });

const converToRequest = (planningSetting: PlanningSettingRequest) => {
  if (!planningSetting) return null;

  const { deliveryConditions, orderConditions, planningConditions } = planningSetting;
  return {
    delivery_conditions: deliveryConditions,
    order_conditions: orderConditions,
    planning_conditions: planningConditions,
  };
};

const convertToRequestBody = (
    shiftIds:number[],
    orderIds:number[],
    planning: PlanningEntity,
    latestAlgorithmRequestVersion: number,
    requestAsync: boolean,
    selectedCycleIndexes: SelectedCycle[],
    deliveryOperations: DeliveryOperationRequest[],
    transferFromShiftId?: number,
    planningSetting?: PlanningSettingRequest,
  ) => {
  const { priorityLargeTrucks } = planning;
  const prioritySmallTrucks = 1.0 - priorityLargeTrucks;

  const request = {
    algo_preset: 'opt_center',
    osrm_exclude_toll: planning.excludeToll,
    lock_type: planning.lockType,
    shift_ids: shiftIds,
    order_ids: orderIds,
    respect_recent_plan: planning.isRespectRecent,
    start_on: planning.startOn,
    end_on: planning.endOn,
    respect_type: planning.respectType,
    respect_days_of_week: planning.respectDaysOfWeek,
    respect_date: planning.respectOn,
    time_table_backward: planning.isTimeTableBackward,
    include_future_deliveries: planning.includeFutureDeliveries,
    activate_constraint_load_after_not_cleared: planning.activateConstraintLoadAfterNotCleared,
    order_operation_ids_for_sort: planning.orderOperationIdsForSort,
    delete_order_ids_from_operations: planning.deleteOrderIdsFromOperations,
    relax_constraints: buildRelaxParams(planning.selectedRelaxedRules, planning.activateConstraintLoadAfterNotCleared, planning.loadAfterInitialStock),
    priority_large_trucks: priorityLargeTrucks,
    priority_small_trucks: prioritySmallTrucks,
    timeline_edit_deliveries: planning.timelineEditDeliveries,
    latest_algorithm_request_version: latestAlgorithmRequestVersion,
    expand_working_time_range_start: planning.expandWorkingStartTime,
    expand_working_time_range_end: planning.expandWorkingEndTime,
    expand_load_start_time: planning.expandLoadStartTime,
    expand_load_end_time: planning.expandLoadEndTime,
    expand_unload_start_time: planning.expandUnloadStartTime,
    expand_unload_end_time: planning.expandUnloadEndTime,
    expand_max_volume_rate: planning.expandMaxVolumeRate,
    expand_max_load_capacity_rate: planning.expandMaxLoadCapacityRate,
    request_async: requestAsync,
    ml_source_type: planning.mlSourceType,
    balanced_loading: planning.balancedLoading,
    transfer_from_shift_id: transferFromShiftId,
    selected_cycle_indexes: selectedCycleIndexes,
    delivery_operations: deliveryOperations,
    order_operation_cycle_indexes: planning.orderOperationCycleIndexes,
    planning_setting: converToRequest(planningSetting),
    auto_split_orders: planning.autoSplitOrders,
    preroute_exchangeable: !planning.dontExchangePreroute,
    concurrent_all_or_nothing: planning.concurrentAllOrNothing,
    ml_planning: planning.mlPlanning,
    optimize_for_load: planning.optimizeForLoad,
    force_null_base: planning.forceNullBase,
    set_current_time_to_algorithm: planning.setCurrentTimeToAlgorithm,
    trunk_transportation: planning.trunkTransportation,
  };

  if (planning.selectedRelaxedRules.includes('1回転あたりの降地')) {
    return {
      ...request,
      relax_max_num_orders_per_piston: true
    };
  }

  return request;
};

export const useScenarioPlanning = () => ({
  scenarioPlanning: useMutation((
    { scenarioId, startOn, endOn, }:
    { scenarioId: number, startOn: string, endOn: string }
  ) => axios.post(
      '/api/v4/plannings',
      {
        scenario_id: scenarioId,
        start_on: startOn,
        end_on: endOn,
      }
    )),
});

export const useMutationPlanning = () => ({
  rollback: useMutation(
    (
      {
        deliveryIds,
        orderOperations,
        arrivalOperations,
        departureOperations,
        distanceMmBurdenPerShippersEntities,
        latestAlgorithmRequestVersion,
        startOn,
        endOn,
      }: {
        deliveryIds: number[];
        orderOperations: PlanningsOperationEntity[];
        arrivalOperations: BaseOperationEntity[];
        departureOperations: BaseOperationEntity[];
        distanceMmBurdenPerShippersEntities: distanceMmBurdenPerShippersEntity[];
        latestAlgorithmRequestVersion: number;
        startOn: string;
        endOn: string;
      }
    ) => axios
      .post(
        '/api/v3/plannings/rollback',
        {
          delivery_ids: deliveryIds,
          plannings_operation_entities: orderOperations,
          distance_mm_burden_per_shippers: distanceMmBurdenPerShippersEntities,
          arrival_operations: arrivalOperations,
          departure_operations: departureOperations,
          latest_algorithm_request_version: latestAlgorithmRequestVersion,
          start_on: startOn,
          end_on: endOn,
        }
      ),
  ),
  swapPlanning: useMutation(
    (
      {
        deliveryIds,
        latestAlgorithmRequestVersion,
        startOn,
        endOn
      }: {
        deliveryIds: [number, number];
        latestAlgorithmRequestVersion: number;
        startOn: string;
        endOn: string;
      }
    ) => axios
      .post('/api/v3/deliveries/swapping', {
        delivery_ids: deliveryIds,
        latest_algorithm_request_version: latestAlgorithmRequestVersion,
        start_on: startOn,
        end_on: endOn,
      })
  ),
  transferPlanning: useMutation(
    ({ orderIds, planning, transferToShiftId, latestAlgorithmRequestVersion, transferFromShiftId }: {
      transferToShiftId: number;
      orderIds: number[];
      planning: PlanningEntity;
      latestAlgorithmRequestVersion: number;
      transferFromShiftId: number;
    }) => axios.post(requestPath, convertToRequestBody([transferToShiftId], orderIds, planning, latestAlgorithmRequestVersion, true, [], [], transferFromShiftId))
  ),
  normalPlanning: useMutation(
    ({ orderIds, planning, shiftIds, latestAlgorithmRequestVersion, requestAsync, selectedCycleIndexes, deliveryOperations, planningSetting }: {
      shiftIds: number[],
      orderIds: number[],
      planning: PlanningEntity,
      latestAlgorithmRequestVersion: number,
      requestAsync: boolean,
      selectedCycleIndexes: SelectedCycle[],
      deliveryOperations: DeliveryOperationRequest[];
      planningSetting: PlanningSettingRequest;
    }) => axios
      .post(
        requestPath,
        convertToRequestBody(shiftIds, orderIds, planning, latestAlgorithmRequestVersion, requestAsync, selectedCycleIndexes, deliveryOperations, null, planningSetting)
      )
  ),
  manualPlanning: useMutation(
    ({ startOn, endOn, timelineEditDeliveries, timelineEditBaseOperations }: {
      startOn: string, endOn: string, timelineEditDeliveries: unknown, timelineEditBaseOperations: unknown
    }) => axios.post(requestPath, {
        planningMethod: 'manual',
        start_on: startOn,
        end_on: endOn,
        timelineEditDeliveries,
        timelineEditBaseOperations,
      })
  ),
});
