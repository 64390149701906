import { subSeconds } from 'date-fns';
import { OrderEntity } from 'src/entities/orderEntity';
import { datetimeUtil } from 'src/utils/datetime.util';

export class OrderModel {
  id: number;

  shipperName: string; // 荷主名

  shipperEmailAddress?: string; // 荷主メールアドレス

  shipperPhoneNumber?: string; // 荷主電話番号

  code?: string; // 注文コード

  memo?: string; // 備考

  designatedTruckCarModels?: (
    | '平ボディ'
    | 'バンボディ'
    | 'ウィングボディ'
    | '保冷車'
    | '冷凍車'
    | '車載車'
    | '重機運搬車'
    | '危険物運搬車'
    | 'ダンプ'
    | '幌'
    | 'ユニック'
    | '海上コンテナー用'
    )[]; // 指定車種

  designatedTruckFeatures?: (
    | 'パワーゲート'
    | '空調'
    | 'エアサス'
    | 'スタンション'
    )[]; // 指定装置・特徴

  designatedTruckFloorSpecifications?: (
    | '鉄板'
    | 'ステンレス'
    | 'ジョルダー'
    | '縞板'
    )[]; // 床仕様

  designatedTruckLoadingPlatformHeights?: ('標準' | '低床' | '中低床')[]; // 荷台高

  designatedTruckLoadingPlatformLengths?: (
    | '大型'
    | '中型'
    | '小型'
    | 'ショート'
    | 'ロング'
    )[]; // 荷台長

  designatedTruckLoadingPlatformWidths?: (
    | '標準'
    | 'セミワイド'
    | 'ワイド'
    )[]; // 荷台幅

  designatedTruckKlasses?: (
    | '軽貨物'
    | 'トラック'
    | 'セミトレーラー'
    | 'ポールトレーラー'
    | 'フルトレーラー'
    )[]; // 車両タイプ

  chargeLoadingFeeYen: number; // 積込料金

  chargeAncillaryContent?: string; // 付帯作業備考

  chargeAncillaryFeeYen: number; // 付帯作業料金

  chargeBasicFeeYen: number; // 基本運賃

  chargeExpensesFeeYen: number; // 諸経費

  chargeHighwayFeeYen: number; // 高速代金

  chargeUnloadingFeeYen: number; // 取降料金

  chargeWaitingTimeFeeYen: number; // 待機料金

  itemCount: number; // 数量

  itemCanBeMixed?: boolean; // 混載可否

  itemHandlingOfCargoStyle?:
    | '手積'
    | '機械積'
    | '機械積（フォーク）'
    | '機械積（クレーン）'; // 荷扱い

  itemHeightCm: number; // 高さ

  itemKlass?:
    | '農産物'
    | '畜産物'
    | '水産物'
    | '食料品'
    | '飲料品'
    | '木材'
    | '砂利・砂等'
    | '金属製品'
    | '銅材'
    | '建材'
    | '電気製品'
    | '機械・装置'
    | 'セメント'
    | 'セメント製品'
    | '紙・パルプ製品'
    | '石油製品'
    | '化学製品'
    | 'その他危険物'
    | '衣料・雑貨'
    | '引越貨物'; // 輸送区分

  itemName?: string; // 品名

  itemPackingStyle?:
    | 'ケース'
    | '袋'
    | 'はだか'
    | 'フレコンバック'
    | 'ドラム類'
    | '缶類'
    | 'パレット'
    | 'ラック'
    | 'ばら'; // 荷姿

  itemTotalVolumeM3: number; // 総体積

  itemTotalWeightKg: number; // 総重量

  itemVerticalWidthCm: number; // 幅

  itemWidthCm: number; // 横幅

  loadingName: string; // 積地名称

  loadingAddress: string; // 積地住所

  loadingEndAt: Date; // 積地締切日時

  loadingStartAt: Date; // 積地受付開始日時

  loadingStayingSeconds?: number; // 積地作業秒

  loadingLat?: number; // 積地緯度

  loadingLng?: number; // 積地経度

  unloadingName: string; // 降地名称

  unloadingAddress: string; // 降地住所

  unloadingEndAt: Date; // 降地締切日時

  unloadingStartAt: Date; // 降地受付開始日時

  unloadingStayingSeconds?: number; // 降地作業秒

  unloadingLat?: number; // 降地緯度

  unloadingLng?: number; // 降地経度

  unit: '才' | 'kg';

  constructor(entity: OrderEntity) {
    this.id = entity.id;
    this.shipperName = entity.shipper_name;
    this.shipperEmailAddress = entity.shipper_email_address;
    this.shipperPhoneNumber = entity.shipper_phone_number;
    this.code = entity.code;
    this.memo = entity.memo;
    this.designatedTruckCarModels = entity.designated_truck_car_models;
    this.designatedTruckFeatures = entity.designated_truck_features;
    this.designatedTruckFloorSpecifications = entity.designated_truck_floor_specifications;
    this.designatedTruckLoadingPlatformHeights = entity.designated_truck_loading_platform_heights;
    this.designatedTruckLoadingPlatformLengths = entity.designated_truck_loading_platform_lengths;
    this.designatedTruckLoadingPlatformWidths = entity.designated_truck_loading_platform_widths;
    this.designatedTruckKlasses = entity.designated_truck_klasses;
    this.chargeLoadingFeeYen = entity.charge_loading_fee_yen;
    this.chargeAncillaryContent = entity.charge_ancillary_content;
    this.chargeAncillaryFeeYen = entity.charge_ancillary_fee_yen;
    this.chargeBasicFeeYen = entity.charge_basic_fee_yen;
    this.chargeExpensesFeeYen = entity.charge_expenses_fee_yen;
    this.chargeHighwayFeeYen = entity.charge_highway_fee_yen;
    this.chargeUnloadingFeeYen = entity.charge_unloading_fee_yen;
    this.chargeWaitingTimeFeeYen = entity.charge_waiting_time_fee_yen;
    this.itemCount = entity.item_count;
    this.itemCanBeMixed = entity.item_can_be_mixed;
    this.itemHandlingOfCargoStyle = entity.item_handling_of_cargo_style;
    this.itemHeightCm = entity.item_height_cm;
    this.itemKlass = entity.item_klass;
    this.itemName = entity.item_name;
    this.itemPackingStyle = entity.item_packing_style;
    this.itemTotalVolumeM3 = entity.item_total_volume_m3;
    this.itemTotalWeightKg = entity.item_total_weight_kg;
    this.itemVerticalWidthCm = entity.item_vertical_width_cm;
    this.itemWidthCm = entity.item_width_cm;
    this.loadingName = entity.loading_name;
    this.loadingAddress = entity.loading_address;
    this.loadingEndAt = new Date(entity.loading_end_at);
    this.loadingStartAt = new Date(entity.loading_start_at);
    this.loadingStayingSeconds = entity.loading_staying_seconds;
    this.loadingLat = entity.loading_lat;
    this.loadingLng = entity.loading_lng;
    this.unloadingName = entity.unloading_name;
    this.unloadingAddress = entity.unloading_address;
    this.unloadingEndAt = new Date(entity.unloading_end_at);
    this.unloadingStartAt = new Date(entity.unloading_start_at);
    this.unloadingStayingSeconds = entity.unloading_staying_seconds;
    this.unloadingLat = entity.unloading_lat;
    this.unloadingLng = entity.unloading_lng;
    this.unit = entity.unit;
  }

   // 積地時間表示形式
  loadingDateFormat(): string {
    return OrderModel.loadingDateFormat({ start: this.loadingStartAt, end: this.loadingEndAt });
  }

  // 降地時間表示形式
  unloadingDateFormat(): string {
    return OrderModel.loadingDateFormat({ start: this.unloadingStartAt, end: this.unloadingEndAt });
  }

  private static loadingDateFormat({ start, end }: { start: Date; end: Date; }): string {
    return (datetimeUtil.isSameDay(start, end)) ? 'HH:mm' : 'MM/dd HH:mm';
  }

  toSearchKw() {
    return [
      this.shipperName,
      this.shipperEmailAddress,
      this.shipperPhoneNumber,
      this.code,
      this.memo,
      this.designatedTruckCarModels.join(' '),
      this.designatedTruckFeatures.join(' '),
      this.designatedTruckFloorSpecifications.join(' '),
      this.designatedTruckLoadingPlatformHeights.join(' '),
      this.designatedTruckLoadingPlatformLengths.join(' '),
      this.designatedTruckLoadingPlatformWidths.join(' '),
      this.designatedTruckKlasses.join(' '),
      this.itemHandlingOfCargoStyle,
      this.itemKlass,
      this.itemName,
      this.itemPackingStyle,
      this.loadingName,
      this.loadingAddress,
      this.unloadingName,
      this.unloadingAddress,
    ].filter((maybe) => !!maybe).join(' ');
  }

  geocodes() {
    return [
      { lat: this.loadingLat, lng: this.loadingLng },
      { lat: this.unloadingLat, lng: this.unloadingLng },
    ];
  }

  totalEarningsYen(): number {
    return [
      this.chargeLoadingFeeYen,
      this.chargeAncillaryFeeYen,
      this.chargeBasicFeeYen,
      this.chargeExpensesFeeYen,
      this.chargeHighwayFeeYen,
      this.chargeUnloadingFeeYen,
      this.chargeWaitingTimeFeeYen
    ].filter((maybe) => maybe)
      .reduce((prev, current) => prev + current, 0);
  }

  receptionEndAt(action: ('積'|'降')): Date {
    if (action === '積') {
      return this.loadingEndAt;
    }

    return this.unloadingEndAt;
  }

  workingSeconds(action: ('積'|'降')): number {
    if (action === '積') {
      return this.loadingStayingSeconds || (5 * 60);
    }

    return this.unloadingStayingSeconds || (10 * 60);
  }

  arrivalDeadlineAt(action: ('積'|'降')): Date {
    const endAt = this.receptionEndAt(action);

    const workingSeconds = this.workingSeconds(action);

    return subSeconds(endAt, workingSeconds);
  }
}
