import 'leaflet/dist/leaflet.css';
import './react-mosaic-component.css';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import {
  Backdrop,
  Box, Button, Chip, CircularProgress, Dialog, DialogContent, Divider, IconButton,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Snackbar,
  SnackbarContent,
  Stack, Typography, useTheme
} from '@mui/material';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useNavigate } from 'react-router';
import DirectionSettingDialog from 'src/components/DirectionSettingDialog';
import { actionBarHeight, appBarHeight } from 'src/constants/layout';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { GarageEntity } from 'src/entities/Garage.entity';
import {
  PlanningMapUnallocatedOrderPositionEntity
} from 'src/entities/import/PlanningMapUnallocatedOrderPosition.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { OrderSearchConditionEntity } from 'src/entities/OrderSearchCondition.entity';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { RespectType, WeekDayEn } from 'src/entities/planningEntity';
import { PlanningOrderStatisticsEntity } from 'src/entities/PlanningOrderStatistics.entity';
import { AsyncPlanningResponseEntity } from 'src/entities/planningResponseEntity';
import { PlanningsDeliveryEntity, PlanningsDeliveryWithTruckAndDriverEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsMapGaragePositionEntity } from 'src/entities/PlanningsMapGaragePosition.entity';
import { PlanningsMapOperationPositionEntity } from 'src/entities/PlanningsMapOperationPosition.entity';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity,
  PlanningsOperationEntitiesWithStatsByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningRunningEntity } from 'src/entities/PlanningsRunning.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import { EstimationMethodVo } from 'src/vo/EstimationMethod.vo';
import { PlanningViewKind } from 'src/vo/PlanningViewKInd.vo';
import { RelaxedRuleVo } from 'src/vo/RelaxedRule.vo';
import { TruckDisplayStatusVo } from 'src/vo/TruckDisplayStatusVo';

import V2OrdersPresenter from '../V2OrdersPresenter';

import EstimationByDeliveriesComponent from './components/EstimationByDeliveries.component';
import ActionsOnMapPresenter from './presenters/ActionsOnMapPresenter';
import ActionsPresenter from './presenters/ActionsPresenter';
import AllocateHistoryPresenter from './presenters/AllocateHistoryPresenter';
import MapFunctionPresenter from './presenters/MapFunctionPresenter';
import OrderPolylinesPresenter from './presenters/OrderPolylinesPresenter';
import OrdersPresenter from './presenters/OrdersPresenter';
import PlanningMapCurrentPositionsPresenter from './presenters/PlanningMapCurrentPositions.presenter';
import PlanningMapDeliveriesPolylinesPresenter from './presenters/PlanningMapDeliveriesPolylines.presenter';
import PlanningMapGarageMarkersPresenter from './presenters/PlanningMapGarageMarkers.presenter';
import PlanningMapOperationMarkersPresenter from './presenters/PlanningMapOperationMarkers.presenter';
import PlanningMapOrderMarkersPresenter from './presenters/PlanningMapOrderMarkers.presenter';
import PlanningOrderPresenter from './presenters/PlanningOrder.presenter';
import PlanningPresenter from './presenters/PlanningPresenter';
import PlanningTrucksPresenter from './presenters/PlanningTrucks.presenter';
import { ScenarioPlanningListDialog } from './presenters/ScenarioPlanningListDialog';
import SelectImportMethodPresenter from './presenters/SelectImportMethodPresenter';
import SelectPrintPresenter from './presenters/SelectPrintPresenter';

type Props = {
  startOn: string;
  endOn: string;
  orderIds: number[];
  selectedOrderIds: number[];
  addSelectedOrderId: (id: number) => void;
  removeSelectedOrderId: (id: number) => void;
  unit: string;
  isLoading: boolean;
  updateBalancedLoading: (balancedLoading: boolean) => void;
  updateIsRespectRecent: (bool: boolean) => void;
  updateExcludeToll: (bool: boolean) => void;
  mutateDeleteShiftsOperations: (deliveryIds: number[]) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  planningRequest: (shiftIds: number[], orderIds: number[]) => void;
  transferRequest: (transferToShiftIds: number[], requestOrderIds: number[]) => void;
  transfer: TransferRequestEntity;
  setTransfer: (entity: TransferRequestEntity) => void;
  resetTransfer: () => void;
  mutateDeleteOrder: () => void;
  mutateDeleteSpecificOrder: (orderId: number) => void;
  mutateCloneOrder: (order: OrderEntity) => void;
  setImportFile: (file: File) => void;
  setImportForUpdateFile: (file: File) => void;
  mutateCloneLastWeekShifts: (date: string) => void;
  displayOrderId: number | undefined;
  resetDisplayOrderId: () => void;
  updateDisplayOrderId: (orderId: number) => void;
  planningMapUnallocatedOrderPositionEntities: PlanningMapUnallocatedOrderPositionEntity[];
  garageData: GarageEntity[];
  truckEntities: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  operationEntities: PlanningsOperationEntity[];
  orderEntityMap: Map<number, OrderEntity>;
  swapRequest: (swapFromDeliveryId: number, swapToDeliveryId: number) => void;
  planningOrderStatisticsEntity: PlanningOrderStatisticsEntity | undefined;
  groupEntities: PlanningsGroupEntity[];
  selectedGroupEntities: PlanningsGroupEntity[] | undefined;
  updateSelectedGroupEntities: (entities: PlanningsGroupEntity[] | undefined) => void;
  planningsMapGaragePositionEntities: PlanningsMapGaragePositionEntity[];
  planningsMapOperationPositionEntities: PlanningsMapOperationPositionEntity[];
  planningsOperationEntitiesWithStatsByDeliveryIdEntity: PlanningsOperationEntitiesWithStatsByDeliveryIdEntity
  updateRespectType: (respectType: RespectType) => void;
  updateRespectDaysOfWeek: (days: WeekDayEn[]) => void;
  updateRespectOn: (on: string) => void;
  updateIncludeFutureDeliveries: (bool: boolean) => void;
  updateAutoSplitOrders: (bool: boolean) => void;
  updateDontExchangePreroute: (bool: boolean) => void;
  updateConcurrentAllOrNothing: (bool: boolean) => void;
  updateMLPlanning: (bool: boolean) => void;
  calculating: boolean;
  updateIsTimeTableBackward: (bool: boolean) => void;
  driverCostYenPerHours: number | undefined;
  truckFuelCostYenPerKm: number | undefined;
  truckInsuranceFeeYenPerDay: number | undefined;
  truckRepairCostYenPerDay: number | undefined;
  truckExpresswayFeeYenPerShift: number | undefined;
  sendOperationMail: () => void;
  refreshOperationStatuses: () => void;
  lastOperationStatusUpdated: string;
  resetDrivers: () => void;
  flyToPosition: PositionEntity | undefined;
  resetFlyToPosition: () => void;
  fitBoundsPositions: PositionEntity[];
  resetFitBoundsPositions: () => void;
  allocateHistories: AllocateHistoryEntity[];
  currentHistoryVersion: string | undefined;
  setCurrentHistoryVersion: (version: string) => void;
  resetCurrentHistoryVersion: () => void;
  resetAllocateHistories: () => void;
  rollbackRequest: () => void;
  customInputFields: string[];
  relaxedRules: RelaxedRuleVo[];
  selectedRelaxedRules: RelaxedRuleVo[];
  setSelectedRelaxedRules: (value: RelaxedRuleVo[]) => void;
  setPriorityLargeTrucks: (priority: number) => void;
  priorityLargeTrucks: number;
  notAllocReasons: PlanningsNotAllocReason[];
  updateExpandWorkingStartMinutes: (minutes: number) => void;
  updateExpandWorkingEndMinutes: (minutes: number) => void;
  updateExpandLoadStartMinutes: (minutes: number) => void;
  updateExpandLoadEndMinutes: (minutes: number) => void;
  updateExpandUnloadStartMinutes: (minutes: number) => void;
  updateExpandUnloadEndMinutes: (minutes: number) => void;
  updateExpandMaxVolumeRate: (rate: number) => void;
  updateExpandMaxLoadCapacityRate: (rate: number) => void;
  pastOperationsData: undefined | PastOperationAndDriverIdEntity[];
  latestAllocateHistory: AllocateHistoryEntity | undefined;
  directionSettingDialogIsOpen: boolean;
  updateDirectionSettingDialogIsOpen: (bool: boolean) => void;
  planningRunningEntities: PlanningRunningEntity[];
  refreshRunningEntities: () => void;
  cancelRunningPlan: (id: number) => void;
  updateMLSourceType: (mlSourceTypes: number) => void;
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  editPlaces: EditPlaceEntity[];
  resetEditPlaces: () => void;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  addEmptyCycle: (deliveryId: number) => void;
  removeEmptyCycle: (deliveryId: number, cycleIndex: number) => void;
  truckDisplayStatus: TruckDisplayStatusVo;
  setTruckDisplayStatus: (stauts: TruckDisplayStatusVo) => void;
  orderSearchKw: string;
  setOrderSearchKw: (kw: string) => void;
  currentlyOrderSearching: boolean;
  setCurrentlyOrderSearching: (searching: boolean) => void;
  setOrderSearchConditions: (conditions: OrderSearchConditionEntity[]) => void;
  setAllDeliverySelected: (selected: boolean) => void;
  requestScenarioPlanning: (scenarioId: number) => void;
  asyncPlanningResponse: AsyncPlanningResponseEntity;
  mutateRestoreSplittedOrder: (orderId: number) => void;
  mutateRestoreSplittedOrders: () => void;
  updateOptimizeForLoad: (bool: boolean) => void;
  updateForceNullBase: (bool: boolean) => void;
  updateTrunkTransportation: (bool: boolean) => void;
  currentView: PlanningViewKind;
  updateView: (val: PlanningViewKind) => void;
  requestForcePlanning: (delivery: PlanningsDeliveryWithTruckAndDriverEntity, orderId: number) => void;
  resetQuery: () => void;
  forcePlanningQueue: { delivery: PlanningsDeliveryWithTruckAndDriverEntity, orderId: number, latestAlgorithmRequestVersion: number }[];
  currentForcePlanning: { delivery: PlanningsDeliveryWithTruckAndDriverEntity, orderId: number, latestAlgorithmRequestVersion: number };
}

const Presenter: FC<Props> = memo((
  {
    startOn,
    endOn,
    orderIds,
    unit,
    isLoading,
    selectedOrderIds,
    addSelectedOrderId,
    removeSelectedOrderId,
    updateRespectType,
    updateRespectDaysOfWeek,
    updateRespectOn,
    updateBalancedLoading,
    updateIsRespectRecent,
    updateExcludeToll,
    updateIncludeFutureDeliveries,
    updateAutoSplitOrders,
    updateDontExchangePreroute,
    updateConcurrentAllOrNothing,
    updateMLPlanning,
    planningRequest,
    mutateDeleteShiftsOperations,
    mutateDeleteOrdersOperations,
    transferRequest,
    transfer,
    setTransfer,
    resetTransfer,
    mutateDeleteOrder,
    mutateDeleteSpecificOrder,
    mutateCloneOrder,
    setImportFile,
    setImportForUpdateFile,
    mutateCloneLastWeekShifts,
    displayOrderId,
    resetDisplayOrderId,
    updateDisplayOrderId,
    planningMapUnallocatedOrderPositionEntities,
    garageData,
    truckEntities,
    driverEntities,
    deliveryEntities,
    operationEntities,
    orderEntityMap,
    swapRequest,
    planningOrderStatisticsEntity,
    groupEntities,
    selectedGroupEntities,
    updateSelectedGroupEntities,
    planningsMapGaragePositionEntities,
    planningsMapOperationPositionEntities,
    planningsOperationEntitiesWithStatsByDeliveryIdEntity,
    calculating,
    updateIsTimeTableBackward,
    driverCostYenPerHours,
    truckFuelCostYenPerKm,
    truckInsuranceFeeYenPerDay,
    truckRepairCostYenPerDay,
    truckExpresswayFeeYenPerShift,
    sendOperationMail,
    refreshOperationStatuses,
    lastOperationStatusUpdated,
    resetDrivers,
    flyToPosition,
    resetFlyToPosition,
    fitBoundsPositions,
    resetFitBoundsPositions,
    allocateHistories,
    currentHistoryVersion,
    setCurrentHistoryVersion,
    resetCurrentHistoryVersion,
    resetAllocateHistories,
    rollbackRequest,
    customInputFields,
    relaxedRules,
    selectedRelaxedRules,
    setSelectedRelaxedRules,
    setPriorityLargeTrucks,
    priorityLargeTrucks,
    notAllocReasons,
    updateExpandWorkingStartMinutes,
    updateExpandWorkingEndMinutes,
    updateExpandLoadStartMinutes,
    updateExpandLoadEndMinutes,
    updateExpandUnloadStartMinutes,
    updateExpandUnloadEndMinutes,
    updateExpandMaxVolumeRate,
    updateExpandMaxLoadCapacityRate,
    pastOperationsData,
    latestAllocateHistory,
    directionSettingDialogIsOpen,
    updateDirectionSettingDialogIsOpen,
    planningRunningEntities,
    refreshRunningEntities,
    cancelRunningPlan,
    updateMLSourceType,
    requestSingleAlgorithmPlanning,
    selectedCycleIndexes,
    updateSelectedCycleIndexes,
    planningsOperationDeliveryByDeliveryIdEntity,
    editPlaces,
    resetEditPlaces,
    updateEditPlaces,
    addEmptyCycle,
    removeEmptyCycle,
    truckDisplayStatus,
    setTruckDisplayStatus,
    orderSearchKw,
    setOrderSearchKw,
    currentlyOrderSearching,
    setCurrentlyOrderSearching,
    setOrderSearchConditions,
    setAllDeliverySelected,
    requestScenarioPlanning,
    asyncPlanningResponse,
    mutateRestoreSplittedOrder,
    mutateRestoreSplittedOrders,
    updateOptimizeForLoad,
    updateForceNullBase,
    updateTrunkTransportation,
    currentView,
    updateView,
    requestForcePlanning,
    resetQuery,
    forcePlanningQueue,
    currentForcePlanning
  }
) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const licenseContext = useContext(LicenseContext);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const contentHeight = useMemo(() => `calc(100vh - ${appBarHeight + actionBarHeight}px)`, []);

  const selectedDeliveryIds = useMemo(() => selectedCycleIndexes.map((it) => it.deliveryId), [selectedCycleIndexes]);
  const deliveryDrawerWidthInitialState = 430 as const;
  const [deliveryDrawerWidth, setDeliveryDrawerWidth] = useState<number>(deliveryDrawerWidthInitialState);
  const [deliveryDrawerIsOpen, setDeliveryDrawerIsOpen] = useState<boolean>(true);
  const openDeliveryDrawer = useCallback(() => {
    setDeliveryDrawerIsOpen(true);
  }, []);
  const closeDeliveryDrawer = useCallback(() => {
    setDeliveryDrawerIsOpen(false);
  }, []);
  useEffect(() => {
    setDeliveryDrawerWidth(deliveryDrawerIsOpen ? deliveryDrawerWidthInitialState : 0);
  }, [deliveryDrawerIsOpen]);

  const [showTruckStatus, setShowTruckStatus] = useState(false);

  const unallocatedDrawerWidthInitialState = 300 as const;
  const [unallocatedDrawerWidth, setUnallocatedDrawerWidth] = useState<number>(unallocatedDrawerWidthInitialState);
  const [unallocatedDrawerIsOpen, setUnallocatedDrawerIsOpen] = useState<boolean>(true);
  const openUnallocatedDrawer = useCallback(() => {
    setUnallocatedDrawerIsOpen(true);
  }, []);
  const closeUnallocatedDrawer = useCallback(() => {
    setUnallocatedDrawerIsOpen(false);
  }, []);
  useEffect(() => {
    setUnallocatedDrawerWidth(unallocatedDrawerIsOpen ? unallocatedDrawerWidthInitialState : 0);
  }, [unallocatedDrawerIsOpen]);

  const [allocatedHistoriesDialogIsOpen, setAllocatedHistoriesDialogIsOpen] = useState<boolean>(false);
  const allocateHistoriesOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '元に戻す' });
    setAllocatedHistoriesDialogIsOpen(true);
  }, []);

  const [displayOrderDialogIsOpen, setDisplayOrderDialogIsOpen] = useState(false);
  useEffect(() => {
    setDisplayOrderDialogIsOpen(!!displayOrderId);
  }, [displayOrderId]);

  const [transferDialogIsOpen, setTransferDialogIsOpen] = useState<boolean>(false);
  const openTransferDialog = useCallback((entity: TransferRequestEntity) => {
    setTransfer(entity);
    setTransferDialogIsOpen(true);
  }, [setTransfer]);
  const transferDialogOnClose = useCallback(() => {
    resetTransfer();
    setTransferDialogIsOpen(false);
  }, [resetTransfer]);

  const [planningDialogIsOpen, setPlanningDialogIsOpen] = useState<boolean>(false);
  const planningButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '自動配車' });
    const targets = [
      selectedDeliveryIds.length === 0 ? '車両' : '',
    ].filter((it) => it);
    if (targets.length > 0) {
      closeSnackbar();
      const message = `配車する${targets.join('・')}を選択してください。`;
      ReactGA.event('error', { screen_name: SCREEN_NAMES.PLANNING, button_name: '自動配車', label: message });
      enqueueSnackbar(message);
      return;
    }
    setPlanningDialogIsOpen(true);
  }, [closeSnackbar, enqueueSnackbar, selectedDeliveryIds.length]);

  const planningDialogOnClose = useCallback(() => {
    setPlanningDialogIsOpen(false);
  }, []);

  const [selectImportMethodDialogIsOpen, setSelectImportMethodDialogIsOpen] = useState<boolean>(false);
  const selectImportMethodButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'インポート' });
    setSelectImportMethodDialogIsOpen(true);
  }, []);
  const selectImportMethodDialogOnClose = useCallback(() => {
    ReactGA.event('close', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'インポート' });
    setSelectImportMethodDialogIsOpen(false);
  }, []);

  const importButtonOnClick = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setImportFile(event.target.files[0]);
    setSelectImportMethodDialogIsOpen(false);
  }, [setImportFile]);

  const customImportButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_ORDER_IMPORT, button_name: 'カスタムフォーマット' });
    navigate('/import');
  }, []);

  const importForUpdateButtonOnClick = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setImportForUpdateFile(event.target.files[0]);
    setSelectImportMethodDialogIsOpen(false);
  }, [setImportForUpdateFile]);

  const [selectPrintDialogIsOpen, setSelectPrintDialogIsOpen] = useState<boolean>(false);
  const selectPrintButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '出力' });
    setSelectPrintDialogIsOpen(true);
  }, []);

  const selectPrintDialogClose = () => {
    ReactGA.event('close', { screen_name: SCREEN_NAMES.PLANNING, button_name: '出力' });
    setSelectPrintDialogIsOpen(false);
  };

  const [allocateHistoriesForCompany, setAllocateHistoriesForCompany] = useState<AllocateHistoryEntity[]>([]);
  useEffect(() => {
    const list = allocateHistories.filter((it) => it.companyId === licenseContext.config?.selected_company_id);
    setAllocateHistoriesForCompany(list);
  }, [allocateHistories, licenseContext.config?.selected_company_id]);

  const printAllTruckDirectionsOnClick = useCallback(() => {
    ReactGA.event('print', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'すべての配車表' });
    const selectedShiftIds = deliveryEntities.filter((it) => selectedDeliveryIds.includes(it.id)).map((it) => it.shiftId);
    const allShiftIds = deliveryEntities.map((it) => it.shiftId);
    const ids = selectedShiftIds.length
      ? selectedShiftIds
      : allShiftIds;
    const asidePath = `/truck-directions/?ids=${ids.join(',')}`;

    window.open(asidePath);
  }, [deliveryEntities, selectedDeliveryIds]);

  const printUnloadTruckDirectionsOnClick = useCallback(() => {
    ReactGA.event('print', { screen_name: SCREEN_NAMES.PLANNING, button_name: '納品のみの配車表' });
    const selectedShiftIds = deliveryEntities.filter((it) => selectedDeliveryIds.includes(it.id)).map((it) => it.shiftId);
    const allShiftIds = deliveryEntities.map((it) => it.shiftId);
    const ids = selectedShiftIds.length
      ? selectedShiftIds
      : allShiftIds;
    const asidePath = `/truck-directions/?ids=${ids.join(',')}&action=降`;

    window.open(asidePath);
  }, [deliveryEntities, selectedDeliveryIds]);

  const printAllOperationDirectionsOnClick = useCallback(() => {
    ReactGA.event('print', { screen_name: SCREEN_NAMES.PLANNING, button_name: '配送指示書' });
    updateDirectionSettingDialogIsOpen(true);
  }, [updateDirectionSettingDialogIsOpen]);

  const printOperationDirectionUrl: () => string = useCallback(
    () => {
      const selectedShiftIds = deliveryEntities.filter((it) => selectedDeliveryIds.includes(it.id)).map((it) => it.shiftId);
      const allShiftIds = deliveryEntities.map((it) => it.shiftId);
      const ids = selectedShiftIds.length
        ? selectedShiftIds
        : allShiftIds;

      return `/operation-directions/?ids=${ids.join(',')}`;
    },
    [deliveryEntities, selectedDeliveryIds],
  );

  const printPickingListOnClick = useCallback(() => {
    ReactGA.event('print', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'ピッキングリスト' });
    const selectedShiftIds = deliveryEntities.filter((it) => selectedDeliveryIds.includes(it.id)).map((it) => it.shiftId);
    const allShiftIds = deliveryEntities.map((it) => it.shiftId);
    const ids = selectedShiftIds.length
      ? selectedShiftIds
      : allShiftIds;
    const asidePath = `/picking-list/?ids=${ids.join(',')}&date=${startOn}`;

    window.open(asidePath);
  }, [deliveryEntities, selectedDeliveryIds, startOn]);

  const downloadPlanCsvOnClick = useCallback(() => {
    ReactGA.event('print', { screen_name: SCREEN_NAMES.PLANNING, button_name: '配車CSV' });
    window.open(`/api/v2/shifts/csv?start_at_range_start_on=${startOn}&start_at_range_end_on=${endOn}`);
  }, [startOn, endOn]);

  const estimationMethods: EstimationMethodVo[] = useMemo(() => (
    [
      '原価積み上げ式',
      '荷主価格式-距離制',
      '荷主価格式-時間制',
      '荷主価格式-個建て',
      '荷主価格式-厳密計算',
      '荷主価格式-厳密計算-荷主別',
    ]
  ), []);
  const [estimationMethod, setEstimationMethod] = useState<EstimationMethodVo | undefined>(undefined);

  const estimationMethodOnClick = useCallback((value: EstimationMethodVo) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `見積もり - ${value}` });
    setEstimationMethod(value);
  }, []);

  const [estimationDialogIsOpen, setEstimationDialogIsOpen] = useState<boolean>(false);
  const estimationButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '見積もり' });
    setEstimationDialogIsOpen(true);
  }, []);
  const estimationOnClose = useCallback(() => {
    ReactGA.event('close', { screen_name: SCREEN_NAMES.PLANNING, button_name: '見積もり' });
    setEstimationDialogIsOpen(false);
    setEstimationMethod(undefined);
  }, []);

  const allocateHistoryListItemButtonOnClick = useCallback((version: string) => {
    setCurrentHistoryVersion(version);
  }, [setCurrentHistoryVersion]);

  const allocateHistoryCancelButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '元に戻す キャンセル' });
    resetCurrentHistoryVersion();
    setAllocatedHistoriesDialogIsOpen(false);
  }, [resetCurrentHistoryVersion]);

  const allocateHistoryButtonOnClick = useCallback(() => {
    ReactGA.event('apply', { screen_name: SCREEN_NAMES.PLANNING, button_name: '元に戻す' });
    rollbackRequest();
  }, [rollbackRequest]);

  const [windowWidth, setWindowWidth] = useState<number>(0);

  const [actionContainerWidth, setActionContainerWidth] = useState<number>(0);

  useEffect(() => {
    setActionContainerWidth(windowWidth - deliveryDrawerWidth - unallocatedDrawerWidth);
  }, [windowWidth, deliveryDrawerWidth, unallocatedDrawerWidth]);

  const ordersMemo = useMemo(() => (
    <OrdersPresenter
      ids={orderIds}
      selectedIds={selectedOrderIds}
      addSelectedId={addSelectedOrderId}
      removeSelectedId={removeSelectedOrderId}
      unit={unit}
      isLoading={isLoading}
      startOn={startOn}
      endOn={endOn}
      mutateDeleteOrder={mutateDeleteOrder}
      mutateDeleteSpecificOrder={mutateDeleteSpecificOrder}
      mutateCloneOrder={mutateCloneOrder}
      planningOrderStatisticsEntity={planningOrderStatisticsEntity}
      resetAllocateHistories={resetAllocateHistories}
      customInputFields={customInputFields}
      notAllocReasons={notAllocReasons}
      truckEntities={truckEntities}
      driverEntities={driverEntities}
      deliveryEntities={deliveryEntities}
      orderEntityMap={orderEntityMap}
      unallocatedDrawerWidth={unallocatedDrawerWidth}
      orderSearchKw={orderSearchKw}
      setOrderSearchKw={setOrderSearchKw}
      currentlyOrderSearching={currentlyOrderSearching}
      setCurrentlyOrderSearching={setCurrentlyOrderSearching}
      setOrderSearchConditions={setOrderSearchConditions}
      mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
      mutateRestoreSplittedOrders={mutateRestoreSplittedOrders}
    />
  ), [orderIds, selectedOrderIds, addSelectedOrderId, removeSelectedOrderId, unit, isLoading, startOn, endOn, mutateDeleteOrder, mutateDeleteSpecificOrder, mutateCloneOrder, planningOrderStatisticsEntity, resetAllocateHistories, customInputFields, notAllocReasons, truckEntities, driverEntities, deliveryEntities, orderEntityMap, unallocatedDrawerWidth, orderSearchKw, setOrderSearchKw, currentlyOrderSearching, setCurrentlyOrderSearching, setOrderSearchConditions, mutateRestoreSplittedOrder, mutateRestoreSplittedOrders]);

  const allocateHistoryMemo = useMemo(() => (
    <AllocateHistoryPresenter
      allocateHistoriesForCompany={allocateHistoriesForCompany}
      allocateHistoryListItemButtonOnClick={allocateHistoryListItemButtonOnClick}
      allocateHistoryButtonOnClick={allocateHistoryButtonOnClick}
      allocateHistoryCancelButtonOnClick={allocateHistoryCancelButtonOnClick}
      isLoading={isLoading}
      currentHistoryVersion={currentHistoryVersion}
      unallocatedDrawerWidth={unallocatedDrawerWidth}
    />
  ), [allocateHistoriesForCompany, allocateHistoryButtonOnClick, allocateHistoryCancelButtonOnClick, allocateHistoryListItemButtonOnClick, currentHistoryVersion, isLoading, unallocatedDrawerWidth]);

  const [scenarioPlanningDialogIsOpen, setScenarioPlanningDialogIsOpen] = useState(false);
  const scenarioDialogMemo = useMemo(() => (
    <ScenarioPlanningListDialog
      dialogIsOpen={scenarioPlanningDialogIsOpen}
      setDialogIsOpen={setScenarioPlanningDialogIsOpen}
      customInputFields={customInputFields}
      requestScenarioPlanning={requestScenarioPlanning}
    />
  ), [customInputFields, scenarioPlanningDialogIsOpen, requestScenarioPlanning]);

  const [orderDataGridApiRef, setOrderGridApiRef] = useState<React.MutableRefObject<GridApiPremium>>();

  const selectedOrderIdsForPlanning = useMemo(() => {
    if (currentView === 'map') {
      return selectedOrderIds;
    }
    if (orderDataGridApiRef && orderDataGridApiRef.current) {
      const selected = Array.from(orderDataGridApiRef.current.getSelectedRows().keys()).map(Number);
      // 未割り当てのものだけに絞る
      return selected.filter((it) => orderIds.includes(it));
    }
    return [];
  // planningDialogIsOpen が変わるときに更新しないと古い値を使ってしまう。
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningDialogIsOpen, currentView, orderDataGridApiRef, orderIds, selectedOrderIds]);

  const planningMemo = useMemo(() => (
    <PlanningPresenter
      planningRequest={planningRequest}
      updateBalancedLoading={updateBalancedLoading}
      updateIsRespectRecent={updateIsRespectRecent}
      updateExcludeToll={updateExcludeToll}
      onClose={planningDialogOnClose}
      selectedDeliveryIds={selectedDeliveryIds}
      selectedOrderIds={selectedOrderIdsForPlanning}
      startOn={startOn}
      updateRespectType={updateRespectType}
      updateRespectDaysOfWeek={updateRespectDaysOfWeek}
      updateRespectOn={updateRespectOn}
      updateIsTimeTableBackward={updateIsTimeTableBackward}
      updateIncludeFutureDeliveries={updateIncludeFutureDeliveries}
      updateAutoSplitOrders={updateAutoSplitOrders}
      updateDontExchangePreroute={updateDontExchangePreroute}
      updateConcurrentAllOrNothing={updateConcurrentAllOrNothing}
      relaxedRules={relaxedRules}
      selectedRelaxedRules={selectedRelaxedRules}
      setSelectedRelaxedRules={setSelectedRelaxedRules}
      setPriorityLargeTrucks={setPriorityLargeTrucks}
      priorityLargeTrucks={priorityLargeTrucks}
      updateExpandWorkingStartMinutes={updateExpandWorkingStartMinutes}
      updateExpandWorkingEndMinutes={updateExpandWorkingEndMinutes}
      updateExpandLoadStartMinutes={updateExpandLoadStartMinutes}
      updateExpandLoadEndMinutes={updateExpandLoadEndMinutes}
      updateExpandUnloadStartMinutes={updateExpandUnloadStartMinutes}
      updateExpandUnloadEndMinutes={updateExpandUnloadEndMinutes}
      updateExpandMaxVolumeRate={updateExpandMaxVolumeRate}
      updateExpandMaxLoadCapacityRate={updateExpandMaxLoadCapacityRate}
      mlSourceTypes={licenseContext?.config?.ml_source_types || []}
      updateMLSourceType={updateMLSourceType}
      updateMLPlanning={updateMLPlanning}
      updateOptimizeForLoad={updateOptimizeForLoad}
      updateForceNullBase={updateForceNullBase}
      updateTrunkTransportation={updateTrunkTransportation}
    />
  ), [planningRequest, updateIsRespectRecent, updateExcludeToll, planningDialogOnClose, selectedDeliveryIds, selectedOrderIdsForPlanning, startOn, updateRespectType, updateRespectDaysOfWeek, updateRespectOn, updateIsTimeTableBackward, updateIncludeFutureDeliveries, updateAutoSplitOrders, relaxedRules, selectedRelaxedRules, setSelectedRelaxedRules, setPriorityLargeTrucks, priorityLargeTrucks, updateExpandLoadStartMinutes, updateExpandLoadEndMinutes, updateExpandUnloadStartMinutes, updateExpandUnloadEndMinutes, updateExpandMaxVolumeRate, updateExpandMaxLoadCapacityRate, licenseContext?.config?.ml_source_types, updateMLSourceType, updateDontExchangePreroute, updateConcurrentAllOrNothing, updateMLPlanning, updateBalancedLoading, updateExpandWorkingStartMinutes, updateExpandWorkingEndMinutes, updateOptimizeForLoad, updateForceNullBase, updateTrunkTransportation]);

  const transferMemo = useMemo(() => (
    <PlanningPresenter
      planningRequest={transferRequest}
      updateBalancedLoading={updateBalancedLoading}
      updateIsRespectRecent={updateIsRespectRecent}
      updateExcludeToll={updateExcludeToll}
      onClose={transferDialogOnClose}
      selectedDeliveryIds={transfer && transfer.transferToDeliveryId && transfer.transferFromDeliveryIds ? [transfer.transferToDeliveryId, ...transfer.transferFromDeliveryIds] : []}
      selectedOrderIds={transfer && transfer.orderIds ? transfer.orderIds : []}
      startOn={startOn}
      updateRespectType={updateRespectType}
      updateRespectDaysOfWeek={updateRespectDaysOfWeek}
      updateRespectOn={updateRespectOn}
      updateIsTimeTableBackward={updateIsTimeTableBackward}
      updateIncludeFutureDeliveries={updateIncludeFutureDeliveries}
      updateAutoSplitOrders={updateAutoSplitOrders}
      updateDontExchangePreroute={updateDontExchangePreroute}
      updateConcurrentAllOrNothing={updateConcurrentAllOrNothing}
      relaxedRules={relaxedRules}
      selectedRelaxedRules={selectedRelaxedRules}
      setSelectedRelaxedRules={setSelectedRelaxedRules}
      setPriorityLargeTrucks={setPriorityLargeTrucks}
      priorityLargeTrucks={priorityLargeTrucks}
      updateExpandWorkingStartMinutes={updateExpandWorkingStartMinutes}
      updateExpandWorkingEndMinutes={updateExpandWorkingEndMinutes}
      updateExpandLoadStartMinutes={updateExpandLoadStartMinutes}
      updateExpandLoadEndMinutes={updateExpandLoadEndMinutes}
      updateExpandUnloadStartMinutes={updateExpandUnloadStartMinutes}
      updateExpandUnloadEndMinutes={updateExpandUnloadEndMinutes}
      updateExpandMaxVolumeRate={updateExpandMaxVolumeRate}
      updateExpandMaxLoadCapacityRate={updateExpandMaxLoadCapacityRate}
      mlSourceTypes={licenseContext?.config?.ml_source_types || []}
      updateMLSourceType={updateMLSourceType}
      updateMLPlanning={updateMLPlanning}
      updateOptimizeForLoad={updateOptimizeForLoad}
      updateForceNullBase={updateForceNullBase}
      updateTrunkTransportation={updateTrunkTransportation}
    />
  ), [transferRequest, updateBalancedLoading, updateIsRespectRecent, updateExcludeToll, transferDialogOnClose, transfer, startOn, updateRespectType, updateRespectDaysOfWeek, updateRespectOn, updateIsTimeTableBackward, updateIncludeFutureDeliveries, updateAutoSplitOrders, relaxedRules, selectedRelaxedRules, setSelectedRelaxedRules, setPriorityLargeTrucks, priorityLargeTrucks, updateExpandLoadStartMinutes, updateExpandLoadEndMinutes, updateExpandUnloadStartMinutes, updateExpandUnloadEndMinutes, updateExpandMaxVolumeRate, updateExpandMaxLoadCapacityRate, licenseContext?.config?.ml_source_types, updateMLSourceType, updateDontExchangePreroute, updateConcurrentAllOrNothing, updateMLPlanning, updateExpandWorkingStartMinutes, updateExpandWorkingEndMinutes, updateOptimizeForLoad, updateForceNullBase, updateTrunkTransportation]);

  const deliveryIdSelected = useMemo(() => selectedDeliveryIds.length > 0, [selectedDeliveryIds.length]);

  const actionsMemo = useMemo(() => (
    <ActionsPresenter
      startOn={startOn}
      endOn={endOn}
      isLoading={isLoading}
      planningButtonOnClick={planningButtonOnClick}
      selectImportMethodButtonOnClick={selectImportMethodButtonOnClick}
      deliveryIdSelected={deliveryIdSelected}
      estimationButtonOnClick={estimationButtonOnClick}
      allocateHistoriesOnClick={allocateHistoriesOnClick}
      allocateHistoriesForCompany={allocateHistoriesForCompany}
      currentHistoryVersion={currentHistoryVersion}
      resetAllocateHistories={resetAllocateHistories}
      setScenarioPlanningDialogIsOpen={setScenarioPlanningDialogIsOpen}
      currentView={currentView}
      updateView={updateView}
    />
  ), [startOn, endOn, isLoading, deliveryIdSelected, allocateHistoriesForCompany, allocateHistoriesOnClick, currentHistoryVersion, estimationButtonOnClick, planningButtonOnClick, selectImportMethodButtonOnClick, resetAllocateHistories, setScenarioPlanningDialogIsOpen, currentView, updateView]);

  const displayKindOptions = useMemo(() => ['積地', '納品地', '車庫'], []);
  const storedDisplayKindOptions = useMemo(() => localStorage.getItem('mapDisplayFilter'), []);
  const [displayKinds, setDisplayKinds] = useState<string[]>(storedDisplayKindOptions ? storedDisplayKindOptions.split(',') : displayKindOptions);

  useEffect(() => {
    localStorage.setItem('mapDisplayFilter', displayKinds.join(','));
  }, [displayKinds]);

  const actionsOnMapMemo = useMemo(() => {
    if (currentView !== 'map') return null;

    return (
      <Box
        sx={{
          position: 'absolute',
          left: deliveryDrawerWidth,
          p: 1,
          zIndex: 999,
          width: actionContainerWidth,
        }}
      >
        <ActionsOnMapPresenter
          setShowTruckStatus={setShowTruckStatus}
          displayKindOptions={displayKindOptions}
          displayKinds={displayKinds}
          setDisplayKinds={setDisplayKinds}
        />
      </Box>
    );
  }, [currentView, actionContainerWidth, deliveryDrawerWidth, displayKindOptions, displayKinds]);

  const selectPrintMemo = useMemo(() => (
    <SelectPrintPresenter
      printAllOperationDirectionsOnClick={printAllOperationDirectionsOnClick}
      printAllTruckDirectionsOnClick={printAllTruckDirectionsOnClick}
      printUnloadTruckDirectionsOnClick={printUnloadTruckDirectionsOnClick}
      printPickingListOnClick={printPickingListOnClick}
      downloadPlanCsvOnClick={downloadPlanCsvOnClick}
    />
  ), [printAllOperationDirectionsOnClick, printAllTruckDirectionsOnClick, printUnloadTruckDirectionsOnClick, printPickingListOnClick, downloadPlanCsvOnClick]);

  const selectImportMethodMemo = useMemo(() => (
    <SelectImportMethodPresenter
      importButtonOnClick={importButtonOnClick}
      customImportButtonOnClick={customImportButtonOnClick}
      importForUpdateButtonOnClick={importForUpdateButtonOnClick}
      isLoading={isLoading}
    />
  ), [customImportButtonOnClick, importButtonOnClick, importForUpdateButtonOnClick, isLoading]);

  const deliveryElementsRef = useRef<{ [key: number]: HTMLElement }>({});

  const addDeliveryElementRef = useCallback((deliveryId: number, element: HTMLElement) => {
    deliveryElementsRef.current[deliveryId] = element;
  }, []);

  const scrollToDeliveryElement = useCallback((deliveryId: number) => {
    if (deliveryElementsRef.current[deliveryId]) {
      const elem = deliveryElementsRef.current[deliveryId];
      elem.scrollIntoView(true);
    }
  }, []);

  const planningTrucksPresenterMemo = useMemo(() => (
    <div>
      <Box
        sx={{
          width: deliveryDrawerWidth,
          position: 'absolute',
          left: 0,
          top: actionBarHeight,
          zIndex: 999,
          height: contentHeight,
          bgcolor: theme.colors.alpha.white[100],
          overflow: 'hidden',
        }}
      >
        <PlanningTrucksPresenter
          startOn={startOn}
          endOn={endOn}
          isLoading={isLoading}
          garageData={garageData}
          truckEntities={truckEntities}
          driverEntities={driverEntities}
          deliveryEntities={deliveryEntities}
          operationEntities={operationEntities}
          openTransferDialog={openTransferDialog}
          updateDisplayOrderId={updateDisplayOrderId}
          mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
          mutateCloneLastWeekShifts={mutateCloneLastWeekShifts}
          mutateDeleteShiftsOperations={mutateDeleteShiftsOperations}
          swapRequest={swapRequest}
          groupEntities={groupEntities}
          selectedGroupEntities={selectedGroupEntities}
          updateSelectedGroupEntities={updateSelectedGroupEntities}
          planningsOperationEntitiesWithStatsByDeliveryIdEntity={planningsOperationEntitiesWithStatsByDeliveryIdEntity}
          sendOperationMail={sendOperationMail}
          refreshOperationStatuses={refreshOperationStatuses}
          lastOperationStatusUpdated={lastOperationStatusUpdated}
          resetDrivers={resetDrivers}
          currentHistoryVersion={currentHistoryVersion}
          pastOperationsData={pastOperationsData}
          latestAllocateHistory={latestAllocateHistory}
          selectPrintButtonOnClick={selectPrintButtonOnClick}
          requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
          selectedCycleIndexes={selectedCycleIndexes}
          updateSelectedCycleIndexes={updateSelectedCycleIndexes}
          planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
          resetEditPlaces={resetEditPlaces}
          updateEditPlaces={updateEditPlaces}
          addEmptyCycle={addEmptyCycle}
          removeEmptyCycle={removeEmptyCycle}
          addDeliveryElementRef={addDeliveryElementRef}
          truckDisplayStatus={truckDisplayStatus}
          setTruckDisplayStatus={setTruckDisplayStatus}
          setAllDeliverySelected={setAllDeliverySelected}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '2%',
          left: deliveryDrawerWidth,
          zIndex: 1000,
        }}
      >
        <IconButton
          sx={{
            bgcolor: theme.colors.alpha.white[100],
            borderRadius: '0 5px 5px 0',
            border: '1px solid #ccc',
            px: 0
          }}
          onClick={() => {
            if (deliveryDrawerIsOpen) {
              closeDeliveryDrawer();
            } else {
              openDeliveryDrawer();
            }
          }}
        >
          {
            deliveryDrawerIsOpen
              ? <ChevronLeftRoundedIcon />
              : <ChevronRightRoundedIcon />
          }
        </IconButton>
      </Box>
    </div>
  ), [deliveryDrawerWidth, contentHeight, theme.colors.alpha.white, startOn, endOn, isLoading, garageData, truckEntities, driverEntities, deliveryEntities, operationEntities, openTransferDialog, updateDisplayOrderId, mutateDeleteOrdersOperations, mutateCloneLastWeekShifts, mutateDeleteShiftsOperations, swapRequest, groupEntities, selectedGroupEntities, updateSelectedGroupEntities, planningsOperationEntitiesWithStatsByDeliveryIdEntity, sendOperationMail, refreshOperationStatuses, lastOperationStatusUpdated, resetDrivers, currentHistoryVersion, pastOperationsData, latestAllocateHistory, selectPrintButtonOnClick, requestSingleAlgorithmPlanning, selectedCycleIndexes, updateSelectedCycleIndexes, planningsOperationDeliveryByDeliveryIdEntity, resetEditPlaces, updateEditPlaces, addEmptyCycle, removeEmptyCycle, addDeliveryElementRef, truckDisplayStatus, setTruckDisplayStatus, setAllDeliverySelected, deliveryDrawerIsOpen, closeDeliveryDrawer, openDeliveryDrawer]);

  const mapFunctionMemo = useMemo(() => (
    <MapFunctionPresenter
      startOn={startOn}
      endOn={endOn}
      isLoading={isLoading}
      planningMapUnallocatedOrderPositionEntities={planningMapUnallocatedOrderPositionEntities}
      planningsMapOperationPositionEntities={planningsMapOperationPositionEntities}
      planningsMapGaragePositionEntities={planningsMapGaragePositionEntities}
      flyToPosition={flyToPosition}
      resetFlyToPosition={resetFlyToPosition}
      fitBoundsPositions={fitBoundsPositions}
      resetFitBoundsPositions={resetFitBoundsPositions}
    />
  ), [startOn, endOn, isLoading, planningMapUnallocatedOrderPositionEntities, planningsMapOperationPositionEntities, planningsMapGaragePositionEntities, flyToPosition, resetFlyToPosition, fitBoundsPositions, resetFitBoundsPositions]);
  const tileLayerMemo = useMemo(() => (
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  ), []);

  const planningMapDeliveriesPolylinesPresenterMemo = useMemo(() => (
    <PlanningMapDeliveriesPolylinesPresenter
      editPlaces={editPlaces}
      deliveries={deliveryEntities.filter((it) => selectedDeliveryIds.includes(it.id))}
      selectedCycleIndexes={selectedCycleIndexes}
      planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
    />
  ), [editPlaces, deliveryEntities, selectedCycleIndexes, planningsOperationDeliveryByDeliveryIdEntity, selectedDeliveryIds]);

  const orderPolylinesPresenterMemo = useMemo(() => {
    if (!orderEntityMap) return null;

    return (
      <OrderPolylinesPresenter selectedIds={selectedOrderIds} orderEntityMap={orderEntityMap} />
    );
  }, [selectedOrderIds, orderEntityMap]);

  const planningMapOrderMarkersPresenterMemo = useMemo(() => (
    <PlanningMapOrderMarkersPresenter
      entities={planningMapUnallocatedOrderPositionEntities}
      selectedIds={selectedOrderIds}
      addSelectedId={addSelectedOrderId}
      removeSelectedId={removeSelectedOrderId}
      orderEntityMap={orderEntityMap}
      displayKinds={displayKinds}
    />
  ), [planningMapUnallocatedOrderPositionEntities, selectedOrderIds, addSelectedOrderId, removeSelectedOrderId, orderEntityMap, displayKinds]);

  const planningMapOperationMarkersPresenterMemo = useMemo(() => (
    <PlanningMapOperationMarkersPresenter
      entities={planningsMapOperationPositionEntities}
      startOn={startOn}
      endOn={endOn}
      updateDisplayOrderId={updateDisplayOrderId}
      mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
      openTransferDialog={openTransferDialog}
      isLoading={isLoading}
      deliveryEntities={deliveryEntities}
      driverEntities={driverEntities}
      truckEntities={truckEntities}
      selectedCycleIndexes={selectedCycleIndexes}
      updateSelectedCycleIndexes={updateSelectedCycleIndexes}
      planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
      editPlaces={editPlaces}
      scrollToDeliveryElement={scrollToDeliveryElement}
    />
  ), [planningsMapOperationPositionEntities, startOn, endOn, updateDisplayOrderId, mutateDeleteOrdersOperations, openTransferDialog, isLoading, deliveryEntities, driverEntities, truckEntities, selectedCycleIndexes, updateSelectedCycleIndexes, planningsOperationDeliveryByDeliveryIdEntity, editPlaces, scrollToDeliveryElement]);

  const planningMapGarageMarkersPresenterMemo = useMemo(() => (
    <PlanningMapGarageMarkersPresenter
      entities={planningsMapGaragePositionEntities}
    />
  ), [planningsMapGaragePositionEntities]);

  const planningOrderPresenterMemo = useMemo(() => (
    <PlanningOrderPresenter
      orderId={displayOrderId}
      customInputFields={customInputFields}
    />
  ), [displayOrderId, customInputFields]);

  const backdropMemo = useMemo(() => (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}
      open={calculating}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ), [calculating, theme.zIndex.drawer]);

  useEffect(() => {
    const updateWidth = (): void => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    setAllocatedHistoriesDialogIsOpen(!!currentHistoryVersion);
  }, [currentHistoryVersion]);

  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout[]>([]);
  const [runningSnackbarIsOpen, setRunningSnackbarIsOpen] = useState(false);
  const [runningSnackbarMessage, setRunningSnackbarMessage] = useState('');
  const handleRunningSnackbarClose = useCallback(() => {
    setRunningSnackbarIsOpen(false);
  }, []);

  const stopRunning = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('実行中の自動配車を停止します。よろしいですか？')) {
      const entity = planningRunningEntities[0];
      if (entity && entity.id) {
        cancelRunningPlan(entity.id);
      }
      handleRunningSnackbarClose();
    }
  }, [cancelRunningPlan, handleRunningSnackbarClose, planningRunningEntities]);

  const runningSnackbarAction = useMemo(() => (
    <>
      <Button size="small" color="secondary" onClick={stopRunning}>
        実行を停止する
      </Button>
      <Button size="small" color="secondary" onClick={handleRunningSnackbarClose}>
        閉じる
      </Button>
    </>
  ), [handleRunningSnackbarClose, stopRunning]);

  const setupRunningSnackbarMessage = useCallback(() => {
    if (!planningRunningEntities) return;

    const startAt = new Date(planningRunningEntities[0].createdAt);
    const diffInMinutes = Math.floor((new Date().getTime() - startAt.getTime()) / (1000 * 60));
    const estimate = asyncPlanningResponse?.estimate ?? '';
    let estimateText = '';
    if (estimate) {
      estimateText = `(${datetimeDecorator.toHourMinutes(new Date(estimate))}頃終了予定)`;
    }
    setRunningSnackbarMessage(`${diffInMinutes < 0 ? 0 : diffInMinutes} 分前から自動配車を実行しています。${estimateText}`);

    const tid = setTimeout(() => {
      setupRunningSnackbarMessage();
      refreshRunningEntities();
    }, 1000 * 60);
    setTimeoutIds((prev) => [...prev, tid]);
  }, [planningRunningEntities, refreshRunningEntities, asyncPlanningResponse]);

  useEffect(() => {
    if (!planningRunningEntities) return;

    if (planningRunningEntities.length > 0) {
      setRunningSnackbarIsOpen(true);
      setupRunningSnackbarMessage();
    } else {
      setRunningSnackbarIsOpen(false);
      setRunningSnackbarMessage('');
    }
  }, [planningRunningEntities, setupRunningSnackbarMessage]);

  useEffect(() => {
    // 画面の軽花粉数表示のため再帰的にsetTimeoutをしている。
    // 配車が終わったらクリアしておかないと、再帰処理が続いてしまい、いつまでもカウントアップしてしまうため、
    // isLoading が false になったらタイマーをクリアする。
    if (!isLoading && timeoutIds.length > 0) {
      timeoutIds.forEach((it) => clearTimeout(it));
      setTimeoutIds([]);
    }
  }, [isLoading, timeoutIds]);

  const mapView = useMemo(() => {
    if (currentView !== 'map') return null;

    return (
      <MapContainer
        style={{
          width: '100vw',
          height: contentHeight
        }}
        center={[35.232811, 139.729094]}
        zoom={8}
        scrollWheelZoom
        zoomControl={false}
      >
        {mapFunctionMemo}
        {tileLayerMemo}
        {displayKinds.includes('車庫') && planningMapGarageMarkersPresenterMemo}
        {planningMapDeliveriesPolylinesPresenterMemo}
        {orderPolylinesPresenterMemo}
        {planningMapOrderMarkersPresenterMemo}
        {planningMapOperationMarkersPresenterMemo}
        {licenseContext.config?.use_truck_position && showTruckStatus && (
          <PlanningMapCurrentPositionsPresenter
            startOn={startOn}
            endOn={endOn}
          />
        )}
      </MapContainer>
    );
  }, [currentView, contentHeight, displayKinds, endOn, licenseContext.config, mapFunctionMemo, orderPolylinesPresenterMemo, planningMapDeliveriesPolylinesPresenterMemo, planningMapGarageMarkersPresenterMemo, planningMapOperationMarkersPresenterMemo, planningMapOrderMarkersPresenterMemo, showTruckStatus, startOn, tileLayerMemo]);

  const allocateHistoriesMemo = useMemo(() => (
    <Stack
      width={unallocatedDrawerWidth}
    >
      <Stack
        position="fixed"
        zIndex={100}
        width="100%"
      >
        <Paper
          sx={{
            pt: 2,
            px: 2,
            pb: 1,
          }}
          square
        >
          <Typography>
            操作履歴
          </Typography>
          <Stack
            direction="row"
            gap={1}
          >
            <Button
              onClick={allocateHistoryCancelButtonOnClick}
              disabled={isLoading}
              color="error"
              size="small"
            >
              キャンセル
            </Button>
            <Button
              startIcon={<SaveAsRoundedIcon />}
              disabled={!currentHistoryVersion || isLoading}
              onClick={allocateHistoryButtonOnClick}
              size="small"
            >
              元に戻す
            </Button>
          </Stack>
        </Paper>
        <Divider />
      </Stack>
      <List
        disablePadding
        sx={{
          pt: 11
        }}
      >
        {
          allocateHistoriesForCompany.map((history) => (
            <ListItem
              key={[
                'allocateHistories',
                'ListItem',
                history.version
              ].join('-')}
              disablePadding
            >
              <ListItemButton
                onClick={() => allocateHistoryListItemButtonOnClick(history.version)}
              >
                <ListItemIcon
                  sx={{
                    mr: 1
                  }}
                >
                  <Chip
                    color={currentHistoryVersion === history.version ? 'success' : 'secondary'}
                    label={datetimeDecorator.toHourMinuteSeconds(history.actAt)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={history.action}
                  secondary={history.message}
                />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>
    </Stack>
  ), [allocateHistoriesForCompany, allocateHistoryButtonOnClick, allocateHistoryCancelButtonOnClick, allocateHistoryListItemButtonOnClick, currentHistoryVersion, isLoading, unallocatedDrawerWidth]);

  const rightSideBarMemo = useMemo(() => {
    if (currentView !== 'map' && !allocatedHistoriesDialogIsOpen) return null;

    return (
      <div>
        <Box
          sx={{
            position: 'absolute',
            bottom: '2%',
            right: unallocatedDrawerWidth,
            zIndex: 1000,
          }}
        >
          <IconButton
            sx={{
              bgcolor: theme.colors.alpha.white[100],
              borderRadius: '5px 0 0 5px',
              border: '1px solid #ccc',
              px: 0
            }}
            onClick={() => {
              if (unallocatedDrawerIsOpen) {
                closeUnallocatedDrawer();
              } else {
                openUnallocatedDrawer();
              }
            }}
          >
            {
              unallocatedDrawerIsOpen
                ? <ChevronRightRoundedIcon />
                : <ChevronLeftRoundedIcon />
            }
          </IconButton>
        </Box>
        <Box
          sx={{
            width: unallocatedDrawerWidth,
            bgcolor: theme.colors.alpha.white[100],
            position: 'absolute',
            right: 0,
            top: actionBarHeight,
            zIndex: 999,
            overflow: 'hidden',
            height: contentHeight,
            borderLeft: '1px solid #ccc',
          }}
        >
          {
            !allocatedHistoriesDialogIsOpen
              ? ordersMemo
              : allocateHistoriesMemo
          }
        </Box>
      </div>
    );
  }, [currentView, allocateHistoriesMemo, allocatedHistoriesDialogIsOpen, closeUnallocatedDrawer, contentHeight, openUnallocatedDrawer, ordersMemo, theme.colors.alpha.white, unallocatedDrawerIsOpen, unallocatedDrawerWidth]);

  const [notAllocDeliveries, setNotAllocDeliveries] = useState<PlanningsDeliveryEntity[]>([]);
  const [notAllocTrucks, setNotAllocTrucks] = useState<PlanningsTruckEntity[]>([]);
  const [notAllocDrivers, setNotAllocDrivers] = useState<PlanningsDriverEntity[]>([]);

  useEffect(() => {
    const notAllocDeliveryIds = notAllocReasons?.map((it) => it.delivery_id) || [];
    setNotAllocDeliveries(deliveryEntities.filter((it) => notAllocDeliveryIds.includes(it.id)));
  }, [deliveryEntities, notAllocReasons]);

  useEffect(() => {
    const notAllocTruckIds = notAllocDeliveries.map((it) => it.truckId);
    setNotAllocTrucks(truckEntities.filter((it) => notAllocTruckIds.includes(it.id)));
  }, [notAllocDeliveries, truckEntities]);

  useEffect(() => {
    const notAllocDriverIds = notAllocDeliveries.map((it) => it.driverId);
    setNotAllocDrivers(driverEntities.filter((it) => notAllocDriverIds.includes(it.id)));
  }, [driverEntities, notAllocDeliveries]);

  const orderListMemo = useMemo(() => {
    if (currentView !== 'list') return null;

    const deliveryWithTruckAndDriverEntities: PlanningsDeliveryWithTruckAndDriverEntity[] = deliveryEntities.map((delivery) => {
      const truck = truckEntities.find((it) => it.id === delivery.truckId);
      const driver = driverEntities.find((it) => it.id === delivery.driverId);

      return {
        ...delivery,
        truck,
        driver,
      };
    });

    return (
      <Box
        sx={{
          position: 'absolute',
          left: deliveryDrawerWidth,
          top: actionBarHeight,
          height: contentHeight,
          width: `calc(100vw - ${deliveryDrawerWidth}px)`,
          p: 0,
          m: 0,
        }}
      >
        <V2OrdersPresenter
          startDate={new Date(startOn)}
          endDate={new Date(endOn)}
          isPlanningView
          setOrderGridApiRef={setOrderGridApiRef}
          notAllocReasons={notAllocReasons}
          notAllocDeliveries={notAllocDeliveries}
          notAllocDrivers={notAllocDrivers}
          notAllocTrucks={notAllocTrucks}
          deliveryWithTruckAndDriverEntities={deliveryWithTruckAndDriverEntities}
          requestForcePlanning={requestForcePlanning}
          resetQuery={resetQuery}
          forcePlanningQueue={forcePlanningQueue}
          currentForcePlanning={currentForcePlanning}
        />
      </Box>
    );
  }, [currentView, notAllocReasons, deliveryEntities, truckEntities, driverEntities, deliveryDrawerWidth, contentHeight, startOn, endOn, requestForcePlanning, notAllocDeliveries, notAllocTrucks, notAllocDrivers, forcePlanningQueue, currentForcePlanning, resetQuery]);

  return (
    <>
      <Helmet>
        <title>配車計画 | マップ</title>
      </Helmet>
      <Stack>
        <Stack
          sx={{
            height: actionBarHeight,
            bgcolor: theme.colors.alpha.white[100],
          }}
        >
          {actionsMemo}
        </Stack>
        <Stack
          direction="row"
          sx={{
            height: contentHeight,
            width: '100vw',
            overflow: 'hidden',
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={runningSnackbarIsOpen}
            key="RunningSnackbar"
          >
            <SnackbarContent
              message={runningSnackbarMessage}
              action={runningSnackbarAction}
            />
          </Snackbar>
          {mapView}
          {actionsOnMapMemo}
          {planningTrucksPresenterMemo}
          {rightSideBarMemo}
          {orderListMemo}
          {scenarioDialogMemo}
          <Dialog
            open={planningDialogIsOpen}
            onClose={planningDialogOnClose}
            fullWidth
            maxWidth="md"
          >
            {planningMemo}
          </Dialog>
          <Dialog
            open={transferDialogIsOpen}
            onClose={transferDialogOnClose}
            fullWidth
            maxWidth="md"
          >
            {transferMemo}
          </Dialog>
          <Dialog
            open={selectImportMethodDialogIsOpen}
            onClose={selectImportMethodDialogOnClose}
            fullWidth
            // maxWidth="md"
            maxWidth="sm"
          >
            {selectImportMethodMemo}
          </Dialog>
          <Dialog
            open={selectPrintDialogIsOpen}
            onClose={selectPrintDialogClose}
          >
            {selectPrintMemo}
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="md"
            open={displayOrderDialogIsOpen}
            onClose={resetDisplayOrderId}
          >
            {planningOrderPresenterMemo}
          </Dialog>
          <DirectionSettingDialog
            open={directionSettingDialogIsOpen}
            onClose={() => {
              updateDirectionSettingDialogIsOpen(false);
            }}
            width="md"
            navigateToPath={printOperationDirectionUrl()}
          />
          <Dialog
            open={estimationDialogIsOpen}
            onClose={estimationOnClose}
            fullScreen={!!estimationMethod}
          >
            {
              !estimationMethod ? (
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={(
                    <ListSubheader component="div" id="nested-list-subheader">
                      見積もり方法を選択してください
                    </ListSubheader>
                  )}
                >
                  {estimationMethods.map((it) => (
                    <ListItem
                      disablePadding
                      key={it}
                    >
                      <ListItemButton
                        onClick={() => {
                          estimationMethodOnClick(it);
                        }}
                      >
                        <ListItemText primary={it} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <DialogContent>
                  <EstimationByDeliveriesComponent
                    onClose={estimationOnClose}
                    planningsDeliveryEntities={deliveryEntities.filter((it) => selectedDeliveryIds.includes(it.id))}
                    planningsDriverEntities={driverEntities}
                    planningsTruckEntities={truckEntities}
                    defaultDriverCostYenPerSeconds={driverCostYenPerHours ? driverCostYenPerHours / (60 * 60) : 0}
                    defaultTruckExpresswayFeeYenPerDay={truckExpresswayFeeYenPerShift || 0}
                    defaultTruckFuelCostYenPerMm={truckFuelCostYenPerKm ? (truckFuelCostYenPerKm / 1000000) : 0}
                    defaultTruckInsuranceFeeYenPerDay={truckInsuranceFeeYenPerDay || 0}
                    defaultTruckRepairFeeYenPerDay={truckRepairCostYenPerDay || 0}
                    presetEstimationMethod={estimationMethod}
                    estimationMethods={estimationMethods}
                  />
                </DialogContent>
              )
            }
          </Dialog>
          {backdropMemo}
        </Stack>
      </Stack>
    </>
  );
});

export default Presenter;
