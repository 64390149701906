const random: (num: number) => number = (num) => Math.floor(Math.random() * num) + 1;
const buildId: () => number = () => random(2147483647);
const convertFromHoursToSeconds = (hours: number) => Math.floor(hours * 3600);
const convertFromSecondsToHours: (seconds: (number | undefined)) => number | undefined = (seconds) => (seconds ? Math.floor(seconds / 3600) : undefined);
const convertFromSecondsToMinutes: (seconds: (number | undefined)) => number | undefined = (seconds) => (seconds ? Math.floor(seconds / 60) : undefined);
const convertFromMinutesToSeconds: (seconds: (number | undefined)) => number | undefined = (minutes) => (minutes ? Math.floor(minutes * 60) : undefined);
const convertFromKmToMm: (km: (number | undefined)) => number = (km) => {
  if (!km) {
    return 0;
  }

  return km * 1000000;
};
const convertFromMmToKm: (mm: (number | undefined)) => number = (mm) => {
  if (!mm) {
    return 0;
  }

  return mm / 1000000;
};
const convertFromGramToKg: (g: (number | undefined)) => number = (g) => {
  if (!g) {
    return 0;
  }

  return g / 1000;
};
const convertFromKgToGram: (g: (number | undefined)) => number = (g) => {
  if (!g) {
    return 0;
  }

  return g * 1000;
};
const convertFromMm3ToM3: (mm3: (number | undefined)) => number = (mm3) => {
  if (!mm3) {
    return 0;
  }

  return mm3 / 1000000000;
};
const convertFromM3ToMm3: (mm3: (number | undefined)) => number = (mm3) => {
  if (!mm3) {
    return 0;
  }

  return mm3 * 1000000000;
};
const convertFromMmToCm: (mm: (number | undefined)) => number = (mm) => {
  if (!mm) {
    return 0;
  }

  return mm / 10;
};
const isNumber: (val: string) => boolean = (val) => !Number.isNaN(val) && val.trim() !== '';

const numberUtil = {
  random,
  buildId,
  convertFromHoursToSeconds,
  convertFromSecondsToHours,
  convertFromSecondsToMinutes,
  convertFromMinutesToSeconds,
  convertFromKmToMm,
  convertFromMmToKm,
  convertFromGramToKg,
  convertFromKgToGram,
  convertFromMm3ToM3,
  convertFromM3ToMm3,
  convertFromMmToCm,
  isNumber,
};

export default numberUtil;
