import { Reducer } from 'react';
import { OperationStatusEventEntity } from 'src/entities/OperationStatusEvent.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';

type OperationStatusEventEntitiesAction = {
  type: 'set';
  payload: OperationStatusEventEntity[];
} | {
  type: 'reset';
}

export const operationStatusEventEntitiesReducer: Reducer<OperationStatusEventEntity[], OperationStatusEventEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'reset': {
      return [];
    }
    default: {
      return state;
    }
  }
};

type GroupEntitiesAction = {
  type: 'set',
  payload: PlanningsGroupEntity[];
};

export const groupEntitiesReducer: Reducer<PlanningsGroupEntity[], GroupEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type SelectedGroupEntityAction = {
  type: 'set',
  payload: PlanningsGroupEntity;
};

export const selectedGroupEntityReducer: Reducer<PlanningsGroupEntity | undefined, SelectedGroupEntityAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type OrderEntityAction = {
  type: 'set',
  payload: OrderEntity[];
};

export const orderEntitiesReducer: Reducer<OrderEntity[], OrderEntityAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};
