import { startOfWeek } from 'date-fns';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FourZeroFourComponent from 'src/components/FourZeroFourComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import { DateModel } from 'src/models/DateModel';

import Component from './TrunkTransportation/Component';

const TrunkTransportation: FC = memo(() => {
  const { startOn } = useParams();
  const navigate = useNavigate();
  const context = useContext(LicenseContext);

  const start = new Date(startOn);
  const [unit, setUnit] = useState<string | undefined>(undefined);
  const [customInputFields, setCustomInputFields] = useState<string[]>([]);

  useEffect(() => {
    if (!context.config?.on) return;
    if (!context.config?.unit) return;

    setUnit(context.config.unit);
    setCustomInputFields(context.config.custom_input_fields);
    if (startOn) {
      const paramsOnModel = new DateModel(startOn);

      if (paramsOnModel.isValid()) {
        navigate(`/trunk-transportation/${paramsOnModel.toString()}`);
        return;
      }
    }

    const configOnModel = new DateModel(context.config.on);

    if (configOnModel.isValid()) {
      navigate(`/trunk-transportation/${configOnModel.toString()}/${configOnModel.toString()}`);
      return;
    }

    navigate('/404');
  }, [startOn, context.config, navigate]);

  if (!startOn) return <FourZeroFourComponent />;

  return <Component startDate={startOfWeek(start, { weekStartsOn: 1 })} unit={unit} customInputFields={customInputFields} />;
});

export default TrunkTransportation;
