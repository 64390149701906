import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Radio,
} from '@mui/material';
import { DataGridPremium, GridColDef, jaJP } from '@mui/x-data-grid-premium';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import numberDecorator from 'src/decorators/number.decorator';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';

type TransferDialogRow = {
  id: number;
  deliveryId: number;
  driverName: string;
  truckName: string;
  ratio: string;
  hasOperation: boolean;
}

type Props = {
  transferDialogIsOpen: boolean;
  setTransferDialogIsOpen: (open: boolean) => void;
  fromDeliveryId: number;
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
  openTransferDialog: (entity: TransferRequestEntity) => void;
  selectedOrderIds: number[];
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
}

const PlanningsTransferDialogPresenter: FC<Props> = memo(({
  transferDialogIsOpen,
  setTransferDialogIsOpen,
  fromDeliveryId,
  deliveryEntities,
  driverEntities,
  truckEntities,
  openTransferDialog,
  selectedOrderIds,
  planningsOperationDeliveryByDeliveryIdEntity,
}) => {
  const [radioChecked, setRadioChecked] = useState<number[]>([]);

  useEffect(() => {
    if (transferDialogIsOpen) {
      ReactGA.event('open', { screen_name: SCREEN_NAMES.TRANSFER_DIALOG });
    }
  }, [transferDialogIsOpen]);

  const onClickTransferCancel = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.TRANSFER_DIALOG, button_name: 'キャンセル' });
    setTransferDialogIsOpen(false);
  }, [setTransferDialogIsOpen]);

  const onClickTransferCommit = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.TRANSFER_DIALOG, button_name: '選択したドライバーに挿入する' });
    setTransferDialogIsOpen(false);

    openTransferDialog({
      transferToDeliveryId: radioChecked[0],
      transferFromDeliveryIds: [fromDeliveryId],
      orderIds: selectedOrderIds
    });
  }, [fromDeliveryId, openTransferDialog, radioChecked, selectedOrderIds, setTransferDialogIsOpen]);

  const Columns: GridColDef[] = useMemo(() => (
    [
      {
        field: 'radio',
        headerName: '選択',
        sortable: false,
        renderCell: (params: { row: TransferDialogRow }) => (
          <Radio checked={radioChecked[0] === params.row.id} value={params.row.id} />
        ),
        flex: 1,
      },
      {
        field: 'driverName',
        headerName: 'ドライバー',
        flex: 5,
      },
      {
        field: 'truckName',
        headerName: 'トラック',
        flex: 5,
      },
      {
        field: 'ratio',
        headerName: '積載 (重量、体積)',
        renderCell: (params: { row: TransferDialogRow }) => {
          if (params.row.hasOperation) {
            return params.row.ratio;
          }
          return (<Chip color="error" size="small" label="計画無" />);
        },
        valueGetter: (params: { row: TransferDialogRow }) => {
          if (params.row.hasOperation) {
            return params.row.ratio;
          }
          return '';
        },
        flex: 5,
      }
    ]
  ), [radioChecked]);

  const Data: TransferDialogRow[] = useMemo(() => {
    const truckIds = truckEntities.map((it) => it.id);
    const sortedDeliveries = deliveryEntities.filter((it) => it.id !== fromDeliveryId).sort((a, b) => truckIds.indexOf(a.truckId) - truckIds.indexOf(b.truckId));

    return sortedDeliveries.map((it) => {
      const driver = driverEntities.find((dr) => dr.id === it.driverId);
      const truck = truckEntities.find((tr) => tr.id === it.truckId);

      let ratio = '';
      const planningsOperationDelivery = planningsOperationDeliveryByDeliveryIdEntity[it.id];
      if (planningsOperationDelivery) {
        const weightRatio = [
          numberDecorator.convertGramToTons(planningsOperationDelivery.maxWeightG || 0, 1),
          numberDecorator.convertGramToTons(truck.maximumLoadingCapacityWeightForCalculation, 1)
        ].join('/');
        const volumeRatio = (planningsOperationDelivery.maxVolumeMm3 && truck.loadingPlatformVolumeMm3)
          ? [
              numberDecorator.convertMm3ToM3(planningsOperationDelivery.maxVolumeMm3, 1),
              numberDecorator.convertMm3ToM3(truck.loadingPlatformVolumeMm3, 1)
            ].join('/')
          : null;
        ratio = [weightRatio, volumeRatio].filter((r) => r).join(', ');
      }
      const hasOperation = it.operations.length > 0;

      return {
        id: it.id,
        deliveryId: it.id,
        driverName: driver.name,
        truckName: truck.licensePlateValue,
        ratio,
        hasOperation,
      };
    });
  }, [deliveryEntities, driverEntities, fromDeliveryId, planningsOperationDeliveryByDeliveryIdEntity, truckEntities]);

  return (
    <Dialog
      open={transferDialogIsOpen}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <DataGridPremium
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          columns={Columns}
          rows={Data}
          autoHeight
          selectionModel={radioChecked}
          onSelectionModelChange={(newSelectionModel) => {
            setRadioChecked(newSelectionModel as unknown as number[]);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickTransferCancel}>キャンセル</Button>
        <Button onClick={onClickTransferCommit} disabled={radioChecked.length === 0} variant="contained">選択したドライバーに挿入する</Button>
      </DialogActions>
    </Dialog>
  );
});

export default PlanningsTransferDialogPresenter;
