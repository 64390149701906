import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Button, Dialog, DialogActions, Stack } from '@mui/material';
import { FC, memo, Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import arrayUtil from 'src/utils/array.util';

import PlanningMapDriversPresenter from './PlanningMapDrivers.presenter';
import PlanningTransferSelectDeliveriesPresenter from './PlanningTransferSelectDeliveries.presenter';

type SelectedOrderIdsActions = {
  type: 'add';
  payload: number[];
} | {
  type: 'remove';
  payload: number[];
} | {
  type: 'reset';
}

const selectedIdsReducer: Reducer<number[], SelectedOrderIdsActions> = (state, action) => {
  switch (action.type) {
    case 'add':
      return [
        ...state.filter((it) => !action.payload.includes(it)),
        ...action.payload,
      ];
    case 'remove':
      return [
        ...state.filter((it) => !action.payload.includes(it)),
      ];
    default:
      return state;
  }
};

type Props = {
  position: PositionEntity;
  driverEntitiesOnPosition: PlanningsDriverEntity[];
  startOn: string;
  endOn: string;
  updateDisplayOrderId: (orderId: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  deliveryEntitiesOnPosition: PlanningsDeliveryEntity[];
  truckEntities: PlanningsTruckEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  dialogIsOpen: boolean;
  setDialogIsOpen: (open: boolean) => void;
  editPlaces: EditPlaceEntity[];
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
}

const PlanningMapOperationDetailDialog: FC<Props> = memo((
  {
    position,
    driverEntitiesOnPosition,
    startOn,
    endOn,
    updateDisplayOrderId,
    mutateDeleteOrdersOperations,
    openTransferDialog,
    isLoading,
    deliveryEntitiesOnPosition,
    truckEntities,
    deliveryEntities,
    driverEntities,
    dialogIsOpen,
    setDialogIsOpen,
    editPlaces,
    planningsOperationDeliveryByDeliveryIdEntity,
  }
) => {
  const [selectedOrderIds, dispatchSelectedOrderIds] = useReducer(selectedIdsReducer, []);
  const [transferToDeliveryId, setTransferToDeliveryId] = useState<number | undefined>(undefined);
  const [transferFromDeliveryIds, setTransferFromDeliveryIds] = useState<number[]>([]);
  const [isAllFixed, setIsAllFixed] = useState<boolean>(false);

  const updateTransferFromDeliveryIds = (ids: number[]) => {
    setTransferFromDeliveryIds(ids);
  };

  const updateTransferToDeliveryId = (transferDeliveryId: number | undefined) => {
    setTransferToDeliveryId(transferDeliveryId);
  };

  const addSelectedOrderIds = useCallback((orderIds: number[]) => {
    dispatchSelectedOrderIds(
      {
        type: 'add',
        payload: orderIds
      }
    );
  }, []);

  const removeSelectedOrderIds = useCallback((orderIds: number[]) => {
    dispatchSelectedOrderIds(
      {
        type: 'remove',
        payload: orderIds
      }
    );
  }, []);

  const dialogOnClose = () => {
    if (isLoading) return;

    setDialogIsOpen(false);
  };

  const deleteButtonOnClick = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: '割当解除' });
    if (isLoading) return;
    if (!selectedOrderIds.length) return;

    setDialogIsOpen(false);
    mutateDeleteOrdersOperations(selectedOrderIds);
  };

  const transferButtonOnClick = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: '移動する' });
    if (!selectedOrderIds.length) return;
    if (isLoading) return;

    const entity = {
      transferToDeliveryId,
      transferFromDeliveryIds,
      orderIds: selectedOrderIds,
    };

    setDialogIsOpen(false);
    openTransferDialog(entity);
  };

  useEffect(() => {
    if (transferFromDeliveryIds.includes(transferToDeliveryId)) {
      setTransferToDeliveryId(undefined);
    }
  }, [transferFromDeliveryIds, transferToDeliveryId]);

  useEffect(() => {
    setIsAllFixed(
      deliveryEntitiesOnPosition.flatMap((it) => it.operations).filter((it) => [it.latitude, it.longitude].toString() === position.toString()).every((it) => it.fixed)
    );
  }, [deliveryEntitiesOnPosition, position]);

  const uniqDriverEntitiesOnPosition = arrayUtil.uniq<number>(
    driverEntitiesOnPosition.filter((it) => it).map((it) => it.id)
  ).map((it) => driverEntitiesOnPosition.find((ent) => ent.id === it));

  const [filteredDeliveryEntities, setFilteredDeliveryEntities] = useState<PlanningsDeliveryEntity[]>([]);

  useEffect(() => {
    const selectedDeliveries = deliveryEntities.filter((it) => it.operations.some((op) => selectedOrderIds.includes(op.orderId)));
    const selectedDriverIds = selectedDeliveries.map((it) => it.driverId);
    const selectedDrivers = driverEntities.filter((it) => selectedDriverIds.includes(it.id));
    const uniqedCompanyIds = [...new Set(selectedDrivers.map((it) => it.companyId))];
    if (uniqedCompanyIds.length === 1) {
      const companyDriverIds = driverEntities.filter((it) => it.companyId === uniqedCompanyIds[0]).map((it) => it.id);
      const localSelectedDeliveryIds = selectedDeliveries.map((it) => it.id);
      setFilteredDeliveryEntities(
        deliveryEntities.filter((it) => companyDriverIds.includes(it.driverId))
                        .filter((it) => !localSelectedDeliveryIds.includes(it.id))
      );
    } else {
      setFilteredDeliveryEntities([]);
    }
  }, [deliveryEntities, driverEntities, selectedOrderIds]);

  return (
    <Dialog
      open={dialogIsOpen}
      onClose={dialogOnClose}
      fullWidth
      maxWidth="md"
    >
      <PlanningMapDriversPresenter
        position={position}
        driverEntitiesOnPosition={uniqDriverEntitiesOnPosition}
        deliveryEntitiesOnPosition={deliveryEntitiesOnPosition}
        startOn={startOn}
        endOn={endOn}
        selectedOrderIds={selectedOrderIds}
        addSelectedOrderIds={addSelectedOrderIds}
        removeSelectedOrderIds={removeSelectedOrderIds}
        updateDisplayOrderId={updateDisplayOrderId}
        isLoading={isLoading}
        updateTransferFromDeliveryIds={updateTransferFromDeliveryIds}
        truckEntities={truckEntities}
        editPlaces={editPlaces}
        planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
      />
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            disabled={!selectedOrderIds.length || isLoading || isAllFixed}
            onClick={deleteButtonOnClick}
            startIcon={<DeleteForeverRoundedIcon />}
          >
            割当解除
          </Button>
          <PlanningTransferSelectDeliveriesPresenter
            currentDeliveryIds={transferFromDeliveryIds}
            updateTransferToDeliveryId={updateTransferToDeliveryId}
            truckEntities={truckEntities}
            driverEntities={driverEntities}
            deliveryEntities={filteredDeliveryEntities}
            isAllFixed={isAllFixed}
          />
          <Button
            disabled={![selectedOrderIds.length, transferToDeliveryId].every((maybe) => maybe) || isLoading || isAllFixed}
            onClick={transferButtonOnClick}
            startIcon={<CompareArrowsRoundedIcon />}
          >
            移動する
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default PlanningMapOperationDetailDialog;
