export const SCREEN_NAMES = {
  HEADER: 'ヘッダー',
  DASHBOARD: 'ダッシュボード',
  DASHBOARD_MONTH_SELECT: 'ダッシュボード 月選択ダイアログ',
  DASHBOARD_COPY_PLANS: 'ダッシュボード 複製ダイアログ',
  PLANNING: '配車計画',
  TRUNK_TRANSPORTATION: '幹線輸送',
  ORDER_ADVANCED_SEARCH: '配車計画 案件詳細検索',
  ORDER_GROUPING: '配車計画 グループ化設定',
  PLANNING_PLACE_DIALOG: '配車計画 地点ダイアログ',
  PLANNING_MAP: '配車計画 地図',
  PLANNING_MAIL: '配車計画 配送指示メール',
  PLANNING_ORDER_IMPORT: '配車計画 インポートダイアログ',
  TRANSFER_DIALOG: '移動先車両選択ダイアログ',
  SCENARIO_LIST: 'シナリオ一覧',
  SCENARIO_EDIT: 'シナリオ編集',
};
