import { Checkbox, Popover, Stack, Typography } from '@mui/material';
import { format } from 'date-fns-tz';
import { FC, memo, useCallback, useState } from 'react';
import numberDecorator from 'src/decorators/number.decorator';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';

type Props = {
  deliveries: PlanningsDeliveryEntity[];
  truckData: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  selectedDeliveryIds: number[];
  setSelectedDeliveryIds: (selectedDeliveryIds: number[]) => void;
}

const TruckLabel: FC<Props> = memo(({
  deliveries,
  truckData,
  driverEntities,
  selectedDeliveryIds,
  setSelectedDeliveryIds,
}) => {
  const truck = truckData.find((it) => it.id === deliveries[0].truckId);
  const driver = driverEntities.find((it) => it.id === deliveries[0].driverId);
  const weightAndVolume = [
    numberDecorator.convertGramToKg(truck.maximumLoadingCapacityWeightForCalculation),
    numberDecorator.convertMm3ToM3(truck.loadingPlatformVolumeMm3)
  ].filter((it) => it);
  const workingTime = (deli: PlanningsDeliveryEntity) => [
    format(new Date(deli.startAt), 'MM/dd HH:mm', { timeZone: 'Asia/Toyo' }),
    format(new Date(deli.endAt), 'MM/dd HH:mm', { timeZone: 'Asia/Toyo' }),
  ].join(' - ');

  const checkBoxOnClick = useCallback((deliveryIds: number[]) => {
    const addIds = deliveryIds.filter((it) => !selectedDeliveryIds.includes(it));
    const removeIds = deliveryIds.filter((it) => selectedDeliveryIds.includes(it));
    const ids = [...selectedDeliveryIds.filter((it) => !removeIds.includes(it)), ...addIds];
    setSelectedDeliveryIds(ids);
  }, [selectedDeliveryIds, setSelectedDeliveryIds]);

  const [ancherEl, setAncherEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAncherEl(e.currentTarget);
  }, []);

  const handleClose = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAncherEl(null);
  }, []);

  const open = Boolean(ancherEl);
  const deliveryIds = deliveries.map((it) => it.id);
  const checked = deliveryIds.every((it) => selectedDeliveryIds.includes(it));

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        cursor: 'pointer',
      }}
      sx={{
        p: 0,
        m: 0,
        height: '58px',
      }}
    >
      <Checkbox
        onClick={() => checkBoxOnClick(deliveryIds)}
        checked={checked}
        size="small"
        // sx={{ p: 0, m: 0, }}
      />
      <Stack
        direction="column"
        sx={{
          p: 0,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        onClick={handleOpen}
      >
        <Stack>
          <Typography fontSize="12px" sx={{ textOverflow: 'clip', whiteSpace: 'nowrap', }}>
            {truck.licensePlateValue}
          </Typography>
        </Stack>
        <Stack>
          <Typography fontSize="12px" sx={{ textOverflow: 'clip', whiteSpace: 'nowrap', textDecoration: driver?.isActive ? '' : 'line-through' }}>
            {driver?.name}
          </Typography>
        </Stack>
      </Stack>
      <Popover
        open={open}
        anchorEl={ancherEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Stack sx={{ p: 2, }}>
          <Stack>
            <Typography fontSize="12px">{truck.licensePlateValue}</Typography>
          </Stack>
          <Stack>
            <Typography fontSize="12px">{`(${weightAndVolume.join('/')})`}</Typography>
          </Stack>
          <Stack sx={{ pb: 1 }}>
            <Typography fontSize="12px">{driver?.name}</Typography>
          </Stack>
          {deliveries.map((it) => (
            <Stack key={`truckLabel-${it.id}`}>
              <Typography fontSize="12px">{workingTime(it)}</Typography>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
});

export default TruckLabel;
